import { useContext, useState, useEffect } from 'react'
import { AppContext, log } from '../App'
import * as c from '../c'
import * as f from '../f'

export default function DashboardGeral({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador},
        icons = App.icons, user = App.user

  const [lstSeguro, setLstSeguro] = useState([]),
        [loading, setLoading] = useState(false),
        [data, setData] = useState({})

  function get(){
    setLoading(true)
    App.api('dashboard::transportadora').then(r=>{
      if(user.in_trp==='T') setData(r.results)
      if( user.in_sgr==='T' ) App.api('apolices::get').then(r=>setLstSeguro(r.results.lst_seguro))
      setLoading(false)
    })
  }

  useEffect(()=>{ get()
    setReloader(()=>get)
  }, [])

  const styleNumber = {fontWeight:'bold', fontSize:50, padding: '0 20px'}

  return <>
    {user.in_trp === 'T' && <>
      {user.in_sgr==='T' && 
        <c.Frame title={lang.seguro} loading={loading}>
          <c.Table data={lstSeguro} columns={[
            [lang.seguro, 'DS_TSGR'],
            [lang.obrigatorio, 'DT_OBR'],
            [lang.apolice, 'DS_APOLICE'],
            [lang.inicio, 'DT_INIVIG'],
            [lang.termino, 'DT_FIMVIG'],
            [lang.situacao, 'DS_STS'],
          ]} />
        </c.Frame>
      }

      {data.lst_situacao&&<>
        <div className='f g2'>
          <c.Frame title={lang.cotas} loading={loading} className='f1' style={{'--aniPos': '0'}}>
            <div className='f f-column g1'>
              <div className='f g1 center-v f-between'>
                <div className='f g1 f-column' style={styleNumber}>{data.lst_situacao.total.cota}</div>

                <c.chart.ResponsiveContainer height={150}>
                  <c.chart.AreaChart data={data.lst_cota.total}>
                    <c.chart.YAxis /><c.chart.XAxis dataKey="Data" /><c.chart.Tooltip />
                    <c.chart.CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <c.chart.Area type="natural" dataKey="Cota" stroke="var(--info)" fill="var(--info)" strokeWidth={4} />
                  </c.chart.AreaChart>
                </c.chart.ResponsiveContainer>
              </div>

              <div className='f g1'>
                <div className='fcc'>{lang.agendado}  <div className='bold'>{data.lst_situacao.total.agendado}</div></div>
                <div className='fcc'>{lang.transito}  <div className='bold'>{data.lst_situacao.total.transito}</div></div>
                <div className='fcc'>{lang.encerrado} <div className='bold'>{data.lst_situacao.total.encerrado}</div></div>
                <div className='fcc'>{lang.no_show}   <div className='bold'>{data.lst_situacao.total.noshow}</div></div>
                <div className='fcc'>{lang.consumo}   <div className='bold'>{data.lst_situacao.total.percentual}%</div></div>
              </div>
            </div>
          </c.Frame>
                
          <c.Frame title={lang.cotas_exclusivas} loading={loading} className='f1' style={{'--aniPos': '1'}}>
            <div className='f f-column g1'>
              <div className='f g1 center-v f-between'>
                <div className='f g1 f-column' style={styleNumber}>{data.lst_situacao.total.cota}</div>

                <c.chart.ResponsiveContainer height={150}>
                  <c.chart.AreaChart data={data.lst_cota.exclusiva}>
                    <c.chart.YAxis /><c.chart.XAxis dataKey="Data" /><c.chart.Tooltip />
                    <c.chart.CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <c.chart.Area type="natural" dataKey="Cota" stroke="var(--info)" fill="var(--info)" strokeWidth={4} />
                  </c.chart.AreaChart>
                </c.chart.ResponsiveContainer>
              </div>

              <div className='f g1'>
                <div className='fcc'>{lang.agendado}  <div className='bold'>{data.lst_situacao.exclusiva.agendado}</div></div>
                <div className='fcc'>{lang.transito}  <div className='bold'>{data.lst_situacao.exclusiva.transito}</div></div>
                <div className='fcc'>{lang.encerrado} <div className='bold'>{data.lst_situacao.exclusiva.encerrado}</div></div>
                <div className='fcc'>{lang.no_show}   <div className='bold'>{data.lst_situacao.exclusiva.noshow}</div></div>
                <div className='fcc'>{lang.consumo}   <div className='bold'>{data.lst_situacao.exclusiva.percentual}%</div></div>
              </div>
            </div>
          </c.Frame>
        </div>

        <c.Frame title={lang.cotas_compartilhadas} loading={loading} style={{'--aniPos': '2'}}>
          <div className='f f-column g1'>
            <div className='f g1 center-v f-between'>
              <div className='f g1 f-column' style={styleNumber}>{data.lst_situacao.compartilhada.cota}</div>

              <div className='f5'>
                <c.chart.ResponsiveContainer height={150}>
                  <c.chart.AreaChart data={data.lst_cota.compartilhada}>
                    <c.chart.YAxis /><c.chart.XAxis dataKey="Data" /><c.chart.Tooltip />
                    <c.chart.CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <c.chart.Area type="natural" dataKey="Cota" stroke="var(--warning)" fill="var(--warning)" strokeWidth={4} />
                  </c.chart.AreaChart>
                </c.chart.ResponsiveContainer>
              </div>

              <div className=''>
                <c.chart.ResponsiveContainer height={144} width={150}>
                  <c.chart.PieChart>
                    <c.chart.Tooltip />
                    <c.chart.Pie data={data.lst_cpt} innerRadius={25} outerRadius={60} dataKey="value" >
                      {data.lst_cpt.map((e, i)=><c.chart.Cell key={i} fill={"var(--"+e.color+")"} />)}
                    </c.chart.Pie>
                  </c.chart.PieChart>
                </c.chart.ResponsiveContainer>
              </div>

              <div className=''>{data.lst_cpt.map(({name, value, color}, i)=>
                <div key={i} className='f g1 center-v f-between'>
                  <span className='f g1 center-v'>
                    <c.Dot color={"var(--"+color+")"} />
                    <span>{name}</span>
                  </span>
                  <span>{value}</span>
                </div>
              )}</div>
            </div>

            <div className='f g1'>
              <div className='fcc'>{lang.agendado}  <div className='bold'>{data.lst_situacao.compartilhada.agendado}</div></div>
              <div className='fcc'>{lang.transito}  <div className='bold'>{data.lst_situacao.compartilhada.transito}</div></div>
              <div className='fcc'>{lang.encerrado} <div className='bold'>{data.lst_situacao.compartilhada.encerrado}</div></div>
              <div className='fcc'>{lang.no_show}   <div className='bold'>{data.lst_situacao.compartilhada.noshow}</div></div>
              <div className='fcc'>{lang.consumo}   <div className='bold'>{data.lst_situacao.compartilhada.percentual}%</div></div>
            </div>
          </div>
        </c.Frame>
      </>}

      <c.Frame title={lang.agendamento} loading={loading} style={{'--aniPos': '3'}}
        control={<div><div className='f g3 p2' style={{justifyContent: 'flex-end'}}>
          <div className='f center g1'><c.Dot color='var(--info)'/>{lang.cota}</div>
          <div className='f center g1'><c.Dot color='var(--success)'/>{lang.consumo_proprio}</div>
          <div className='f center g1'><c.Dot color='var(--danger)'/>{lang.consumo_outros}</div>
          <div className='f center g1'><c.Dot color='var(--warning)'/>{lang.disponivel}</div>
        </div></div>}
      >
        <c.chart.ResponsiveContainer height={350}>
          <c.chart.ComposedChart data={data.lst_consumo}>
            <c.chart.CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <c.chart.Tooltip /><c.chart.YAxis /><c.chart.XAxis dataKey="Data" />
            <c.chart.Area type="natural" dataKey="Cota" stroke="var(--info)" fill="var(--info)" strokeWidth={8} />
            <c.chart.Bar barSize={20} dataKey="Consumo Proprio" stackId="consumo_total" stroke="var(--success)" fill="var(--success)" strokeWidth={2} />
            <c.chart.Bar barSize={20} dataKey="Consumo Outros" stackId="consumo_total" stroke="var(--danger)" fill="var(--danger)" strokeWidth={2} />
            <c.chart.Bar barSize={20} dataKey="Disponivel" stackId="consumo_total" stroke="var(--warning)" fill="var(--warning)" strokeWidth={2} />
          </c.chart.ComposedChart>
        </c.chart.ResponsiveContainer>
      </c.Frame>
    </>}
</>
}