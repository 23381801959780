import { useState, useEffect, useContext, useDeferredValue } from "react";
import { AppContext, log } from "../../App";
import * as c from "../../c";
import * as f from "../../f";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Map, GeoJSON, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import TarifasModal from "./tarifas";

export default function Itinerarios({ setReloader }) {
  const App = useContext(AppContext),
    lang = {
      ...App.lang.global,
      ...App.lang.rotas,
      ...App.lang.itinerarios,
    },
    icons = App.icons;

  const [loading, setLoading] = useState({}),
    [busca, setBusca] = useState(""),
    [incluir, setIncluir] = useState(null),
    [editing, setEditing] = useState(null),
    [rotas, setRotas] = useState([]),
    [tarifasModal, setTarifasModal] = useState(false),
    [data, setData] = useState();

  function get() {
    setLoading(true);
    App.api("itinerarios::get").then((r) => {
      setRotas(r.results);
      setLoading(false);
    });
  }

  useEffect(() => {
    get();
    setReloader(() => get);
  }, []);

  return (
    <>
      <c.Frame
        loading={loading}
        title={lang.itinerarios}
        actions={{
          add: () => (setIncluir(true), setData({})),
        }}
        control={
          <>
            <div className="f f-row w100 center">
              <c.Input
                className="w100"
                placeholder={lang.busca}
                value={busca}
                onChange={(e) => setBusca(e.target.value)}
                clearable
              />
            </div>
          </>
        }
      >
        <c.Table
          hasSub={"sub"}
          data={rotas.map((n) => ({
            ...n,
            END_ORG:
              n.NM_LGDORI +
              " " +
              n.NM_BROORI +
              " ( " +
              f.formatCEP(n.CD_CEPORI) +
              " )  / " +
              n.NM_CIDORI +
              " - " +
              n.NM_UFORI,
            END_DST:
              n.NM_LGDDST +
              " " +
              n.NM_BRODST +
              " ( " +
              f.formatCEP(n.CD_CEPDST) +
              " )  / " +
              n.NM_CIDDST +
              " - " +
              n.NM_UFDST,
            QT_KM: n.QT_KM ?? "----",
            VL_PDG: "(R$) " + (n.VL_PDG ?? "----"),
            HR_DURACAO: !!n.HR_DURACAO
              ? n.HR_DURACAO.split(":")[0] +
                "h " +
                n.HR_DURACAO.split(":")[1] +
                "m"
              : "----",
            actions: (
              <>
                <c.IconButton onClick={() => setTarifasModal({...n})}>
                  <icons.FaDollarSign />
                </c.IconButton>
                <c.IconButton onClick={() => setEditing({ ...n })}>
                  <icons.BsFillPencilFill />
                </c.IconButton>
                {/* <button
                  style={{
                    padding: "11px",
                  }}
                  onClick={() => setTarifasModal(true)}
                >
                  <icons.FaDollarSign />
                </button> */}
              </>
            ),
            ORGPROD: n.ROTAS[0]?.NM_PESORI,
            ENDPROD: n.ROTAS[n.ROTAS.length - 1]?.NM_PESDST,
            sub: (
              <c.Table
                isSub
                withPagination={false}
                navigate={false}
                data={n.ROTAS}
                columns={[
                  [lang.id_referencia, "ID_EXT", {}, "f"],
                  [lang.parceiroOrg, "NM_PESORI", {}, "f"],
                  [lang.parceiroDes, "NM_PESDST", {}, "f"],
                  [lang.km, "QT_KM", {}, "f"],
                  [lang.pedagio, "VL_PDG", {}, "f"],
                  [lang.duracao, "HR_DURACAO", {}, "f"],
                  [lang.tp_rota, "TP_ROTA", {}, "f"],
                ]}
              />
            ),
          }))}
          columns={[
            ["#", "ID_UOCC", {}, "f center"],
            [lang.id_referencia, "ID_EXT", {}, "f"],
            [lang.descricao_itinerario, "DS_CMPROTA", {}, "f"],
            [lang.origem_inicial, "ORGPROD", {}, "f"],
            [lang.destino_final, "ENDPROD", {}, "f"],
            [lang.distancia_total, "QT_KM", {}, "f"],
            [lang.valor_pedagio_total, "VL_PDG", {}, "f"],
            [lang.duracao_total, "HR_DURACAO", {}, "f"],
            [lang.actions, "actions", { width: 100 }, "f center"],
          ]}
          search={busca}
        />
      </c.Frame>
      {incluir && (
        <ItinerariosModal
          onClose={() => setIncluir(null)}
          onFinish={() => get()}
        />
      )}
      {editing && (
        <ItinerariosModal
          ItinerarioData={editing}
          onClose={() => setEditing(null)}
          onFinish={() => get()}
        />
      )}
      {!!tarifasModal && <TarifasModal itinerario={tarifasModal} onClose={() => setTarifasModal(false)} />}
    </>
  );
}


function ItinerariosModal({ItinerarioData = null, onFinish, onClose}){
    const App = useContext(AppContext),
          lang = {...App.lang.global, ...App.lang.rotas, ...App.lang.itinerarios},
          icons = App.icons
  
    const 
          [listas, setListas] = useState({}),
          [loading, setLoading] = useState(false),
          [loadingMap, setLoadingMap] = useState(false),
          [pedagios, setPedagios] = useState([]),
          [rotaOrigemDestino, setRotaOrigemDestino] = useState([]),
          [loadingAddress, setLoadingAddress] = useState(false),
          [posOrigemDestino, setPosOrigemDestino] = useState({inicio: [],fim: []}),
          [rota, setRota] = useState({lat_lng_orig: [],lat_lng_dest: []}),
          [position, setPosition] = useState([-23.415881526446956, -51.93903347350793]),
          [keyMAP, setKeyMAP] = useState(Math.random()),
          [data, setData] = useState({
            idUocc: ItinerarioData?.ID_UOCC??0,
            id_ext: ItinerarioData?.ID_EXT??'',
            desc_it: ItinerarioData?.DS_CMPROTA??'',
            nr_cnpjexp: ItinerarioData?.ROTAS[0].CNPJORI??'',
            nr_cnpjrcb: ItinerarioData?.ROTAS[ItinerarioData?.ROTAS.length - 1].CNPJDST??'',
            rotas: ItinerarioData?.ROTAS.map(r => ({
              id_rota: parseInt(r.ID_UOCC),
              qt_km: parseFloat(r.QT_KM)??0,
              vl_pedagio: parseFloat(r.VL_PDG)??0,
              hr_duracao: r.HR_DURACAO??'00:00'
            }))??[],
          })
  
  
    function submit(){
      setLoading(true)
      return App.api('itinerarios::save',{...data,
      km_total: data.rotas.reduce((a,b)=>a+b.qt_km,0),
      ped_total: data.rotas.reduce((a,b)=>a+b.vl_pedagio,0),
      dur_total: data.hr_duracao.split(" ")[0] + ":" + data.hr_duracao.split(" ")[2],
      
      })
        .then(r=>{ setLoading(false)
          onFinish()
          return r.status
        })
    }
  
    useEffect(() => {
      if (!!data.nr_cnpjexp) {
        App.api('rotas::getEmbarcadores', { adicionais: true, s: data.nr_cnpjexp }).then(r => {
          setListas(listas => ({ ...listas, origens: r.results }));
        });
      }
    
      if (!!data.nr_cnpjrcb) {
        App.api('rotas::getEmbarcadores', { adicionais: true, s: data.nr_cnpjrcb }).then(r => {
          setListas(listas => ({ ...listas, destinos: r.results}));
        });
      }
      atualizaPedagios()
    }, [data.nr_cnpjexp, data.nr_cnpjrcb]);

  useEffect(() => {
    setLoading(true);
    Promise.all([App.api("rotas::get")]).then((r) => {
      setListas({
        ...listas,
        rotas: r[0].results,
        _rotas: r[0].results.map((r) => ({
          label: r.NM_PESORI + " / " + r.NM_PESDST,
          value: parseInt(r?.ID_UOCC),
        })),
      });
      setLoading(false);
    });
  }, []);

  const iconePedagio = new L.divIcon({
    className: "",
    iconSize: [32, 32],
    iconAnchor: [4, 10],
    labelAnchor: [-4, 0],
    popupAnchor: [0, -5],
    html: `<span style="color:#d04933;" class="fa fa-circle"></span>`,
  });

  function atualizarCustos(lat_lng_orig, lat_lng_dest) {
    fetch("https://velog.vertti.com.br/api/roteiro", {
      method: "POST",
      headers: { "content-type": "application/json, text/plain, */*" },
      body: JSON.stringify({
        sn_lat_lng: true,
        sn_rota_alternativa: false,
        sn_pedagio: true,
        sn_balanca: true,
        tag: "TRUCK",
        sn_calcu_volta: false,
        qtd_eixos: 6,
        veiculo_km_litro: 0,
        valor_medio_combustivel: 0,
        rotas: [
          { lat: lat_lng_orig[1], lng: lat_lng_orig[0] },
          { lat: lat_lng_dest[1], lng: lat_lng_dest[0] },
        ],
      }),
    })
      .then((r) => r.json())
      .then((r) => {
        setPedagios(r[0].track.rotas[0].pedagios);
        setRotaOrigemDestino(r[0].track.rotas[0].lng_lat);
        setPosOrigemDestino({
          inicio: [
            r[0].track.rotas[0].lng_lat[0][1],
            r[0].track.rotas[0].lng_lat[0][0],
          ],
          fim: [
            r[0].track.rotas[0].lng_lat[
              r[0].track.rotas[0].lng_lat.length - 1
            ][1],
            r[0].track.rotas[0].lng_lat[
              r[0].track.rotas[0].lng_lat.length - 1
            ][0],
          ],
        });

        setData({
          ...data,
          vl_pedagio:
            (r[0].track.rotas[0].vl_total_pedagio_original / 6)?.toFixed(2) ??
            0,
          qt_km: r[0].track.rotas[0].distancia,
          hr_duracao: r[0].track.rotas[0].duracao,
        });
      });
  }

    const somaHorarios = (horarios) => {
      const totalMinutos = horarios.reduce((acumulador, atual) => {
        if (atual.hr_duracao) {
          const [horas, minutos] = atual.hr_duracao.split(':');
          return acumulador + parseInt(horas) * 60 + parseInt(minutos);
        }
        return acumulador;
      }, 0);
    
      const horas = Math.floor(totalMinutos / 60);
      const minutos = totalMinutos % 60;
      
      setData({...data, hr_duracao: totalMinutos === 0 ? '0 h 0 min' : `${horas} h ${minutos} min`})
    };

    async function atualizaPedagios(){
      if( !!data.nr_cnpjexp && !!data.nr_cnpjrcb ){
        

        setLoadingMap(true)
        const origem = !!data.nr_cnpjexp ? listas.origens?.find(o=>parseInt(o.VALUE)===parseInt(data.nr_cnpjexp)) : null
        const destino = !!data.nr_cnpjrcb ? listas.destinos?.find(o=>parseInt(o.VALUE)===parseInt(data.nr_cnpjrcb)) : null


        if( origem !== null && destino !== null && origem !== undefined && destino !== undefined ){
          if( origem?.adicional?.cd_cep === '' || destino?.adicional?.cd_cep === '' ){
              setLoadingAddress(true)
          }
          const posOrigem = await buscarLongitudeLatitude(origem)
          
          const posDestino = await buscarLongitudeLatitude(destino)

          setLoadingAddress(false)

          setRota({...rota, 
            lat_lng_orig: (data.nr_cnpjexp === ItinerarioData?.ROTAS[0].CNPJORI) ? [parseFloat(ItinerarioData.NR_LGTORI), parseFloat(ItinerarioData.ROTAS[0].NR_LATORI)] : posOrigem, 
            lat_lng_dest: (data.nr_cnpjrcb === ItinerarioData?.ROTAS[ItinerarioData.ROTAS.length - 1].CNPJDST) ? [parseFloat(ItinerarioData?.ROTAS[ItinerarioData.ROTAS.length - 1]), parseFloat(ItinerarioData.NR_LATDST)] : posDestino})
          setPosition([posOrigem[1], posOrigem[0]])
          atualizarCustos(posOrigem, posDestino)



        }
        setLoadingMap(false)

      }
    }
    
    function handleOnDragEnd(result) {

      if (!result.destination) return;

    const items = Array.from(data.rotas);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    data.rotas = items;

    setData({
      ...data,
      nr_cnpjexp: listas.rotas.find(
        (r) => parseInt(r.ID_UOCC) === data?.rotas[0].id_rota
      )?.CNPJORI,
      nr_cnpjrcb: listas.rotas.find(
        (r) =>
          parseInt(r.ID_UOCC) === data?.rotas[data?.rotas?.length - 1].id_rota
      )?.CNPJDST,
    });
  }

  async function buscarLongitudeLatitude(objeto) {
    const endereco = `${objeto?.adicional?.nm_loc}, ${objeto?.adicional?.nm_uf}, ${objeto?.adicional?.nm_pais}`;
    const r = await fetch(
      `https://nominatim.openstreetmap.org/search?q=${endereco}&format=geojson`
    );
    const dados = await r.json();
    return dados.features[0].geometry.coordinates;
  }

  useEffect(
    () => atualizaPedagios(),
    [data.nr_cnpjexp, data.nr_cnpjrcb, data.vl_pedagio, listas, data.rotas]
  );
  useEffect(
    () => rotaOrigemDestino.length > 0 && setKeyMAP(Math.random()),
    [rotaOrigemDestino]
  );

  return (
    <c.Modal
      largeWidth
      onFinish={submit}
      loading={loading}
      title={
        !!ItinerarioData
          ? lang.editar_itinerario.replace("%d", ItinerarioData.ID_UOCC)
          : lang.criacao_itinerario
      }
      contentHeight={500}
      onClose={onClose}
      validate={!!data?.desc_it && !!data?.id_ext && !!data?.rotas.length > 0}
    >
      {/*  */}
      {
        <>
          <div className="f g2 w100">
            <div className="f g1 f-column f1 " style={{ width: "90%" }}>
              <c.Input
                required
                value={data.desc_it ?? ""}
                onChange={(e) => setData({ ...data, desc_it: e.target.value })}
                label={lang.descricao_itinerario}
              />

        <button disabled={data.rotas.some(r=>r.id_rota === 0)}
                  title={lang.tipo}
                  onClick={() => {
                    data.rotas.push({
                      id_rota: 0
                    });
                    setData({ ...data });
                  }}
                >
                  <icons.MdAdd />
        </button>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="rotas" >
            {(provided) => (
              <div className="rotas" id="rotas"{...provided.droppableProps} ref={provided.innerRef}>
        {data.rotas.map(({id_rota}, i) => (
          <Draggable key={i} draggableId={i.toString()} index={i}>
            {(provided) => (
          <div key={i} className="f f-row w100" ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
            <c.Select
            searchable
            className="w100"
              required
              label={lang.origem_destino.replace("%d", i.toString())}
              value={id_rota}
              onChange={(e) => {
                data.rotas[i].id_rota = e.value;
                data.rotas[i].qt_km = parseFloat(listas.rotas.find(r=>parseInt(r.ID_UOCC) === e.value)?.QT_KM??0);
                data.rotas[i].vl_pedagio = parseFloat(listas.rotas.find(r=>parseInt(r.ID_UOCC) === e.value)?.VL_PDG??0);
                data.rotas[i].hr_duracao = listas.rotas.find(r=>parseInt(r.ID_UOCC) === e.value)?.HR_DURACAO??'00:00';
                

                setData({ ...data, nr_cnpjexp: listas.rotas.find(r=>parseInt(r.ID_UOCC) === data?.rotas[0].id_rota)?.CNPJORI, nr_cnpjrcb: listas.rotas.find(r=>parseInt(r.ID_UOCC) === data?.rotas[data?.rotas?.length - 1].id_rota)?.CNPJDST });
                somaHorarios(data.rotas);
              }}
              options={listas._rotas}
            />
            <button style={{fontSize: '1.0 rem', marginLeft: '5px'}}
                  title={lang.tipo}
                  onClick={() => {
                    data.rotas.splice(i, 1);
                    atualizaPedagios();
                    setData({ ...data, nr_cnpjexp: '', nr_cnpjrcb: '' });
                  }}
                >
                  <icons.MdRemove />
                </button>
            </div>
            )}
            </Draggable>
        ))}
        {provided.placeholder}
        </div>
        )}
          </Droppable>
        </DragDropContext>
        <div className="f">
        <c.Span label={lang.distancia_total}
        value={isNaN(data.rotas.reduce((a,b)=>a+b.qt_km,0)) ? 0 : data.rotas.reduce((a,b) => a+ b.qt_km, 0) + " (KM)"} />
        <c.Span label={lang.valor_pedagio_total}
        value={"R$ " + (isNaN(data.rotas.reduce((a,b)=>a+b.vl_pedagio,0)) ? 0 : data.rotas.reduce((a,b) => a+ b.vl_pedagio, 0))} />
        <c.Span label={lang.duracao_total}
        value={data.hr_duracao} />
        </div>
        <c.Input required value={data.id_ext??''} onChange={(e) => setData({...data, id_ext: e.target.value})} label={lang.id_referencia} />
        
        </div>
        <div className="f2 radius overflow-hidden box-shadow">
            {
            loadingAddress ? <div className="f center " style={{color: 'red'}}><h1>{lang.endereco_nao_cadastrado}</h1></div>  : loadingMap  ?  <App.LoadingComponent /> :
            <Map key={keyMAP} center={position} zoom={8} style={{ position: "relative", width: "100%", height: "500px" }}>
                {posOrigemDestino.inicio.length > 0 && posOrigemDestino.fim.length > 0 && <>
                  <Marker position={posOrigemDestino.inicio}><Popup>Inicio da Rota</Popup></Marker>
                  <Marker position={posOrigemDestino.fim}><Popup>Final da Rota</Popup></Marker>
                  <GeoJSON attribution='&copy; <a href="https://velog.com.br">VELOG</a>'
                    data={{type: "RotaCollection",
                      features: [{type: "Feature", geometry: {type: "LineString", coordinates: rotaOrigemDestino}}],
                    }}
                  />
                </>}
                {pedagios.map((value, index) => (
                  <Marker icon={iconePedagio} key={`pedagio-${index}`} position={[value.id_lat, value.id_long]} >
                    <Popup>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                        <div style={{ backgroundImage: `url("${value.ds_logo}")`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain", width: "100px", minHeight: "70px", maxHeight: "100px" }} />
                        <span>{value.ds_praca}</span> <span><strong>{f.formatCurrency(value.vl_tarifa / 6)}</strong></span>
                      </div>
                    </Popup>
                  </Marker>
                ))}
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>' />
              </Map>
            }
          </div> 
        </div>
      </>}
    </c.Modal>)
  }
  
