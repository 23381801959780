import { useContext } from "react"
import { AppContext } from '../../App'
import * as c from '../../c'
import * as p from '..'
import * as f from '../../f'

export default function GerencialOperacaoCards({data}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.contratos},
        icons = App.icons

return(<>
    <c.Card icon={icons.BsArrowsMove}     title={lang.cota}           value={data.qt_cota}         percent={data.pr_cota} />
    <c.Card icon={icons.BsCurrencyDollar} title={lang.cif}            value={data.qt_cif}          percent={data.pr_cif} />
    <c.Card icon={icons.FaHandshake}      title={lang.fob}            value={data.qt_fob}          percent={data.pr_fob} />
    <c.Card icon={icons.FaShareAlt}       title={lang.redistribuido}  value={data.qt_distribuida}  percent={data.pr_distribuida} type='info' />
    <c.Card icon={icons.BsCalendar3}      title={lang.consumida}      value={data.qt_consumida}    percent={data.pr_consumida}   type='success' />
    <c.Card icon={icons.BsCalendar3}      title={lang.disponivel}     value={data.qt_disponivel}   percent={data.pr_disponivel}  type='warning' />

    <c.Card icon={icons.BsCalendar3}      title={lang.agendado}       value={data.lst_portaria?.[3]?.QT_VCL??0}  type='info' />
    <c.Card icon={icons.FaRoad}           title={lang.transito}       value={data.lst_portaria?.[4]?.QT_VCL??0}  type='warning' />
    <c.Card icon={icons.BsClock}          title={lang.em_fila}        value={data.lst_portaria?.[5]?.QT_VCL??0}  type='warning' />
    <c.Card icon={icons.BsClock}          title={lang.em_operacao}    value={data.lst_portaria?.[6]?.QT_VCL??0}  type='info' />

    <c.Card icon={icons.BsClock}          title={lang.encerrado}      value={data.lst_portaria?.[7]?.QT_VCL??0}  percent={data.lst_portaria?.[7]?.PR_VCL??0}  type='success' />
    <c.Card icon={icons.FaEyeSlash}       title={lang.no_show}        value={data.lst_portaria?.[8]?.QT_VCL??0}  percent={data.lst_portaria?.[8]?.PR_VCL??0}  type='success' />

    <c.Card icon={icons.FaBalanceScale}   title={lang.peso}           value={data.qt_volume?.toLocaleString()}           percent={data.pr_volume} />
    <c.Card icon={icons.FaBalanceScale}   title={lang.peso_medio}     value={data.qt_volumemedio?.toLocaleString()}  percent={data.pr_volumemedio} />

    <c.Card icon={icons.BsClock}          title={lang.tempo_transito}   value={data.tempo_trs||'00:00'}      percent={data.pr_diftrs} />
    <c.Card icon={icons.BsClock}          title={lang.tempo_fila}       value={data.tempo_fila||'00:00'}     percent={data.pr_diffila} />
    <c.Card icon={icons.BsClock}          title={lang.tempo_operacao}   value={data.tempo_opr||'00:00'}      percent={data.pr_difopr} />
    <c.Card icon={icons.BsClock}          title={lang.tempo_total}      value={data.tempo_estadia||'00:00'}  percent={data.pr_difestadia} />
  </>)
}