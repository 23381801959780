import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as f from '../../f'
import * as p from '../'

export default function Seguros({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.seguros},
        icons = App.icons

  const [loading, setLoading] = useState({}),
        [incluirApolice, setIncluirApolice] = useState(null),
        [data, setData] = useState([])

  function get(){ setLoading(true)
    App.api('apolices::get').then(r=>{
      setData({
        seguros: r.results.seguros.map(d=>({...d,
          SITUACAO: <span className={["destaque", d.DS_STS==='Aprovado'?'success':d.DS_STS==='Pendente'?'warning':'danger'].join(' ')}>{d.DS_STS}</span>,
        })),
        apolices: r.results.apolices.map(d=>({...d,
          SITUACAO: <span className={["destaque", d.DS_STS==='Aprovado'?'success':d.DS_STS==='Pendente'?'warning':'danger'].join(' ')}>{d.DS_STS}</span>,
        })),
      })
      setLoading(false)
    })
  }

  useEffect(()=>{get()
    setReloader(()=>get)
  },[])

  return(<>
    <c.Frame title={lang.seguros} loading={loading}>
      <c.Table data={data?.seguros} columns={[
        [lang.seguro, 'DS_TSGR'],
        [lang.dt_obrigatorio, 'DT_OBR'],
        [lang.apolice, 'DS_APOLICE'],
        [lang.inicio, 'DT_INIVIG'],
        [lang.termino, 'DT_FIMVIG'],
        [lang.situacao, 'SITUACAO'],
      ]} withPagination={false} />
    </c.Frame>

    <c.Frame title={lang.apolices_de_seguro} loading={loading}
      actions={{
        add: ()=>setIncluirApolice(true)
      }}
    >
      <c.Table data={data?.apolices} columns={[
        [lang.seguro, 'DS_TSGR'],
        [lang.apolice, 'DS_APOLICE'],
        [lang.cadastro, 'DT_CDT'],
        [lang.inicio, 'DT_INIVIG'],
        [lang.termino, 'DT_FIMVIG'],
        [lang.situacao, 'SITUACAO'],
      ]} withPagination={false} />
    </c.Frame>

    {incluirApolice&&<IncluirApolice onClose={()=>setIncluirApolice(null)} onFinish={()=>get()} />}
  </>)
}

function IncluirApolice({onClose, onFinish}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.seguros},
        icons = App.icons

  const [loading, setLoading] = useState(false),
        [tipos, setTipos] = useState([]),
        [data, setData] = useState([])

  function get(){
    App.api('apolices::getTipos').then(r=>
      setTipos(r.results.map(t=>({value: t.ID_TSGR, label: t.DS_TSGR})))
    )
  }

  function submit(){
    setLoading(true)
    return App.api('apolices::save',{...data}).then(r=>{
      if( !r.status ) setLoading(false)
      return r.status ? App.api('apolices::sendMail', {id_uocc: r.results,
        pdf: data.pdf, nm_pdf: data.nome_pdf,
      }).then(r=>{
        if( r.status ) onFinish()
        setLoading(false)
        return r.status
      }) : false
    })
  }

  useEffect(()=>get(),[])

  return(<c.Modal title={lang.incluir_apolice_title} onClose={onClose} loading={loading} successMsg={lang.apolice_registrada}
    onFinish={submit} validate={!!data.id_tsgr&&!!data.ds_apolice&&!!data.dt_inivig&&!!data.dt_fimvig&&!!data.pdf}
  >
    <div className="w100 f g1">
      <c.Select label={lang.seguro} options={tipos} required value={data.id_tsgr} onChange={e=>setData({...data, id_tsgr: e.value})} />
      <c.Input label={lang.apolice} error={data.ds_apolice<5} required value={data.ds_apolice} onChange={e=>setData({...data, ds_apolice: e.target.value})} />
      <c.Input label={lang.inicio_vigencia}  error={!data.dt_inivig} required type="date" value={data.dt_inivig} onChange={e=>setData({...data, dt_inivig: e.target.value})} />
      <c.Input label={lang.termino_vigencia} error={!data.dt_fimvig} required type="date" value={data.dt_fimvig} onChange={e=>setData({...data, dt_fimvig: e.target.value})} />
    </div>

    <div className="w100 f g1 center-v">
      <label>
        <span className="button">Upload</span>
        <input type="file" accept=".pdf" onChange={e=>f.readFile(e.target.files[0]).then(r=>setData({...data, pdf: r, nome_pdf: e.target.files[0]['name']}))} className="hide" />
      </label>
      <c.Span value={data.nome_pdf} />
    </div>
    
    {!!data.pdf&&<c.Frame title="Visualização" style={{maxHeight: 400, overflow: 'auto'}}><c.PreviewPDF file={data.pdf} /></c.Frame>}
  </c.Modal>)
}