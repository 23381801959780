import { useContext, useState, useEffect, Fragment } from 'react'
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as p from '../../p'
import * as f from '../../f'

export default function TransportadorAgendados({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador},
        icons = App.icons

  const [loading, setLoading] = useState(false),
        [data, setData] = useState([]),
        [cargasFracionadas, setCargasFracionadas] = useState([]),
        [openTicket, setOpenTicket] = useState(null),
        [openTicketFracionado, setOpenTicketFracionado] = useState(null),
        [openDfe, setOpenDfe] = useState(false),
        [openCTe, setOpenCTe] = useState(false),
        [openCTeMM, setOpenCTeMM] = useState(false),
        [openMDFe, setOpenMDFe] = useState(false),
        // [openOrdem, setOpenOrdem] = useState(false),
        // [openTicketPesagem, setOpenTicketPesagem] = useState(false),
        [openDcoAuxModal, setOpenDcoAuxModal] = useState(false),
        [showFilters, setShowFilters] = useState(false),
        [agendarAppa, setAgendarAppa] = useState(null)

  function liberarPortaria(portaria){
    if( portaria.TP_OPRPRT === 'E' || portaria.IN_NFE !== true || !!portaria.IN_NFE_XML){
      App.confirm( lang.deseja_liberar_transito.replace('%d', portaria.ID_VCL), 
        () => App.api('portarias::alteraSituacao', {id_uocc: portaria.ID_UOCC, cd_sts:'5'})
              .then(r=>{
                // if( r.status && portaria.TP_OPRPRT === 'R' ) {
                //   // sendAppApiNovoAgendamento é uma async mas está sendo chamada sem await pois será em background
                //   f.sendAppApiNovoAgendamento({App, cota, motorista, tracao, 
                //     prtIdUocc: portaria.ID_UOCC,
                //     cnpjTrp: sessionStorage.getItem('nr_cnpj'),
                //   })      
                //  }             
                App.toast.success(lang.liberado_transito)
                get()
                return r.status
              })
      )
    } else { setOpenDfe(portaria) }
  }

  async function get(){
    setLoading(true)

    App.api('ordemCarregamento::getPortariasByTrp').then(r => 
      setCargasFracionadas( r.results )
    )

    const portarias = (await App.api('portarias::get')).results

    const extras = (await App.api('portarias::getExtras',{ID_UOCCs: portarias.map(i => i.ID_UOCC)})).results

    setData(portarias.map(portaria => {
      const docs = extras.dcos[portaria.ID_UOCC]
      const nfes = docs.filter(d=>parseInt(d.TP_DF) === 55)
      const ctes = docs.filter(d=>parseInt(d.TP_DF) === 57 && d.CD_IDC === null)
      const ctemms = docs.filter(d=>parseInt(d.TP_DF) === 57 && d.CD_IDC === '1')
      //const mdfes = docs.filter(d=>parseInt(d.TP_DF) === 58)
      const mdfes = extras.mdfes[portaria.ID_UOCC]

      const nfe_status = !nfes.length ? 0 : (
        nfes.some(d => d.validacoes?.filter(v=>parseInt(v.CD_STS) === 1).length) ? 2
        : (nfes.some(d => d.validacoes?.filter(v=>parseInt(v.CD_STS) === -3).length) ? 3 : 1)
      )
      const cte_status = !ctes.length ? 0 : (
        ctes.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === 1).length) ? 2
        : (ctes.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === -3).length) ? 3 : 1)
      )
      const ctemm_status = !ctemms.length ? 0 : (
        ctemms.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === 1).length) ? 2
        : (ctemms.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === -3).length) ? 3 : 1)
      )
      const mdfe_status = !mdfes.length ? 0 : (
        mdfes.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === 1).length) ? 2
        : (mdfes.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === -3).length) ? 3 : 1)
      )
      const dfe_status_labels = ['Não cadastrada','Aprovada','Necessita verificação','Recusada']

      return {...portaria,
        dco_aux: extras.dcos_aux[portaria.ID_UOCC], 
        dcos: extras.dcos[portaria.ID_UOCC],
        steps: extras.steps[portaria.ID_UOCC],
        nfe_status: nfe_status,
        cte_status: cte_status,
        ctemm_status: ctemm_status,
        mdfe_status: mdfe_status,
        nfe_status_label: dfe_status_labels[nfe_status],
        cte_status_label: dfe_status_labels[cte_status],
        ctemm_status_label: dfe_status_labels[ctemm_status],
        mdfe_status_label: dfe_status_labels[mdfe_status],
        ordem_status: !!portaria.LST_ORDEM,
        ordem_status_label: !!portaria.LST_ORDEM ? 'Ordem: Cadastrada' : 'Ordem: Não cadastrada',
        rf_status: !!portaria.LST_RF,
        rf_status_label: !!portaria.LST_RF ? 'Resumo de frete: Cadastrada' : 'Resumo de frete: Não cadastrada',

        ticket_pesagem_status: !!portaria.LST_TICKET_PESAGEM,
        ticket_pesagem_status_label: !!portaria.LST_TICKET_PESAGEM ? 'Ticket de pesagem: Cadastrada' : 'Ticket de pesagem: Não cadastrada',
        docs_ticket_pesagem: extras.dcos_aux[portaria.ID_UOCC].filter(d=>parseInt(d['TP_DCO'])===2),
      }
    }))
    setLoading(false)
  }

  useEffect(()=>{get()
    setReloader(()=>get)
  }, [])
  
  useEffect(()=>{
    let extras;
    App.api('portarias::getExtras',{ID_UOCCs: data.map(i => i.ID_UOCC)}).then(r =>{
      extras = r.results
      data.forEach(e => {
        const docs = extras.dcos[e.ID_UOCC]
        const nfes = docs.filter(d=>parseInt(d.TP_DF) === 55)
      });
      
    })
    
  },[data])

  return(<>
    <c.Tabs>
      <c.TabList>
        <c.Tab>Carga cheia <span className='destaque info'>{(data??[]).length}</span></c.Tab>
        <c.Tab>Carga fracionada <span className='destaque info'>{(cargasFracionadas??[]).length}</span></c.Tab>
      </c.TabList>

      {/* Cargas cheias */}
      <c.TabPanel>
        <c.Frame title={lang.veiculos_agendados} className='w100' loading={loading}
          actions={{
            filter: {onClick:()=>setShowFilters(!showFilters), value: showFilters}
          }}
        >
          <c.Table loading={loading} showFilters={showFilters} className='w100' 
            columns={[
              ['', 'ACTION', {}, 'force-fit'],
              [lang.operacao, 'DS_OPRPRT', {}, 'force-fit'],
              [lang.ticket, 'ID_UOCC'],
              [lang.veiculo, 'ID_VCL'],
              [lang.contrato, 'ID_CN'],
              [lang.produto, 'NM_PRO'],
              [lang.cliente, 'NM_PRC'],
              [lang.origem, 'NM_ORI'],
              [lang.destino, 'NM_DST'],
              [lang.motorista, 'NM_MTR'],
              [lang.situacao, 'DS_STS'],
              [lang.previsao, 'DT_PRV'],
              [lang.turno, 'DS_TRN'],
              [<><icons.BsPlugin/>Integrações</>, '_INTEGRATIONS', {}, 'force-fit'],
              [<><icons.MdCloudDownload />{lang.downloads}</>, 'downloads', {}, 'f g1'],
            ]} 
            data={data.map(p => ({...p,
              _INTEGRATIONS: (!!p.ID_APPA && !!p.IN_NFE_XML) ? (
                !!p.IN_APPA_AGENDADO
                  ? <icons.FaShip title={'APPA OK'} className='t-success' size={18} />
                  : <c.IconButton title={'APPA Pendente'}
                      onClick={() => setAgendarAppa(p)}
                    >
                      <icons.FaShip className='t-danger blink circle' />
                    </c.IconButton>
              ) : <></>,
              ID_CN: p.ID_CN??'----',
              DS_OPRPRT: p.DS_OPRPRT==='Carga'?<icons.CargaIcon title={lang.carga} size={24} />:<icons.DescargaIcon title={lang.descarga} size={24} />,
              ACTION: <c.DropdownMenu itens={[
                        {title: lang.liberar_transito, icon: <icons.FaRoad />,  disabled: p.CD_STS!=='4', action: ()=>liberarPortaria(p)},
                        {title: lang.imprimir_ticket,  icon: <icons.FaPrint />, disabled: p.CD_STS==='4', action: ()=>setOpenTicket(p)},
                        {
                          title: lang.anexar_nfe, icon: <icons.MdAttachFile />, 
                          disabled: p.IN_NFE!==true||p.TP_OPRPRT==='E'||(p.TP_OPRPRT==='R' && ![4,5].includes(p.CD_STS|0)), 
                          action: ()=>setOpenDfe(p)
                        },
                        {title: lang.anexar_cte, icon: <icons.MdAttachFile />, disabled: p.TP_OPRPRT==='E' && false, action: ()=>setOpenCTe(p)},
                        {title: lang.anexar_ctemm, icon: <icons.MdAttachFile />, disabled: p.IN_NFE!==true||p.TP_OPRPRT==='E', action: () => setOpenCTeMM(p)},
                        {title: lang.anexar_mdfe, icon: <icons.MdAttachFile />, disabled: p.TP_OPRPRT==='E' && false, action: ()=>setOpenMDFe(p)},
                        // {title: p.TP_OPRPRT==="R" ? lang.anexar_ordem_descarga : lang.anexar_ordem_carga, icon: <icons.MdAttachFile />, disabled: false, action: ()=>setOpenOrdem(p)},
                        // {title: lang.anexar_ticket_pesagem, icon: <icons.MdAttachFile />, disabled: p.TP_OPRPRT==='E', action: ()=>setOpenTicketPesagem(p)},
                        {title: lang.anexar_documento_auxiliar, icon: <icons.MdAttachFile />, disabled: false, action: () => setOpenDcoAuxModal(p)},
                      ]}><c.IconButton icon={icons.BsThreeDotsVertical} size={24} /></c.DropdownMenu>,
              nfe_status_icon:
                <c.IconButton onClick={()=>!!p.nfe_status && window.open(App.baseURL+'download/?k=' + btoa('nfe|' + p.ID_UOCC))}>
                  {[
                    <icons.BsQuestionCircleFill size={16} />,
                    <icons.BsCheck2Circle style={{color: 'var(--success)'}} size={16} />,
                    <icons.MdReportProblem style={{color: 'var(--warning)'}} size={16} />,
                    <icons.MdCancel style={{color: 'var(--cancel)'}} size={16} />
                  ][p.nfe_status]}
                </c.IconButton>,
              cte_status_icon:
                <c.IconButton onClick={()=>!!p.cte_status && window.open(App.baseURL+'download/?k=' + btoa('cte|' + p.ID_UOCC))}>
                  {[
                    <icons.BsQuestionCircleFill size={16} />,
                    <icons.BsCheck2Circle style={{color: 'var(--success)'}} size={16} />,
                    <icons.MdReportProblem style={{color: 'var(--warning)'}} size={16} />,
                    <icons.MdCancel style={{color: 'var(--cancel)'}} size={16} />
                  ][p.cte_status]}
                </c.IconButton>,
              ordem_icon: 
                <c.IconButton onClick={()=>!!p.LST_ORDEM&&window.open(App.baseURL+'download/?k=' + btoa('ordem|' + p.ID_UOCC))}>
                  {!p.LST_ORDEM ? <icons.BsQuestionCircleFill size={16} /> : <icons.BsCheck2Circle style={{color: 'var(--success)'}} size={16} />}
                </c.IconButton>,
              rf_icon: 
                <c.IconButton onClick={()=>!!p.LST_RF&&window.open(App.baseURL+'download/?k=' + btoa('rf|' + p.ID_UOCC))}>
                  {!p.LST_RF ? <icons.BsQuestionCircleFill size={16} /> : <icons.BsCheck2Circle style={{color: 'var(--success)'}} size={16} />}
                </c.IconButton>,
              ticket_pesagem_icon: 
                <c.IconButton onClick={()=>!!p.LST_TICKET_PESAGEM&&window.open(App.baseURL+'download/?k=' + btoa('ticket_pesagem|' + p.ID_UOCC))}>
                  {!p.LST_TICKET_PESAGEM ? <icons.BsQuestionCircleFill size={16} /> : <icons.BsCheck2Circle style={{color: 'var(--success)'}} size={16} />}
                </c.IconButton>,
              downloads: <c.PortariaDownloadButtons portaria={p} />,
            }))} />
        </c.Frame>
      </c.TabPanel>


      {/* Cargas fracionadas */}
      <c.TabPanel>
        <c.Frame title="Cargas fracionadas" className='w100' loading={loading}>
          <c.Table hasSub='_SUB' loading={loading} className='w100'
            columns={[
              ['', 'ACTION', {}, 'force-fit'],
              [lang.operacao, 'DS_OPRPRT', {}, 'force-fit'],
              [lang.ticket, 'ID_UOCC'],
              [lang.veiculo,'ID_VCL'],
              [lang.produto_predominante,'NM_PRO'],
              [lang.motorista,'NM_MOTORISTA'],
              [lang.situacao,'_DS_STS'],
              [lang.previsao,'DT_PRV'],
              [lang.turno,'DS_TRN'],
            ]} data={
              cargasFracionadas.map(carga=>{
                let ds_sts = carga.CD_STS === -3 ? 'Cancelado' 
                            :carga.CD_STS ===  1 ? 'Não agendado'
                            :carga.CD_STS ===  2 ? 'Em coleta'
                            :carga.CD_STS ===  3 ? 'Em entrega'
                            :carga.CD_STS === -1 ? 'Encerrado' : ''
                return {...carga,
                  _DS_STS: <div className={['destaque fit-content', carga.CD_STS!=='-1'?'success':'warning'].join(' ')}>{ds_sts}</div>,
                  DS_OPRPRT: carga.TP_OPRPRT==='E'?<icons.CargaIcon title={lang.carga} size={24} />:<icons.DescargaIcon title={lang.descarga} size={24} />,
                  ACTION: <c.DropdownMenu itens={[
                    {title: lang.imprimir_ticket,  icon: <icons.FaPrint />, disabled: false, action: ()=>setOpenTicketFracionado(carga)},
                  ]}><c.IconButton icon={icons.BsThreeDotsVertical} size={24} /></c.DropdownMenu>,
                  NM_PRO: carga.PRODUTO_PREDOMINANTE?.NM_PRO,
                  DT_PRV: App.moment(carga.TURNO_INICIAL?.DT_CRG).format('DD/MM/YYYY'),
                  DS_TRN: carga.TURNO_INICIAL?.DS_TRN,
                  DS_STS: ds_sts,
                  _SUB: <div className='f g1 f-column'>
                    <c.Table isSub columns={[
                      ['','ACTIONS'],
                      ['Portaria','_ID_UOCC'],
                      ['Produto predominante', 'NM_PRO'],
                      ['Origem', 'NM_UND'],
                      ['Data e turno', 'DS_DATA_TURNO'],
                      ['Situação', '_DS_STS'],
                      [<><icons.MdCloudDownload/>{lang.downloads}</>, 'downloads', {}, 'f g1 force-fit'],
                    ]} data={
                      carga.PORTARIAS.map(portaria => {
                        let ds_sts = portaria.CD_STS === '-1' ? lang.encerrado : 
                                     portaria.CD_STS === '1'  ? lang.em_fila :
                                     portaria.CD_STS === '2'  ? lang.recepcionado :
                                     portaria.CD_STS === '3'  ? lang.em_operacao :
                                     portaria.CD_STS === '4'  ? lang.agendado :
                                     portaria.CD_STS === '5'  ? lang.transito : ''
                        return {...portaria,
                          _DS_STS: <div className={['destaque fit-content', carga.CD_STS!=='-1'?'success':'warning'].join(' ')}>{ds_sts}</div>,
                          ACTIONS: <c.DropdownMenu itens={[
                                    // {title: lang.liberar_transito, icon: <icons.FaRoad />,  disabled: p.CD_STS!=='4', action: ()=>liberarPortaria(p)},
                                    {title: lang.anexar_nfe, icon: <icons.MdAttachFile />, disabled: !!portaria['LST_NFE'], action: ()=>setOpenDfe(portaria)},
                                    {title: lang.anexar_cte, icon: <icons.MdAttachFile />, disabled: false, action: ()=>setOpenCTe(portaria)},
                                    // {title: portaria.TP_OPRPRT==="R" ? lang.anexar_ordem_descarga : lang.anexar_ordem_carga, icon: <icons.MdAttachFile />, disabled: false, action: ()=>setOpenOrdem(portaria)},
                                    // {title: lang.anexar_ticket_pesagem, icon: <icons.MdAttachFile />, disabled: false, action: ()=>setOpenTicketPesagem(portaria)},
                                    {title: lang.anexar_documento_auxiliar, icon: <icons.MdAttachFile />, disabled: false, action: () => setOpenDcoAuxModal(portaria)},
                                  ]}><c.IconButton icon={icons.BsThreeDotsVertical} size={24} /></c.DropdownMenu>,
                          downloads: <c.PortariaDownloadButtons portaria={portaria} />,
                          _ID_UOCC: f.formatNumber(portaria.ID_UOCC),
                          DS_DATA_TURNO: App.moment(portaria.DT_PRV).format('DD/MM/YYYY') + ' - ' + portaria.DS_TRN,
                          DS_STS: ds_sts,
                        }
                      })
                    } />

                    <hr />
                    
                    <c.Table isSub 
                      columns={[
                        ['Destino','NM_PES'],
                        ['Itens','_ITENS'],
                      ]}
                      data={
                        carga.DESTINOS.map(destino => ({...destino,
                          _ITENS: destino.ITENS.reduce((acc, item) => <>{acc}
                            {item.QT_EXP} x {carga.ITENS.find(ci => ci.ID_PRO === item.ID_PRO)?.NM_PRO} {item.ID_UNDMEXP}
                          <br /></>, '')
                        }))
                      }
                    />
                  </div>,
                }
              })
            } 
          />
        </c.Frame>
      </c.TabPanel>
    </c.Tabs>

    {!!openDfe && 
      <p.DfeModal tela="agendado" mod={55} onClose={()=>setOpenDfe(false)} portaria={openDfe}
        onFinish={()=>{
          return App.api('portarias::alteraSituacao', {
            id_uocc: openDfe.ID_UOCC, cd_sts: '5',
          }).then(r=>{
            if( r.status ) get()
            return r.status
          })
        }} />}

    {!!openCTe && <p.DfeModal mod={57} onClose={()=>setOpenCTe(false)} onFinish={()=>get()} portaria={openCTe} tela="agendado" />}

    {!!openCTeMM && <p.DfeModal mod={'57m'} onClose={()=>setOpenCTeMM(false)} onFinish={()=>get()} portaria={openCTeMM} tela="agendado" />}

    {!!openMDFe && <p.DfeModal mod={58} onClose={()=>setOpenMDFe(false)} onFinish={()=>get()} portaria={openMDFe} tela="agendado" />}

    {/* {!!openOrdem && <p.AnexarDcoAuxModal tipo='ordem' portaria={openOrdem} onClose={()=>setOpenOrdem(false)} onFinish={()=>get()} />}
    {!!openTicketPesagem && <p.AnexarDcoAuxModal tipo='ticket_pesagem' portaria={openTicketPesagem} onClose={()=>setOpenTicketPesagem(false)} onFinish={()=>get()} />} */}

    {!!openDcoAuxModal && <p.AnexarDcoAuxModal portaria={openDcoAuxModal} onClose={()=>setOpenDcoAuxModal(false)} onFinish={()=>get()} />}

    {!!openTicket && <p.PrintTicketModal idPrt={openTicket.ID_UOCC} onClose={()=>setOpenTicket(false)} />}
    {!!openTicketFracionado && <p.PrintTicketFracionadoModal ocId={openTicketFracionado.ID_UOCC} onClose={()=>setOpenTicketFracionado(null)} />}

    {!!agendarAppa && <AgendarAppaModal portaria={agendarAppa} onClose={()=>setAgendarAppa(null)} onFinish={()=>get()} />}
  </>)
}

function AgendarAppaModal({portaria, onClose, onFinish}){
  const App = useContext(AppContext)

  const [loading, setLoading] = useState(null),
        [disponibilidades, setDisponibilidades] = useState({}),
        [cotaInt, setCotaInt] = useState({}),
        [hora, setHora] = useState(null)

  async function get(){
    setLoading(true)

    let _cotaInt = (await App.api('IntegracaoAppa::getCotaIntById', {
          id: portaria.ID_APPA
        })).results
    setCotaInt( _cotaInt )

    let _disponibilidades = (await App.api('IntegracaoAppa::disponibilidades', {
      int_id: portaria.ID_APPA
    })).results
    setDisponibilidades( _disponibilidades.find(disp => disp.DATA === _cotaInt.DT_PRV) )
    
    setLoading(null)
  }

  async function submit(){
    setLoading(true)

    const status = (await App.api('IntegracaoAppa::preCriarAgendamento',{
      portaria, cotaInt, hora
    })).status
    
    setLoading(false)
    if( status ) { onFinish() }
    return status
  }

  useEffect(() => {
    get()
  }, [])

  return(
    <c.Modal title={'Agendamento APPA - ' + App.moment(cotaInt.DT_PRV).format('DD/MM/YYYY')} 
      onClose={onClose} loading={loading} width={400}
      validate={!!hora}
      onFinish={submit}
    >
      Selecione um horário
      <div className='f g1 f-wrap'>
        {disponibilidades?.HORARIOS?.map((horario, id) => <Fragment key={id}>
          <button key={id} disabled={horario.disponivel === false}
            className={ hora === horario.hora ? 'info' : '' }
            onClick={() => setHora( horario.hora )}
          >{horario.hora}</button>
        </Fragment>)}
      </div>
    </c.Modal>
  )
}