import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as f from '../../f'
import * as p from '../'

export default function DistribuirCotas({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.distribuir_cotas},
        icons = App.icons

  const [loading, setLoading] = useState({}),
        [busca, setBusca] = useState(''),
        [openDistribuir, setOpenDistribuir] = useState(false),
        [openDistribuida, setOpenDistribuida] = useState(false),
        [data, setData] = useState([])

  function get(){
    setLoading(true)
    App.api('cotas::getDistribuir').then(r=>{setData(r.results);setLoading(false)})
  }

  useEffect(()=>{get()
    setReloader(()=>get)
  }, [])

  return(<>
  <c.Frame title={lang.distribuirCotas} loading={loading}
    control={<c.Input placeholder={lang.busca} value={busca} onChange={e=>setBusca(e.target.value)} clearable />}
  >
    <c.Table search={busca} 
      columns={[
        [lang.data, 'DT_COTA'],
        [lang.contrato, 'ID_CN'],
        [lang.pedido_cliente,'NR_CLI'],
        [lang.operacao, 'DS_OPRPRT'],
        [lang.cliente, 'NM_PESPRC'],
        [lang.origem, 'NM_PESORI'],
        [lang.destino, 'NM_PESDST'],
        [lang.produto, 'NM_PRO'],
        [lang.turno, 'DS_TRN'],
        [lang.cota, 'QT_COTA',{},'f center'],
        [lang.distribuir, 'DISPONIVEL',{},'f center'],
        [lang.distribuida, 'DISTRIBUIDA',{},'f center'],
        [lang.consumida, 'QT_CONSUMO',{},'f center'],
      ]} data={
        data.map((d,i)=>({...d,
          DS_OPRPRT: <span className="f center g1 destaque c2">
            {d.TP_OPRPRT==='E' ? <><icons.MdRedo size={16} /> {lang.carga}</> : <>{lang.descarga} <icons.MdRedo size={16} /></>}
          </span>,
          DISPONIVEL: <button disabled={parseInt(d.QT_DISPONIVEL)===0} onClick={()=>setOpenDistribuir(d)}>{d.QT_DISPONIVEL}</button>,
          DISTRIBUIDA: <button disabled={parseInt(d.QT_DISTRIBUIDA)===0} onClick={()=>setOpenDistribuida(d)}>{d.QT_DISTRIBUIDA}</button>,
        }))
      } />
  </c.Frame>

  {!!openDistribuida&&<DistribuidaModal cota={openDistribuida} onClose={()=>{setOpenDistribuida(null);get()}} onFinish={()=>get()} />}
  {!!openDistribuir&&<DistribuirModal cota={openDistribuir} onClose={()=>setOpenDistribuir(null)} onFinish={()=>get()} />}
  </>)
}


// ------------------------------ DISTRIBUIR MODAL ------------------------------
function DistribuirModal({cota, onClose, onFinish}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.distribuir_cotas},
        icons = App.icons

  const [distribuir, setDistribuir] = useState(parseInt(cota.QT_DISPONIVEL)),
        [origem, setOrigem] = useState(cota.NR_CNPJORI),
        [origemTrader, setOrigemTrader] = useState(cota.IN_TRD==='T'),
        [origens, setOrigens] = useState([]),
        [transportadoras, setTransportadoras] = useState([]),
        //[_transportadoras, set_Transportadoras] = useState([]),
        [trader, setTrader] = useState(false)

  function changeOrigem(e){
    setOrigem(e.value)
    setOrigemTrader(origens.find(({NR_CNPJORI})=>NR_CNPJORI===e.value).IN_TRD === 'T')
    setTrader(false)
  }

  function get(){
    App.api('transportadoras::consultaDistribuir', {id_cota: cota.ID_COTA}).then(r=>{
      setTransportadoras(r.results.lst_transportadora)
      setOrigem(r.results.lst_origem[0].NR_CNPJORI ?? cota.NR_CNPJORI)
      setOrigens(r.results.lst_origem)
    })
  }

  function submit(){
    let all = transportadoras.every(t=>t.IN_TRP==='T')
    return App.api('cotas::distribuirFob',{
      id_cota: cota.ID_COTA,
      qt_cota: distribuir,
      nr_cnpjori: origem,
      in_fob: trader,
      lst_trp: all === true ? transportadoras.map(t=>t.NR_CNPJTRP) 
        : transportadoras.filter(t=>t.IN_TRP==='T').map(t=>t.NR_CNPJTRP),
      in_alltrp: all,
    }).then(r=>{onFinish(); return true})
  }

  // useEffect(()=>{
  //   set_Transportadoras(transportadoras.map((t,i)=>({...t,
  //     SELECTION: <c.Switch checked={t.IN_TRP==='T'} 
  //       onChange={e=>{
  //         transportadoras[i].IN_TRP = e.target.checked?'T':'F'
  //         setTransportadoras([...transportadoras])
  //       }} />,
  //   })))
  // }, [transportadoras])

  useEffect(() => get(), [])

  return(
  <c.Modal title={lang.cota_distribuicao} onClose={onClose} largeWidth onFinish={submit}
    successMsg={lang.distribuida_com_sucesso} validate={trader || transportadoras.some(t=>t.IN_TRP==='T')}
  >
    <div className="f g2 w100">
      <c.Span value={cota.QT_COTA} label={lang.cota} style={{minWidth: 100}} />
      <c.Span value={cota.QT_DISTRIBUIDA} label={lang.distribuida} style={{minWidth: 100}} />
      <c.Span value={cota.QT_DISPONIVEL} label={lang.disponivel} style={{minWidth: 100}} />

      <c.Slider onChange={e=>setDistribuir(e.target.value)} value={distribuir} min={1} max={parseInt(cota.QT_DISPONIVEL)} className='f1' />

      <c.Select required label={lang.origem} value={origem} onChange={changeOrigem}
        options={origens.map(l=>({value: l.NR_CNPJORI, label: l.NM_ORI}))} className='f1'
      />

      {origemTrader&&<c.Switch label={'FOB'} checked={trader} onChange={e=>setTrader(e.target.checked)} />}
    </div>

    {!trader &&
      <c.Frame title={lang.transportadoras} className="w100">
        <c.Table selectColumns={false} isSub
          columns={[
            [<c.Switch title={lang.selecionar_todas} checked={transportadoras?.every(t => t.IN_TRP === 'T')}
              onChange={e => {
                setTransportadoras( transportadoras.map(t => ({...t, 
                  IN_TRP: e.target.checked ? 'T' : 'F'
                })))
              }}
            />, 'SELECTION'],
            [lang.cnpj, 'CNPJ'],
            [lang.nome, 'NM_TRP'],
            [lang.endereco, 'ENDERECO'],
            [lang.cep, 'CEP'],
            [lang.municipio_uf, 'MUNICIPIO_UF'],
          ]}
          data={transportadoras.map(transportadora => ({...transportadora,
            CNPJ: f.formatCnpj(transportadora.NR_CNPJTRP),
            ENDERECO: transportadora.NM_LGD + ', ' + transportadora.NR_LGD,
            MUNICIPIO_UF: transportadora.NM_LOC + '/' + transportadora.ID_UF,
            CEP: f.formatCEP(transportadora.CD_CEP),
            SELECTION: <c.Switch checked={transportadora.IN_TRP === 'T'} 
                          onChange={e => {
                            transportadora.IN_TRP = e.target.checked ? 'T' : 'F'
                            setTransportadoras([...transportadoras])
                          }} />,
          }))}
        />
      </c.Frame>}
  </c.Modal>
  )
}


// ------------------------------ DISTRIBUIDA MODAL ------------------------------
function DistribuidaModal({cota, onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.distribuir_cotas},
        icons = App.icons

  const [cotaDet, setCotaDet] = useState({}),
        [lstCota, setLstCota] = useState([])

  function get(){
    App.api('cotas::consultaDistribuida',{id_cota: cota.ID_COTA}).then(r=>setCotaDet(r.results))
  }

  useEffect(()=>setLstCota(
    cotaDet.lst_cota?.map(l=>{
      let lst_trp = l.LST_TRP.map(trp=>({...trp,
        SELECTION: <c.Switch checked={trp.IN_TRP==='T'} 
          onChange={e=>App.api('cotas::incluirTransportadora',{id_cota: l.ID_COTA, nr_cnpjtrp: trp.NR_CNPJTRP, in_insert: e.target.checked}).then(()=>get())} />,
        CNPJ: f.formatCnpj(trp.NR_CNPJTRP),
        ENDERECO: trp.NM_LGD + ', ' + trp.NR_LGD,
        MUNICIPIO_UF: trp.NM_LOC + '/' + trp.ID_UF,
        CEP: f.formatCEP(trp.CD_CEP)
      }))

      return {...l,
        RECUPERAR: l.TP_COTA==='FOB'&&l.QT_FOB!==l.QT_CONSUMO&&l.QT_FOB!=='0'&&(
          <button onClick={()=>App.api('cotas::recuperar',{id_cota: l.ID_COTA}).then(r=>App.toast.success(lang.foram_recuperada.replace('%d', r.results.qt_reduzida)))}>
            {lang.recuperar_nao_consumida.replace('%d', l.QT_DISPONIVEL)}
          </button>
        ),
        DISTRIBUIDA: 
          <c.Slider min={parseInt(l.QT_MINIMO)} max={parseInt(l.QT_COTA??0)+parseInt(cotaDet.qt_disponivel)} value={parseInt(l.QT_COTA??0)}
            onChange={e=>App.api('cotas::alterarDistribuida',{id_cota: l.ID_COTA, qt_cota: e.target.value}).then(r=>get())} />,
        LST_TRP: lst_trp,
        sub: <c.Table isSub={true} data={l.TP_COTA !== "FOB" ? lst_trp : []} columns={[
            [lang.selection, 'SELECTION'],
            [lang.cnpj, 'CNPJ'],
            [lang.nome, 'NM_TRP'],
            [lang.endereco, 'ENDERECO'],
            [lang.cep, 'CEP'],
            [lang.municipio_uf, 'MUNICIPIO_UF'],
          ]} />
      }
    })
  ),[cotaDet])

  useEffect(()=>get(),[])

  return(
  <c.Modal title={lang.cota_distribuida} onClose={onClose} largeWidth cancelText={lang.fechar}>
    <div className="f g1 f-between">
      <c.Span value={cota.DT_COTA} label={lang.data} />
      <c.Span value={cota.DS_TRN} label={lang.turno} />
      <c.Span value={cota.DS_OPRPRT} label={lang.operacao} />
    </div>
    <div className="f g1 f-between">
      <c.Span value={cota.NM_PRO} label={lang.produto} />
      <c.Span value={cotaDet.qt_cota} label={lang.cota} />
      <c.Span value={cotaDet.qt_consumo} label={lang.distribuida} />
      <c.Span value={cotaDet.qt_disponivel} label={lang.saldo_distribuir} />
      <c.Span value={cota.QT_CONSUMO} label={lang.consumida_agendada} />
    </div>

    <c.Table data={lstCota} selectColumns={false} columns={[
      [lang.tipo_cota, 'TP_COTA'],
      [lang.origem, 'NM_PESORI'],
      [lang.distribuida, 'DISTRIBUIDA', {}, 'f g1 center-v'],
      [lang.fob_redist, 'QT_FOB', {}, 'f g1 center'],
      [lang.consumida_agendada, 'QT_CONSUMO', {}, 'f g1 center'],
      ['', 'RECUPERAR']
    ]} hasSub='sub' />
  </c.Modal>
  )
}