import { useState, createContext, useEffect } from "react"
import pj from '../package.json'

import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/pt-br'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import * as iconsBs from 'react-icons/bs'
import * as iconsFa from 'react-icons/fa'
import * as iconsMd from 'react-icons/md'
import * as iconsGi from 'react-icons/gi'
import * as iconsVelog from './a/iconsVelog'

import './s/index.sass'

import * as p from './p/'
import * as c from './c/'
import * as f from './f'
import langs from './langs.json'

import Logo from './a/logo.svg'
import { ReactSVG } from "react-svg"

export const AppContext = createContext()

function Application({env=3}){
  const [confirm, setConfirm] = useState({}),
        [language, setLanguage] = useState({})

  const protocol = env===4?'http:':window.location.protocol,
        host = env===4?'localhost':window.location.hostname,
        pathname = window.location.pathname.split('/')[1],
        baseURL = protocol + '//' + host + '/' + (pathname ? pathname + '/' : '')

  const [app, setApp] = useState({
      env: env, // 0 = dev, 1 = prod
      loading: null,
      LoadingComponent: Loading,
      cliente: false,
      notifications: {itens:[], noreads: 0},
      pj, protocol, host, baseURL,
      confirm: (text='', onConfirm=()=>{}, title='')=>setConfirm({show: true, text, title, onConfirm}),
      prefs: {lang:0},
      components: {},
      diretivas: {},
    })

  function Loading({msg}){ 
    return  <div className="vertti-logo-loader f center g2 f-column center-margin">
              {!!msg && <b>{msg}</b>}
              <ReactSVG src={Logo} />
            </div>
  }

  function api( action, json = {}, quiet = false ){
    const base = app.baseURL + 'api/'
  
    return fetch( base, {
      method: 'POST', 
      headers: {'content-type': 'text/plain'}, 
      body: JSON.stringify({action: action, ...json,
        token: sessionStorage.getItem('token')??'e30='
      }) 
    })
    .then(r => r.json()).then(r => {
      if( !r.status && !r.command ){
        toast.error( lang?.errors[r.error.message] ?? r.error.message ) 
      }
      if( r.command === 'logout' ) logout()

      return r 
    })
  }

  const lang = Object.fromEntries( Object.entries(langs).map( ([tela, words]) => [ 
    tela, Object.fromEntries( Object.entries(words).map(([key, val]) => [
      key, Array.isArray(val) ? val[app.prefs?.lang??0] : val // Se não foi definida a linguagem
    ]) ) 
  ] ) )

  function logout(){
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('diretivas')
    setApp({...app, user: {}, prefs: null, components: {}, diretivas: {},})
    window.location.reload(true)
    toast.error(lang.errors.usuario_desconectado)
  }

  document.documentElement.lang = app.prefs?.lang!==null ? lang?.global?.document_langs[app.prefs?.lang] : navigator.language
  if(app.prefs?.dark) document.body.classList.add('dark'); else document.body.classList.remove('dark')

  function setPrefs(prefs, save=true){
    const newPrefs = {...app.prefs, ...prefs}
    setApp({...app, prefs: newPrefs})
    

    if(save) {
      api('users::savePrefs', {prefs: newPrefs})
      setLanguage({newPrefs})
    }
  }

  useEffect(() => {
    !!language.newPrefs && toast.success(lang.global.prefs_saved_success)
  }, [language])


  useEffect(() => {
    (async () => {
      const [cliente] = await Promise.all([
        api('users::getCliente')
      ])
  
      setApp({ ...app, 
        cliente: (!cliente.results?.id || cliente.error?.message === 'invalid_client') 
                    ? -1 : cliente.results,
        user: JSON.parse(atob(sessionStorage.getItem('token')??'e30=')),
      })  
    })()
  }, [])


  const appProvider = { ...app, setApp, prefs: app.prefs??null,
    logout, lang, api, moment, toast, f, setPrefs, 
    icons: {...iconsBs, ...iconsFa, ...iconsMd, ...iconsGi, ...iconsVelog}
  }

  return (<>
    <AppContext.Provider value={appProvider}>
      {(env!==3 && env!==0)&&
        <span className='version-env-big-stamp destaque danger'>
          {['Dev - Local back','Testing','Homologation','Production','Dev - Remote back'][env]}
        </span>
      }
      {!app.cliente
        ? <div id='lockerScreen' style={{display:'flex'}}>{Loading({})}</div>
          //<c.LockerScreen />
        : (app.cliente === -1 
          ? lang.errors.invalid_client
          : ( !app.user.id_usr
              ? <p.Login /> 
              : <p.Layout /> 
            ) 
        )
      }

      {confirm.show && <ConfirmBox confirm={confirm} onClose={()=>setConfirm({})} />}

      <ToastContainer position="top-center" theme={app.prefs?.dark?'dark':'light'} />
    </AppContext.Provider>
  </>)
}

function ConfirmBox({confirm, onClose}){
  const [loading, setLoading] = useState(false)

  function submit(){
    setLoading(true)
    const f = confirm.onConfirm()

    if( f instanceof Promise ){
      return f.then(r => {
        setLoading(false)
        return r?.results ?? r
      })
    }else{
      return f
    }
  }

  return(
    <c.Modal title={confirm.title} onFinish={submit} loading={loading}
      onClose={onClose} cancelText='Não' okText="Sim" successMsg=''
    >{confirm.text}</c.Modal>
  )
}

export function log( data='' ){console.log(data)}

export default Application