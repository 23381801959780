import React, { useState, useContext, useRef, useEffect } from 'react'
import { AppContext, log } from '../../App'

import * as c from '../'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'


export default function DaterangePicker({label, startDate, endDate, ranges, onChange=()=>{},filter=false}){
  const App = useContext(AppContext),
        lang = {...App.lang.global},
        icons = App.icons

  const [_startDate, setStartDate] = useState(startDate),
        [_endDate, setEndDate] = useState(endDate),
        [open, setOpen] = useState(false)

  const defaultRanges = [
    [lang.hoje, App.moment(), App.moment()],
    [lang.ontem, App.moment().subtract(1, "days"), App.moment().subtract(1, "days")],
    [lang.sete_dias, App.moment().subtract(6, "days"), App.moment()],
    [lang.trinta_dias, App.moment().subtract(29, "days"), App.moment()],
    [lang.este_mes, App.moment().startOf("month"), App.moment().endOf("month")],
    [lang.mes_passado, App.moment().subtract(1, "month").startOf("month"), App.moment().subtract(1, "month").endOf("month")],
  ]

  const popRef = useRef(null)
  useEffect(() => {
    function handleClickOutside(e) {
      if (popRef.current && !popRef.current.contains(e.target)) setOpen(false)
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [popRef])

  registerLocale('pt-BR', ptBR)
  registerLocale('en-US', en)
  registerLocale('es', es)
  setDefaultLocale(lang.linguagem)

  const handleDatePreset = (preset) => {
    const start = preset[1].toDate(),
          end = preset[2].toDate()
    setStartDate(start)
    setEndDate(end)
    onChange(start, end)
    setOpen(false)
  }

  function change(dates){
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    onChange(start, end)
    if( !!start && !!end ) setOpen(false)
  }

  return <div className={['text-group','icon','daterange-picker'].join(' ')}>
    <input onClick={()=>!open?setOpen(true):!!_startDate&&!!_endDate&&setOpen(false)} 
      type='text' value={
        (!!_startDate?App.moment(_startDate).format(lang.formatoData):'') + ' - ' 
        + (!!_endDate?App.moment(_endDate).format(lang.formatoData):'')
      } onChange={()=>{}} />

    <c.IconButton onClick={()=>setOpen(!open)}>
      <icons.MdCalendarToday />
    </c.IconButton>

    {label&&<span className="label">{label}</span>}

    {open &&
      <div className='daterange-picker-selector' ref={popRef}>
        <div className='daterange-picker-presents'>
          {(ranges||defaultRanges).map((r,i)=>
            <button key={i} onClick={()=>handleDatePreset(r)}>{r[0]}</button>
          )}
        </div>

        <DatePicker selectsRange inline monthsShown={2} locale={lang.linguagem}
          selected={_startDate} onChange={change}
          startDate={_startDate} endDate={_endDate} 
          filterDate={filter}
        />
      </div>
    }
  </div>
}
