import { useState, useEffect, useContext, Fragment } from "react"
import { AppContext, log } from '../App'
import * as c from '../c'
import * as p from './'
import * as f from '../f'

export default function FreteAuditoria({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.menu, ...App.lang.transportador},
        icons = App.icons

  const [data, setData] = useState([]),
        [loading, setLoading] = useState(null),
        [filtro, setFiltro] = useState({
          startDate: App.moment().subtract(30, 'days').toDate(),
          endDate: App.moment().toDate(),
        }),
        [openCTeMM, setOpenCTeMM] = useState(false),
        [listas, setListas] = useState({}),
        [filtroCard, setFiltroCard] = useState(''),
        [aprovarReprovarDocumeto, setAprovarReprovarDocumeto] = useState(null)


  async function getListas(){ //setData([])
    setListas( (await App.api('portarias::getListasFiltro',{
      startDate: App.moment(filtro.startDate).format('YYYY-MM-DD'), 
      endDate: App.moment(filtro.endDate).format('YYYY-MM-DD'),
    })).results )
  }


  async function get(){
    setLoading(true)

    const portarias = (await App.api('portarias::consultaAll', {...filtro,
                        startDate: App.moment(filtro.startDate).format('YYYY-MM-DD'), 
                        endDate: App.moment(filtro.endDate).format('YYYY-MM-DD'),
                      })).results
    const ids = portarias.map(i=>i.ID_UOCC)
    const extras = (await App.api('portarias::getExtras', {ID_UOCCs: ids})).results

    setData( portarias.map(p => {
      let docs = extras.dcos[p.ID_UOCC]
      let nfes = docs.filter(d => parseInt(d.TP_DF) === 55)
      let ctes = docs.filter(d => parseInt(d.TP_DF) === 57 && d.CD_IDC === null)
      let ctemms = docs.filter(d=>parseInt(d.TP_DF) === 57 && d.CD_IDC === '1')
      let nfe_status = !nfes.length ? 0 : (
        nfes.some(d => d.validacoes?.filter(v => parseInt(v.CD_STS) === 1).length) ? 2
        : (nfes.some(d => d.validacoes?.filter(v => parseInt(v.CD_STS) === -3).length) ? 3 : 1)
      )
      let cte_status = !ctes.length ? 0 : (
        ctes.some(d => d.validacoes?.filter(v => parseInt(v.CD_STS) === 1).length) ? 2
        : (ctes.some(d => d.validacoes?.filter(v => parseInt(v.CD_STS) === -3).length) ? 3 : 1)
      )
      let ctemm_status = !ctemms.length ? 0 : (
        ctemms.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === 1).length) ? 2
        : (ctemms.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === -3).length) ? 3 : 1)
      )
      let dfe_status_labels = ['Não cadastrada','Aprovada','Necessita verificação','Recusada']

      return {...p, 
        dco_aux: extras.dcos_aux[p.ID_UOCC].filter(d=>parseInt(d['TP_DCO'])===1), 
        dcos: docs,
        steps: extras.steps[p.ID_UOCC],
        docs_rf: extras.dcos_rf[p.ID_UOCC],
        nfe_status: nfe_status,
        cte_status: cte_status,
        ctemm_status: ctemm_status,
        nfe_status_label: 'NFe: ' + dfe_status_labels[nfe_status],
        cte_status_label: 'CTe: ' + dfe_status_labels[cte_status],
        ctemm_status_label: dfe_status_labels[ctemm_status],
        ordem_status: p.LST_ORDEM ? 1 : 0,
        ordem_status_label: p.LST_ORDEM ? 'Ordem: Cadastrada' : 'Ordem: Não cadastrada',
        rf_status: p.LST_RF ? 1 : 0,
        rf_status_label: p.LST_RF ? 'Resumo de frete: Cadastrada' : 'Resumo de frete: Não cadastrada',

        ticket_pesagem_status: p.LST_TICKET_PESAGEM ? 1 : 0,
        ticket_pesagem_status_label: p.LST_TICKET_PESAGEM ? 'Ticket de pesagem: Cadastrada' : 'Ticket de pesagem: Não cadastrada',
        docs_ticket_pesagem: extras.dcos_aux[p.ID_UOCC].filter(d=>parseInt(d['TP_DCO'])===2),
        ACTION: <c.DropdownMenu itens={[
          {title: lang.anexar_ctemm, icon: <icons.MdAttachFile />, disabled: false, action: () => setOpenCTeMM(p)},
        ]}><c.IconButton icon={icons.BsThreeDotsVertical} size={24} /></c.DropdownMenu>,
      }
    }) )

    setFiltroCard('')

    setLoading(false)
    return true
  }


  const CardsDFe = ({title, doc, field}) => {
    return <>
      <div className="f g1 f1 card-group">
        <div className="card-group-title">{title}</div>

        <div className={['card-item p1', !!doc.vazio?'info':'', (filtroCard===field+'|0')?'active':''].join(' ')} 
          onClick={() => setFiltroCard( filtroCard !== field+'|0' ? field+'|0' : '' )}>
          <div className="f g1 f-column">
            <div className="f g1 center-v">
              <span className="card-value">{doc.vazio}</span> 
              <span className="card-percent">({parseInt( (doc.vazio??0) / (doc.total?doc.total:1) * 100 )}%)</span>
            </div>
            <span className="card-title">Não cadastrada</span>
          </div>
        </div>

        <div className={['card-item p1', !!doc.aprovadas?'success':'', (filtroCard===field+'|1')?'active':''].join(' ')} 
          onClick={() => setFiltroCard( filtroCard !== field+'|1' ? field+'|1' : '' )}>
          <div className="f g1 f-column">
            <div className="f g1 center-v">
              <span className="card-value">{doc.aprovadas}</span>
              <span className="card-percent">({parseInt( (doc.aprovadas??0) / (doc.total?doc.total:1) * 100 )}%)</span>
            </div>
            <span className="card-title">Aprovada</span>
          </div>
        </div>

        <div className={['card-item p1', !!doc.verificacao?'warning':'', (filtroCard===field+'|2')?'active':''].join(' ')} 
          onClick={() => setFiltroCard( filtroCard !== field+'|2' ? field+'|2' : '' )}>
          <div className="f g1 f-column">
            <div className="f g1 center-v">
              <span className="card-value">{doc.verificacao}</span>
              <span className="card-percent">({parseInt( (doc.verificacao??0) / (doc.total?doc.total:1) * 100 )}%)</span>
            </div>
            <span className="card-title">Necessita verificação</span>
          </div>
        </div>

        <div className={['card-item p1', !!doc.recusadas?'danger':'', (filtroCard===field+'|3')?'active':''].join(' ')} 
          onClick={() => setFiltroCard( filtroCard !== field+'|3' ? field+'|3' : '' )}>
          <div className="f g1 f-column">
            <div className="f g1 center-v">
              <span className="card-value">{doc.recusadas}</span>
              <span className="card-percent">({parseInt( (doc.recusadas??0) / (doc.total?doc.total:1) * 100 )}%)</span>
            </div>
            <span className="card-title">Recusada</span>
          </div>
        </div>

      </div>
    </>
  }


  const CardsOutros = ({title, doc, field}) => { 
    return <>
      <div className="f g1 f1 card-group">
        <div className="card-group-title">{title}</div>

        <div className={['card-item p1', !!doc.vazio?'info':'', filtroCard===field+'|0'?'active':''].join(' ')} 
          onClick={() => setFiltroCard( filtroCard !== field+'|0' ? field+'|0' : '' )} >
          <div className="f g1 f-column">
            <div className="f g1 center-v">
              <span className="card-value">{doc.vazio}</span>
              <span className="card-percent">({parseInt( (doc.vazio??0) / (doc.total?doc.total:1) * 100 )}%)</span>
            </div>
            <span className="card-title">Não cadastrada</span>
          </div>
        </div>

        <div className={['card-item p1', !!doc.cadastrada?'success':'', filtroCard===field+'|1'?'active':''].join(' ')} 
          onClick={() => setFiltroCard( filtroCard !== field+'|1' ? field+'|1' : '' )} >
          <div className="f g1 f-column">
            <div className="f g1 center-v">
              <span className="card-value">{doc.cadastrada}</span>
              <span className="card-percent">({parseInt( (doc.cadastrada??0) / (doc.total?doc.total:1) * 100 )}%)</span>
            </div>
            <span className="card-title">Cadastrada</span>
          </div>
        </div>

      </div>
    </>
  }


  useEffect(() => !!filtro.startDate && !!filtro.endDate && getListas(), [filtro.startDate, filtro.endDate])

  useEffect(() => { getListas()
    setReloader(() => () => {
      setData([])
      setFiltroCard('')
      getListas()
    })
  }, [])


  const cards = {
    nfe: {
      vazio:       data.filter(l=>l.nfe_status===0).length,
      aprovadas:   data.filter(l=>l.nfe_status===1).length,
      verificacao: data.filter(l=>l.nfe_status===2).length,
      recusadas:   data.filter(l=>l.nfe_status===3).length,
      total:       data.length,
    },
    cte: {
      vazio:       data.filter(l=>l.cte_status===0).length,
      aprovadas:   data.filter(l=>l.cte_status===1).length,
      verificacao: data.filter(l=>l.cte_status===2).length,
      recusadas:   data.filter(l=>l.cte_status===3).length,
      total:       data.length,
    },
    ordem: {
      vazio:      data.filter(l=>l.ordem_status===0).length,
      cadastrada: data.filter(l=>l.ordem_status===1).length,
      total:      data.length,
    },
    rf: {
      vazio:      data.filter(l=>l.rf_status===0).length,
      cadastrada: data.filter(l=>l.rf_status===1).length,
      total:      data.length,
    },
  }

  return(<>
    <c.Frame title={lang.frete_auditoria} loading={loading}>
      <div className="f f-column g1">
        <div className="f f-wrap g1 center-v">
          <c.DaterangePicker label={lang.data} startDate={filtro.startDate} endDate={filtro.endDate} 
            onChange={(startDate, endDate) => setFiltro({startDate, endDate})} className="f1" />
          <c.Select label={lang.unidade} options={listas.unidades} value={filtro.unidades} className="f1"
            onChange={v => setFiltro({...filtro, unidades: v.map(v=>v.value)})} multi clearable />
          <c.Select label={lang.cliente} options={listas.clientes} value={filtro.clientes} className="f1"
            onChange={v => setFiltro({...filtro, clientes: v.map(v=>v.value)})} multi clearable />
          <c.Select label={lang.origem} options={listas.origens} value={filtro.origens} className="f1"
            onChange={v => setFiltro({...filtro, origens: v.map(v=>v.value)})} multi clearable />
          <c.Select label={lang.produto} options={listas.produtos} value={filtro.produtos} className="f1"
            onChange={v => setFiltro({...filtro, produtos: v.map(v=>v.value)})} multi clearable />
          <button onClick={() => get()} disabled={!!loading} className='f1'>{icons.MdSearch({size: 24})} {lang.pesquisar}</button>
        </div>

        {!!data.length && <>
          <div className="f g2 f-wrap">
            <CardsDFe title='NFe' doc={cards.nfe} field={'nfe_status'} />
            <CardsDFe title='CTe' doc={cards.cte} field={'cte_status'} />

            <CardsOutros title={"Ordem"} doc={cards.ordem} field={'ordem_status'} />
            <CardsOutros title={"Resumo de frete"} doc={cards.rf} field={'rf_status'} />
          </div>

          <c.Table hasSub={'SUB'}
            columns={[
              ['', 'ACTION', {}, 'force-fit'],
              ['','_VALIDATION_ICON',{},'force-fit'],
              ['Portaria','ID_UOCC'],
              ['Transportadora','NM_TRP'],
              ['Veículo','ID_VCL'],
              ['Contrato','ID_CN'],
              ['Cliente','NM_PRC'],
              ['Origem','NM_ORI'],
              ['Destino','NM_DST'],
              ['Operação','_OPRPRT',{},'center-h','DS_OPRPRT'],
              ['Motorista','NM_MTR'],
              ['Situação','DS_STS'],
              ['Previsão','DT_PRV'],
              ['Turno','DS_TRN'],
              ['Previsão','DT_PRV'],
              [<><icons.MdCloudDownload />{lang.downloads}</>, 'downloads', {}, 'f g1'],
            ]}
            data={
              data.filter(portaria =>
                !filtroCard || portaria[filtroCard.split('|')[0]] === +filtroCard.split('|')[1]
              ).map(portaria => ({...portaria,
                downloads: <c.PortariaDownloadButtons portaria={portaria} />,
                _VALIDATION_ICON: portaria.dcos?.some(dco => dco.validacoes?.some(v => v.CD_STS !== '-1'))
                                    ? <icons.MdReportProblem size={16} style={{color: 'var(--warning)'}} />
                                    : <icons.BsCheck2Circle size={16} style={{color: 'var(--success)'}} />,
                ID_CN: portaria.ID_CN??'--', 
                _OPRPRT: portaria.TP_OPRPRT==='E' 
                          ? <icons.CargaIcon title={lang.carga} size={24} /> 
                          : <icons.DescargaIcon title={lang.descarga} size={24} />,
                SUB:  <div className="f f-column g1">
                        <PortariaSteps steps={[
                          // [portaria.steps.AGENDADO, 'Agendado', icons.FaCalendarAlt], // Não apagar
                          [portaria.steps.TRANSITO, 'Trânsito', icons.FaRoad],
                          // [portaria.steps.RECEPCIONADO, 'Recepcionado', icons.FaMapMarkerAlt], // Não apagar
                          // [portaria.steps.CLASSIFICACAO, 'Em classificação', icons.FaClipboardList], // Não apagar
                          [portaria.steps.FILA, 'Em fila', icons.FaBars],
                          [portaria.steps.OPERACAO, 'Em operação', icons.FaBalanceScale],
                          [portaria.steps.CONCLUIDO, 'Finalizado', icons.FaCheck],
                        ]} />

                        {!!portaria.dcos.length &&
                          <c.Table isSub hasSub={'_SUB'} // dcos
                            columns={[
                              ['','_VALIDATION_ICON',{},'force-fit'],
                              ['Data','DT_DCO'],
                              ['Tipo','_TP_DF'],
                              ['Número/Série','_NUM_NOTA'],
                              ['Chave','NR_NFE'],
                              ['Emitente','NM_PES'],
                              ['Quantidade','_QT_ORG'],
                              ['Valor','_VL_ORG'],
                              ['','_ACTION',{},'force-fit'],
                            ]} 
                            data={portaria.dcos.map(dco => {
                              const valid = dco.validacoes?.every(v => v.CD_STS === '-1'),
                                    block = dco.validacoes?.some(v => v.CD_STS === '-3'), 
                                    notif = !valid && !block && dco.validacoes?.some(v => v.CD_STS === '1')
                          
                              return {...dco,
                                _VALIDATION_ICON: 
                                  valid ? <icons.BsCheck2Circle size={16} style={{color: 'var(--success)'}} />
                                  : block ? <icons.MdCancel size={16} style={{color: 'var(--danger)'}} />
                                  : notif ? <icons.MdReportProblem size={16} style={{color: 'var(--warning)'}} /> : '',
                                _TP_DF: dco.TP_DF==='55'?'NFe':'CTe',
                                _NUM_NOTA: dco.NR_DCO+'/'+dco.CD_DCO,
                                _QT_ORG: parseFloat(dco.QT_ORG??0).toLocaleString() + 'Kg',
                                _VL_ORG: 'R$' + parseFloat(dco.VL_ORG??0).toLocaleString(),
                                _ACTION:  <div className="f g1 center-v">
                                            <c.IconButton icon={icons.MdDelete} title="Excluir documento" size={30}
                                              onClick={() => App.confirm('Confirma a exclusão do documento '+dco.NR_NFE+'?', 
                                                              () => App.api('portarias::deleteDCO', {
                                                                      tp: 'dco', id: dco.ID_UOCC
                                                                    }).then(r => {get(); return r.status}))} />
                                            {!!notif && <>
                                              <c.IconButton icon={icons.MdThumbUp} title="Aprovar documento"
                                                style={{color: 'var(--success)'}} size={30}
                                                onClick={() => setAprovarReprovarDocumeto({portaria,
                                                  situacao: -1,
                                                  documento: dco,                                                  
                                                })} />
                                              <c.IconButton icon={icons.MdThumbDown} title="Recusar documento"
                                                style={{color: 'var(--danger)'}} size={30}
                                                onClick={() => setAprovarReprovarDocumeto({portaria,
                                                  situacao: -3,
                                                  documento: dco,                                                  
                                                })} />
                                            </>}
                                          </div>,
                                _SUB: !!dco.validacoes && 
                                        <c.Table isSub
                                          columns={[
                                            ['','_VALIDATION_ICON',{},'force-fit'],
                                            ['Regra','DS_VLD'],
                                            ['No agendamento','VL_PRT'],
                                            ['No XML','VL_XML'],
                                          ]}
                                          data={dco.validacoes.map(v => ({...v,
                                            _VALIDATION_ICON: 
                                              v.CD_STS === '-1' ? <icons.BsCheck2Circle size={16} style={{color: 'var(--success)'}} />
                                              : v.CD_STS === '-3' ? <icons.MdCancel size={16} style={{color: 'var(--danger)'}} />
                                              : v.CD_STS === '1' ? <icons.MdReportProblem size={16} style={{color: 'var(--warning)'}} /> : '',
                                          }))}
                                        />
                              }
                            })}
                          />
                        }

                        {!!portaria.dco_aux.length &&
                          <c.Table isSub // dcos_aux
                            columns={[
                              ['Data','DT_DCT'],
                              ['Nome','NM_DCO'],
                              ['','_ACTION',{},'force-fit'],
                            ]} 
                            data={portaria.dco_aux.map(aux => ({...aux,
                              _ACTION:  <c.IconButton icon={icons.MdDelete} title="Excluir documento" size={30}
                                          onClick={() => App.confirm('Confirma a exclusão?', 
                                                          () => App.api('portarias::deleteDCO', {
                                                                  tp: 'aux', id: aux.ID_UOCC
                                                                }).then(r => {get(); return r.status}))} />
                            }))}
                          />
                        }

                        {!!portaria.docs_rf.length &&
                          <c.Table isSub data={portaria.docs_rf} // dcos_rf
                            columns={[['Data','DT_DCT'],['Nome','NM_DCO'],]} />
                        }
                      </div>
              }))
            }
          />
        </>}
      </div>
    </c.Frame>

    {aprovarReprovarDocumeto 
      && <AprovarReprovarDocumetoModal onClose={()=>{setAprovarReprovarDocumeto(null); get();}}
            documento={aprovarReprovarDocumeto.documento} 
            situacao={aprovarReprovarDocumeto.situacao}
            portaria={aprovarReprovarDocumeto.portaria} 
          />}

    {!!openCTeMM && <p.DfeModal mod={'57m'} onClose={()=>setOpenCTeMM(false)} onFinish={()=>get()} portaria={openCTeMM} tela="agendado" />}
  </>)
}


function AprovarReprovarDocumetoModal({situacao, documento, portaria, onClose}){
  const App = useContext(AppContext)

  const [motivo, setMotivo] = useState('')

  async function submit(){
    await App.api('portarias::autorizarDCO', {motivo,
      ids: documento.validacoes.filter(v => v.CD_STS === '1').map(v => v.ID_UOCC),
      situacao: situacao,
    })

    await App.api('notifications::add',{
      cnpj: portaria.NR_CNPJTRP,
      msg: 'O documento fiscal "' + documento.NR_NFE 
              + '", vinculado ao agendamente do ticket "' + portaria.ID_UOCC
              +'" e placa "' + portaria.ID_VCL 
              + '" foi ' + (situacao === -1 ? 'APROVADO': 'RECUSADO')
              + (situacao===-3 && 'pelo motivo "' + motivo + '"') + '.',
    })

    return true
  }

  return(
    <c.Modal title={(situacao === -1 ? 'Aprovar' : 'Reprovar') + " documento"} onClose={onClose}
      validate={motivo.length >= 10} onFinish={submit}
      okText={situacao === -1 ? 'Aprovar' : 'Reprovar'}
    >
      <c.Input label={"Motivo"} value={motivo} onChange={e=>setMotivo(e.target.value)} error={motivo.length < 10} 
        info={'Digite ao menos 10 letras.'} />
    </c.Modal>
  )
}


function PortariaSteps({steps}){
  return(
    <div className="f g3 p3">
      {steps.map((step, id) => <Fragment key={id}>
        {id > 0 && <hr style={{height: 0, flex: 1, marginTop: 20}} />}
        <PortariaStep stepName={step[1]} stepIcon={step[2]} 
          dateStep={step[0]} datePrevStep={!!steps[id-1] ? steps[id-1]?.[0] : '_'} dateNextStep={steps[id+1]?.[0]} 
          dateLastStep={steps[steps.length - 1][0]}
        />
      </Fragment>)}
    </div>
  )
}


function PortariaStep({dateStep, datePrevStep, dateNextStep, dateLastStep, stepName, stepIcon}){
  return(
    <div className="f f-column g1 center-v">
      <div className={['destaque p2 circle f center', 
          !!dateStep || !!dateNextStep ? (!!dateNextStep || !!dateLastStep ? 'success' : 'warning') : 'normal'
        ].join(' ')} style={{width: 40, height: 40}}>
        {stepIcon({size:16})}
      </div>
      <b style={{fontSize: 14}}>{stepName}</b>
      <span style={{fontSize: 10, color: 'var(--colorGray)'}} className="nowrap">{dateStep}</span>
    </div>
  )
}