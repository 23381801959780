import { useContext, useState, useEffect } from 'react'
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as f from '../../f'
import * as p from '../../p'

export default function TransportadorAgendar({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador, ...App.lang.contratos},
        icons = App.icons

  const [toggleCardsList, setToggleCardsList] = useState(false),
        [cotas, setCotas] = useState([]),
        [contratos, setContratos] = useState([]),
        [cotasFracionadas, setCotasFracionadas] = useState([]),
        [semContrato, setSemContratos] = useState([]),
        [agendarCheioModal, setAgendarCheioModal] = useState(null),
        [agendarFracionadoModal, SetAgendarFracionadoModal] = useState(null),
        [loading, setLoading] = useState(false),
        [loadingFracionados, setLoadingFracionados] = useState(false),
        [showFilters, setShowFilters] = useState(false),
        [filter, setFilter] = useState({}),
        [viewFracionadaDetails, setViewFracionadaDetails] = useState(null)

  async function get(){
    setLoading(true)
    getFracionadas()

    const r = await Promise.all([
      App.api('cotas::getByCnpjTrp'),
      App.api('cotas::getCards'),
    ])

    const _contratos = r[1].results.com_contrato??[]

    setCotas( 
      (r[0].results??[]).map( cota => ({...cota,
        IN_VALIDO_LOTE: !(() => {
            const contrato = _contratos.find(_contrato => _contrato.ID_CN === cota.ID_CN)
            return !!contrato && (contrato.ID_NATOP === (App.diretivas.NatopVelogContratoLote??'3'))
              && contrato.CNTRP_STS === '1'
          })(),
      }) )
    )

    setContratos( _contratos )
    setSemContratos( r[1].results.sem_contrato??[] )

    setToggleCardsList( !(r[1].results.com_contrato??[]).length )

    setLoading(false)
  }

  

  function getFracionadas(){
    setLoadingFracionados(true)
    App.api('ordemCarregamento::getAll',{
      future: true,
      free: true
    }).then(r=>{
      setCotasFracionadas( r.results )
      setLoadingFracionados(false)
    })
  }

  async function agendar(cota){
    let se_agendar = true

    if( App.user.in_sgr === 'T' ){
      const r = await App.api('apolices::validar',{id_cota: cota.ID_COTA})
      se_agendar = r.status
      if( !se_agendar ) {
        App.toast.warning(lang.agendar_nao_disponivel.replace('%d', r.results?.ds_tipo))
      }
    }

    if( se_agendar ){
      let checkLimitByUnidade = (await App.api('portarias::checkLimitByUnidade', {ID_COTA: cota.ID_COTA})).results
      if( !checkLimitByUnidade ){
        App.toast.error("Limite de agendamentos para a unidade excedido!")
      }else{
        setAgendarCheioModal(cota)
      }
    }
  }

  useEffect(()=>{ 
    get()
    setReloader(()=>get)
  }, [])

  return(<>
    <c.Tabs>
      <c.TabList>
        <c.Tab>Carga cheia <span className='destaque info'>{(cotas??[]).length}</span></c.Tab>
        <c.Tab>Carga fracionada <span className='destaque info'>{(cotasFracionadas??[]).length}</span></c.Tab>
      </c.TabList>

      {/* Cota / Carga cheia */}
      <c.TabPanel>
        <c.Frame title={lang.agendamento + ' Carga cheia'} loading={loading} flex className='w100'
          actions={{
            filter: toggleCardsList ? {onClick: () => setShowFilters(!showFilters), value: showFilters} : null,
            toggleCardsList: {onClick: () => setToggleCardsList(!toggleCardsList), value: toggleCardsList},
            CSVDownload: !loading && !!cotas.length && { 
              filename: 'cotas.csv',
              columns: [
                          lang.cota, lang.operacao, lang.data, lang.cn, 
                          lang.cliente, lang.origem, lang.destino, lang.produto,
                          lang.turno, lang.agendar_qt, lang.agendar_disp, lang.uso,
                      ], 
              data: cotas.map(c=>[
                      c.ID_COTA, c.DS_OPRPRT, c.DT_COTA, c.ID_CN,
                      c.NM_PESPRC.trim(), c.NM_PESORI, c.NM_PESDST, c.NM_PRO, 
                      c.DS_TRN, c.QT_COTA, c.QT_DISPONIVEL, c.QT_CONSUMO
                    ])??[],
            }
          }}
        >
          {toggleCardsList ? 
            <c.Table showFilters={showFilters} filter={filter} 
              columns={[
                [lang.cota, 'TP_COTA_ICON', {}, 'f center force-fit'],
                [lang.operacao, 'DS_OPRPRT_ICON', {}, 'f center force-fit'],
                [lang.data, 'DT_COTA_TB'],
                // [lang.unidade, 'NM_PESUND'],
                [lang.cn, '_ID_CN'],
                [lang.cliente, 'NM_PESPRC'],
                [lang.origem, 'NM_PESORI'],
                [lang.destino, 'NM_PESDST'],
                [lang.produto, 'NM_PRO'],
                [lang.turno, 'DS_TRN'],
                [lang.agendar_qt, 'QT_COTA'],
                [lang.agendar_disp, '_QT_DISPONIVEL'],
                [lang.uso, 'QT_CONSUMO'],
                [lang.acao, 'ACTION'],
              ]} 
              data={
                cotas.map(c => ({...c,
                  QT_DISPONIVEL:  c.QT_DISPONIVEL < (App.diretivas.VolumeMinimoAgendar?? 0) ? 0 : c.QT_DISPONIVEL,
                  IN_VALIDO_LOTE: c.QT_DISPONIVEL < (App.diretivas.VolumeMinimoAgendar ?? 0) ? false : c.IN_VALIDO_LOTE,
                  IN_VALIDO_CNM:  (c.ID_COTA === '' && ((c.QT_DISPONIVEL > (App.diretivas.VolumeMinimoAgendar ?? 0) || c.IN_VALIDO_CNM === 'T') && c.IN_VALIDO === 'T'))?? false,
                }))
                .map(c => ({...c,
                  _ID_CN: c.ID_CN === '0' ? '---' : c.ID_CN,
                  TP_COTA_ICON: 
                    c.TP_COTA === 'EXCLUSIVA' ? <icons.MdPerson title={c.TP_COTA} size={24} /> : <icons.MdGroups title={c.TP_COTA} size={24} />,
                  DS_OPRPRT_ICON: 
                    c.DS_OPRPRT==='CARGA' ? <icons.CargaIcon title={lang.carga} size={24} /> : <icons.DescargaIcon title={lang.descarga} size={24} />,
                  ACTION: c.ID_COTA !== '' ? <button 
                            disabled={c.IN_VALIDO !== 'T' || !c.IN_VALIDO_LOTE} 
                            onClick={() => agendar(c)} className='small'
                          >
                            {lang.agendar}
                          </button> : 
                          <button 
                          onClick={() => agendar(c)} className='small' disabled={!c.IN_VALIDO_CNM}
                        >
                          {lang.agendar}
                        </button>
                          ,
                  _QT_DISPONIVEL: <span className={['destaque', c.ID_COTA !== '' ? (c.IN_VALIDO === 'T' && c.IN_VALIDO_LOTE?'info':'danger') : c.IN_VALIDO_CNM ? 'info' : 'danger'].join(' ')}>
                                    {c.QT_DISPONIVEL}
                                  </span>,
                  DT_COTA_TB: c.ID_COTA !== '' ? <span className={['destaque nowrap', c.IN_VALIDO === 'T' && c.IN_VALIDO_LOTE?'info':'danger'].join(' ')}>
                                {c.DT_COTA}
                              </span> : 
                              <span className={['destaque nowrap',c.IN_VALIDO_CNM?'info':'danger'].join(' ')} >
                                {lang.data_definir}
                              </span>,
                  DS_TRN: c.ID_COTA !== '' ? c.DS_TRN : <span className={['destaque nowrap',c.IN_VALIDO_CNM?'info':'danger'].join(' ')}> {lang.data_definir}</span>,
                }))
              } />
          : 
            contratos?.length
            ? [...contratos, ...semContrato].map((d,i)=><AgendarCard key={i} contrato={d} />)
            : lang.sem_cota_agendamento
          }
        </c.Frame>
      </c.TabPanel>

      {/* Cota / Carga fracionada */}
      <c.TabPanel>
        <c.Frame title={lang.agendamento + ' - Carga fracionada'} loading={loadingFracionados} flex className='w100'>
          <c.Table data={
            cotasFracionadas.map(d=>({...d,
              TP_FRT: d.TP_FRT === 'F' ? 'FOB' : 'CIF',
              TP_COTA_ICON: d.TRANSPORTADORAS?.length === 1
                ? <icons.MdPerson title='Exclusiva' size={24} /> 
                : <icons.MdGroups title='Compartilhada' size={24} />,
                LOCAIS_CARREGAMENTO: 
                [...new Set( d.ORIGENS?.map(l=>l.NM_PES) )].join(', '),
              CAPACIDADE_VCL: f.formatNumber(d.QT_CAPMAX) + ' ' + d.ID_UNDMOC,
              PESO_CARGA: f.formatNumber(
                            d.ITENS.reduce((acc, i) => 
                              parseFloat(acc) + parseFloat(i.QT_EXP) * parseFloat(i.CONVERSION)
                            , 0)) + ' KG',
              ITENS: d.ITENS.map(i=>({...i,
                        QUANTIDADE: i.QT_EXP + ' ' + i.ID_UNDMEXP
                      })),
              DS_OPRPRT_ICON: d.TP_OPRPRT === 'E' 
                ? <icons.CargaIcon title={lang.carga} size={24} /> 
                : <icons.DescargaIcon title={lang.descarga} size={24} />,
              _ITENS: d.ITENS.reduce((acc,i) => <>
                        {acc}{acc?<br/>:''}{i.QT_EXP} x {i.ID_UNDMEXP} de {i.NM_PRO}
                      </>, ''),
              _LOCAIS: 
                      <c.Table isSub noHeader
                        data={ Object.values( 
                          d.ORIGENS.reduce((acc,l) => ({...acc, 
                            [l.NR_CNPJUND]: l})
                          , {}) )
                          .map(l=>({...l, 
                            ...d.TURNOS.find(t=>t.NR_CNPJUND===l.NR_CNPJUND)??{} 
                          }))
                          .map(l=>({...l, 
                            _DT_CRG: App.moment(l.DT_CRG).format('DD/MM/YYYY') 
                          }))
                        } columns={[ ['a','NAME'],['b','_DT_CRG'],['c','DS_TRN'] ]} />,
              _DESTINOS: <c.Table isSub noHeader data={d.DESTINOS} columns={[['','NM_PES']]} />,
            })).map((d,d_id)=>({...d,
              _VIEW: <c.IconButton onClick={()=>setViewFracionadaDetails(d)} title="Detalhes">
                        <icons.BsEye/>
                      </c.IconButton>,
              _AGENDAR: <button onClick={()=>SetAgendarFracionadoModal(cotasFracionadas[d_id])} title="Agendar">
                          Agendar
                        </button>
            }))
          } columns={[
            [lang.cota, 'TP_COTA_ICON', {}, 'f center force-fit'],
            [lang.operacao, 'DS_OPRPRT_ICON', {}, 'f center force-fit'],
            ['OC', 'ID_UOCC',{},'force-fit'],
            [lang.locais_turnos_coleta, '_LOCAIS'],
            [lang.destinos, '_DESTINOS'],
            ['','_VIEW',{},'force-fit'],
            ['','_AGENDAR',{},'force-fit'],
          ]} />
        </c.Frame>
      </c.TabPanel>
    </c.Tabs>

    {!!agendarCheioModal && 
      <AgendarCheioModal cota={agendarCheioModal} 
        onClose={()=>{setAgendarCheioModal(null); get()}} />}

    {!!agendarFracionadoModal && 
      <AgendarFracionadoModal oc={agendarFracionadoModal} 
        onClose={()=>{SetAgendarFracionadoModal(null); get()}} />}

    {viewFracionadaDetails && 
      <p.OrdemCarregamentoDetailsModal ordem={viewFracionadaDetails} onClose={()=>setViewFracionadaDetails(null)} />}
  </>)


  // ------------------------------ CARD DO AGENDAR ------------------------------
  function AgendarCard({contrato}){

    console.log(contrato??'')

    let obs_initial
    try{
      obs_initial = JSON.parse( contrato?.DS_OBS ? contrato?.DS_OBS : '[]' )
    }catch(e){
      obs_initial = [{ds_obs: contrato?.DS_OBS}]
    }

    const DS_OBS = [...obs_initial,
      !!contrato.VL_FRETENEW ? {id_color: 'danger', ds_obs: lang.card_nova_tarifa + contrato.DT_FRETENEW + ': R$ ' + contrato.VL_FRETENEW} : null,
      !!contrato.VL_FRETEMOTORISTANEW ? {id_color: 'danger', ds_obs: lang.card_valor_maximo_motorista + contrato.DT_FRETEMOTORISTANEW + ': R$ ' + contrato.VL_FRETEMOTORISTANEW} : null,
      contrato.CD_STS==="-2" ? {id_color: 'warning', ds_obs: lang.card_pausar + contrato.DT_MAXCOTA} : null,
    ].filter(o=>!!o&&!!o.ds_obs)

    async function confirmarAceiteContrato(){
      const doc_url = App.baseURL + 'assets/documents/contrato-rumo-transportadoras.pdf'
      App.confirm(
        <object data={doc_url} type="application/pdf" style={{width: '70vw', height: '70vh'}}>
          <p>Não foi possível exibir o arquivo. Faça o <a href={doc_url}>Download</a>.</p>
        </object>,
        async () => {
          await App.api('DistribuirLote::aceiteContrato', {ID_UOCC: contrato.ID_CN})
          get()
          App.toast.success("Contrato " + contrato.ID_CN + " aceito!")
          return true
        },
        'Aceita o contrato de prestação de serviços de transporte?'
      )
    }

    return(
      <div className="contrato-card">
        {App.moment().format('DD/MM/YYYY') === contrato.DT_CDT
          ? <div className="fita-novo">Novo</div> : null
        }

        <div className="header">
          <div className="base">
            <c.Avatar size={48} />
            <div><span>{contrato.DS_ORIGEM + " - " + contrato.NM_PESORI}</span></div>
            <div><span>{contrato.DS_DESTINO + " - " + contrato.NM_PESDST}</span></div>
            <div><span>{contrato.NM_PRO}</span></div>
          </div>
  
          <div className="actions">
            {(contrato.ID_NATOP !== (App.diretivas.NatopVelogContratoLote??'3') || contrato.CNTRP_STS !== '1') &&
              <button onClick={()=>{
                setFilter( contrato.ID_CN 
                  ? {_ID_CN: contrato.ID_CN} 
                  : {
                    _ID_CN: '',
                    NM_PESORI: contrato.NM_PESORI,
                    NM_PESDST: contrato.NM_PESDST,
                    NM_PRO: contrato.NM_PRO,
                  }
                )
                setShowFilters(true)
                setToggleCardsList(true)
              }}>{lang.agendar}</button>
            }

            {contrato.ID_NATOP === (App.diretivas.NatopVelogContratoLote??'3') && contrato.CNTRP_STS === '1' &&
              <button onClick={confirmarAceiteContrato}>
                Aceitar
              </button>
            }
          </div>
        </div>

        <c.Divider />

        <div className="infos">
          {!!contrato.VL_FRETE && <c.Span value={f.formatNumber(contrato.VL_FRETE,2)} label={lang.frete} />}
          {!!contrato.VL_FRETEMOTORISTA && <c.Span value={f.formatNumber(contrato.VL_FRETEMOTORISTA, 2)} label={lang.frete_motorista} />}
          {!!contrato.VL_PEDAGIO && <c.Span value={f.formatNumber(contrato.VL_PEDAGIO, 2)} label={lang.pedagio} />}

          <c.Span value={f.formatNumber(contrato.QT_SLC)} label={lang.volume_ton} />
          <c.Span value={f.formatNumber(contrato.QT_SALDO)} label={lang.saldo_ton} />

          {contrato.QT_CAD &&
            <c.Span value={<>
              <span style={{fontSize: 12}}>
                {f.formatNumber(parseFloat(contrato.QT_ORG_HOJE??0) + parseFloat(contrato.QT_VOLUMECOTA_HOJE??0), 2)}
              </span>/{contrato.QT_CAD}</>
            } label={lang.volume_cadencia} />}

          {!!contrato.ID_CN && <c.Span value={contrato.ID_CN} label={lang.contrato} />}
          {!!contrato.DT_INICIO && <c.Span value={contrato.DT_INICIO} label={lang.inicio} />}
          {!!contrato.DT_MAXCOTA && <c.Span value={contrato.DT_MAXCOTA} label={lang.termino_previsto} />}
        </div>

        {!!DS_OBS.length && <>
          <c.Divider />
          <div className="obs observations">
            {DS_OBS.map((obs, i)=>obs.ds_obs!==""&&<div key={"obs"+i} className={'item ' + obs.id_color}>{obs.ds_obs}</div>)}
          </div>
        </>}
      </div>
    )
  }
}
// ---------------------- MODAL AGENDAR - DATA/TURNO - CNM  -----------------------------

function AgendarCNMModal({virtualCota, setVirtualCota, cotas}){

  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador}

  
  return ( <div className='f f-between w100'>
    <c.Table isSub 
    columns={[
    [lang.cota,'ID_UOCC'], 
    [lang.data,'DT_COTA'],
    [lang.turno,'DS_TRN'],
    [lang.agendar_qt,'QT_COTA'],
    [lang.agendar_disp, 'QT_DISPONIVEL'],
    [lang.uso, 'QT_AGENDADO'],
    [lang.acao, 'ACTION', {}, 'f center']
  ]} 
    data={cotas.map(c=>({...c,
    QT_AGENDADO: parseInt(c.QT_AGENDADO),
    QT_DISPONIVEL: <span className={['destaque', c.IN_VALIDO === 'T'?'info':'danger'].join(' ')}>
    {c.QT_DISPONIVEL}
  </span>,
    ACTION: <button disabled={virtualCota?.ID_UOCC === c.ID_UOCC || parseInt(c.QT_DISPONIVEL) === 0 || c.IN_VALIDO !== 'T'} onClick={()=>setVirtualCota(c)}>
      {lang.agendar}</button>
      
    }))} 
    />
</div>
  
  
  )
}

// ---------------------- MODAL AGENDAR - CAVALO - PARTIALS -----------------------------
function CavaloModalPartial({tracao, setTracao, tiposVeiculo, motorista, setMotorista, reboques, setReboques}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador}

  const paises = [
    {value: "Brasil", label: <><c.Flag code={"BR"} />{` ${lang.brasil}`}</>},
    {value: "Argentina" , label: <><c.Flag code={"AR"} />{` ${lang.argentina}`}</>},
    {value: "Uruguai" , label: <><c.Flag code={"UY"} />{` ${lang.uruguai}`}</>},
    {value: "Paraguai" , label: <><c.Flag code={"PY"} />{` ${lang.paraguai}`}</>},
    {value: "Chile" , label: <><c.Flag code={"CL"} />{` ${lang.chile}`}</>}
  ]

  function getDadosCavalo(e){
    if( tracao.id_vcl?.length >= 5 ){
      Promise.all([
        App.api('veiculos::getById', {id_vcl: tracao.id_vcl}),
        App.api('veiculos::consultaVeiculo',{id_vcl: tracao.id_vcl})
      ]).then((r) =>{
        setTracao({...tracao, ...r[0].results,
          qt_capac: r[0].results.QT_CAPMAX,
          qt_comp: r[0].results.QT_EIXO,
          nr_renavam: r[0].results.ID_RNV,
          qt_bruto: r[0].results.QT_PBT,
          status: r[1].status
        })
        setReboques([
          r[0].results.ID_RBQ1 ? {
            id_vclrbq: r[0].results.ID_RBQ1,
            nr_renavamrbq: r[0].results.ID_RNV1,
            qt_capacrbq: r[0].results.QT_CAPMAX1,
            ds_marcarbq: r[0].results.DS_MARCA1,
            id_ufrbq: r[0].results.ID_UF1,
          } : {},
          r[0].results.ID_RBQ2 ? {
            id_vclrbq: r[0].results.ID_RBQ2,
            nr_renavamrbq: r[0].results.ID_RNV2,
            qt_capacrbq: r[0].results.QT_CAPMAX2,
            ds_marcarbq: r[0].results.DS_MARCA2,
            id_ufrbq: r[0].results.ID_UF2,
          } : {},
          r[0].results.ID_RBQ3 ? {
            id_vclrbq: r[0].results.ID_RBQ3,
            nr_renavamrbq: r[0].results.ID_RNV3,
            qt_capacrbq: r[0].results.QT_CAPMAX3,
            ds_marcarbq: r[0].results.DS_MARCA3,
            id_ufrbq: r[0].results.ID_UF3,
          } : {},
        ])

        let telcel = f.strNums(r[0].results.nr_telcel)
        telcel = telcel.length > 13 ? telcel.substring( telcel.length - 13 ) : telcel
        setMotorista({...motorista,
          doc_motorista: r[0].results.NR_CPFMTR,
          nm_motorista: r[0].results.nm_pes,
          nr_cnh: r[0].results.nr_cnh,
          dt_emscnh: r[0].results.dt_expcnh,
          dt_vldcnh: r[0].results.dt_vctcnh,
          ds_ctgcnh: r[0].results.ds_ctgcnh,
          nr_telcel: '+' + telcel,
          nr_rg: r[0].results.nr_rg
        })
      })
      
    }
  }

  return(<div className="f f-wrap g1">
    <div className='f g1 f-between w100'>
      <c.Select label={lang.pais} required options={paises} value={tracao.pais} onChange={e=>setTracao({...tracao, pais: e.value})} className="" />
      <c.Input label={lang.placa} value={tracao.id_vcl} error={!(f.validatePlaca(tracao.pais, tracao.id_vcl)) || !tracao.status} onChange={e=>setTracao({...tracao, id_vcl: e.target.value.toUpperCase()})} onBlur={getDadosCavalo} maxLength={7} onSearch={getDadosCavalo} className="f1" />
      <c.Select label={lang.tipo} required options={tiposVeiculo} value={tracao.TP_VCL} onChange={e=>setTracao({...tracao, TP_VCL: e.value})} className="" />
      <c.Input label={lang.eixos} type="number" error={!parseInt(tracao.QT_EIXO)} value={tracao.QT_EIXO} onChange={e=>setTracao({...tracao, QT_EIXO: e.target.value})} className="" />
      <c.Input label={lang.capacidade_carga} type="number" error={!tracao.qt_capac} value={tracao.qt_capac} onChange={e=>setTracao({...tracao, qt_capac: e.target.value})} className="" />
      <c.Input label={lang.peso_bruto_total} type="number" error={!tracao.qt_bruto} value={tracao.qt_bruto} onChange={e=>setTracao({...tracao, qt_bruto: e.target.value})} className="" />
    </div>

    <div className='f g1 f-between w100'>
      <c.Input label={lang.comprimento_total} type="number" error={!tracao.qt_comp} value={tracao.qt_comp} onChange={e=>setTracao({...tracao, qt_comp: e.target.value})} className="" />
      <c.Input label={lang.renavam} error={!tracao.nr_renavam} value={tracao.nr_renavam} onChange={e=>setTracao({...tracao, nr_renavam: e.target.value.replace(/[^0-9]/g, '')})} maxLength={20} className="f1" />
      <c.Input label={lang.marca} error={!tracao.DS_MARCA} value={tracao.DS_MARCA} onChange={e=>setTracao({...tracao, DS_MARCA: e.target.value})} maxLength={60} className="f1" />
      <c.Input label={lang.modelo} error={!tracao.DS_MODELO} value={tracao.DS_MODELO} onChange={e=>setTracao({...tracao, DS_MODELO: e.target.value})} maxLength={60} className="f1" />
    </div>

    <div className='f g1 f-between w100'>
      <c.Select label={lang.uf_emplacamento} required options={f.UFs} value={tracao.ID_UF} onChange={e=>setTracao({...tracao, ID_UF: e.value})} className="f1" />
      <c.Input label={lang.ano_fab} type="number" error={!tracao.DT_ANOFAB} required value={tracao.DT_ANOFAB} onChange={e=>setTracao({...tracao, DT_ANOFAB: e.target.value})} maxLength={4} className="f1" />
      <c.Input label={lang.ano_mod} type="number" error={!tracao.DT_ANOMOD} required value={tracao.DT_ANOMOD} onChange={e=>setTracao({...tracao, DT_ANOMOD: e.target.value})} maxLength={4} className="f1" />
    </div>
  </div>)
}


// ---------------------- MODAL AGENDAR - CARRETA - PARTIALS -----------------------------
function CarretaModalPartial({reboques, setReboques}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador}

  function changeReboques(id, field, value){
    setReboques( reboques.slice(0, id)
      .concat({...reboques[id], [field]: value})
      .concat(reboques.slice(id+1, 3)) 
    )
  }

  function getDadosReboque(id){
    if( reboques[id].id_vclrbq?.length >= 5 ){
    Promise.all([
      App.api('veiculos::getById', {id_vcl: reboques[id].id_vclrbq}),
      App.api('veiculos::consultaVeiculo',{id_vcl: reboques[id].id_vclrbq})
    ]).then(r=>{
      setReboques( reboques.slice(0, id)
      .concat({...reboques[id],
        nr_renavamrbq: r[0].results.ID_RNV,
        qt_capacrbq: r[0].results.QT_CAPMAX,
        ds_marcarbq: r[0].results.DS_MARCA,
        id_ufrbq: r[0].results.ID_UF,
        status:r[1].status
      }).concat(reboques.slice(id+1, 3)) )
    })
      
    }
  }

  return(<div className="f f-wrap g1">
    {[0,1,2].map(r=>
      [...Array(r)].every((_,i)=>!!reboques[i].id_vclrbq)&&
      <div key={r} className='f g1 w100'>
        <c.Input label={lang.placa_carreta+' '+(r+1)} value={reboques[r].id_vclrbq} error={(!r&&!(reboques[r].id_vclrbq?.length>=5) || !reboques[r].status)} 
          onChange={e=>changeReboques(r, 'id_vclrbq', e.target.value)} maxLength={7} onBlur={()=>getDadosReboque(r)} 
          onSearch={()=>getDadosReboque(r)} />
        <c.Input label={lang.renavam} required value={reboques[r].nr_renavamrbq} onChange={e=>changeReboques(r, 'nr_renavamrbq', e.target.value.replace(/[^0-9]/g, ''))} maxLength={20} />
        <c.Input type="number" required value={reboques[r].qt_capacrbq} onChange={e=>changeReboques(r, 'qt_capacrbq', e.target.value)} label={lang.capacidade_carga} />
        <c.Input required value={reboques[r].ds_marcarbq} onChange={e=>changeReboques(r, 'ds_marcarbq', e.target.value)} label={lang.marca} maxLength={60} />
        <c.Select label={lang.uf} required options={f.UFs} value={reboques[r].id_ufrbq} onChange={e=>changeReboques(r, 'id_ufrbq', e.value)} style={{minWidth: 100}} />
      </div>
    )}
  </div>)
}


// ---------------------- MODAL AGENDAR - MOTORISTA - PARTIALS -----------------------------
function MototistaModalPartial({motorista, setMotorista, tiposDocumento}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador}

  function getMotorista(nr_cpf) {
    if( !!nr_cpf ){
      App.api('parceiros::consulta', {nr_cpf}).then(r => {
        if( r.status && r.results.nm_pes ){
          let telcel = f.strNums(r.results.nr_telcel)
          telcel = telcel.length > 13 ? telcel.substring( telcel.length - 13 ) : telcel

          return setMotorista({...motorista,
            nm_motorista: r.results.nm_pes,
            nr_cnh: r.results.nr_cnh,
            dt_emscnh: r.results.dt_expcnh,
            dt_vldcnh: r.results.dt_vctcnh,
            ds_ctgcnh: r.results.ds_ctgcnh,
            nr_telcel: '+' + telcel,
            nr_rg: r.results.nr_rg
          })  
        }
      })
    }
  }

  return(<div className="f f-wrap g1">
    <div className='f g1 w100'>
      <c.Select label={lang.tipo} required options={tiposDocumento} value={motorista.tp_dco} onChange={e=>setMotorista({...motorista, tp_dco: e.value})} style={{minWidth: 200}} />
      <c.Input required 
        label={tiposDocumento.find(t => t.value===motorista.tp_dco)?.label} 
        //value={f.formatCpf(motorista.doc_motorista)} 
        value={motorista.tp_dco === '1' ? f.formatCpf(motorista.doc_motorista) : motorista.doc_motorista} 
        onChange={e => setMotorista({...motorista, doc_motorista: f.strNums(e.target.value)})} 
        onBlur={e => getMotorista(motorista.doc_motorista)} 
        error={motorista.tp_dco === '1' ? !f.validateCPF(motorista.doc_motorista) : !motorista.doc_motorista} 
        info={motorista.tp_dco === '1' && !f.validateCPF(motorista.doc_motorista) ? 'Documento inválido' : ''} 
      />
    </div>
    <div className='f g1 f-wrap w100'>
      <c.Input label={lang.nome} required value={motorista.nm_motorista} onChange={e=>setMotorista({...motorista, nm_motorista: e.target.value})} maxLength={60} className="w100" />
      <c.Input label={lang.habilitacao} required maxLength={20} className="f1" type="number"
        //error={motorista.tp_dco === '1' && !f.validateCNH(motorista.nr_cnh)} 
        value={motorista.nr_cnh} 
        onChange={e => setMotorista({...motorista, nr_cnh: e.target.value})} />
      <c.Input label={lang.data_emissao} required type="date" value={motorista.dt_emscnh} onChange={e=>setMotorista({...motorista, dt_emscnh: e.target.value})} className="f1" />
      <c.Input label={lang.data_validade} error={!f.validateVencCNH(motorista.dt_vldcnh)} required type="date" value={motorista.dt_vldcnh} onChange={e=>setMotorista({...motorista, dt_vldcnh: e.target.value})} className="f1" />
      <c.Input label={lang.categoria} required value={motorista.ds_ctgcnh} onChange={e=>setMotorista({...motorista, ds_ctgcnh: e.target.value})} maxLength={2} className="f1" />
      <c.Input label={lang.num_celular} required value={f.formatTel(motorista.nr_telcel)} onChange={e=>setMotorista({...motorista, nr_telcel: '+' + f.strNums(e.target.value)})} className="f1" />
    </div>
  </div>)
}


// ---------------------- MODAL AGENDAR FRACIONADO ----------------------------
function AgendarFracionadoModal({oc, onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador},
        icons = App.icons

  const [tracao, setTracao] = useState({}),
        [motorista, setMotorista] = useState({tp_dco: '1'}),
        [reboques, setReboques] = useState([{},{},{}]),
        [declaro, setDeclaro] = useState(false),
        [tiposVeiculo, setTiposVeiculo] = useState([]),
        [tiposDocumento, setTiposDocumento] = useState([]),
        [loading, setLoading] = useState(false),
        [step, setStep] = useState(0)

  const steps = [
    {label: 'Cavalo', 
      validate: tracao.id_vcl?.length>=5 && tracao.TP_VCL && !!parseInt(tracao.QT_EIXO)
          && tracao.qt_capac  && tracao.nr_renavam && tracao.DS_MARCA && tracao.DS_MODELO
          && tracao.ID_UF && tracao.DT_ANOFAB && tracao.DT_ANOMOD 
          && f.validatePlaca(tracao.pais, tracao.id_vcl ) && tracao.status
    },
    {label: 'Carreta', 
      validate: !reboques[0].id_vclrbq || (
        [0,1,2].every(r=>!reboques[r].id_vclrbq||(
          !!reboques[r].nr_renavamrbq&&!!reboques[r].qt_capacrbq&&!!reboques[r].ds_marcarbq&&!!reboques[r].id_ufrbq&&reboques[r].status
        )))
    },
    {label: 'Motorista', 
      validate: !!motorista.tp_dco&&!!f.validateCPF(motorista.doc_motorista)&&!!motorista.nm_motorista&&!!motorista.nr_cnh&&!!motorista.dt_emscnh&&!!f.validateVencCNH(motorista.dt_vldcnh)&&!!motorista.ds_ctgcnh&&!!motorista.nr_telcel
    },
    {label: 'Confirmação', validate: declaro},
  ]
  
  function get(){
    App.api('veiculos::getTipos').then(r=>{
      setTiposVeiculo( r.results.lst_tipo.map(t=>({value: t.ID, label: t.TEXT})) )
      setTiposDocumento(r.results.lst_dco.map(t=>({value: t.ID, label: t.TEXT})) )
    })
  }

  function submit(){
    setLoading(true)

    return App.api('ordemCarregamento::savePortaria', {
      oc_id: oc.ID_UOCC, 
      tracao, reboques, motorista,
    }).then(r=>{
      setLoading(false)
      if(r.status) 
        App.toast.success( 'Criadas portarias ' + r.results.portarias.join(', ') + ' com sucesso!' )
      return r.status
    })
  }

  useEffect(()=>{ get() }, [])

  return(
  <c.Modal title={"Agendamento carga fracionada nº " + oc.ID_UOCC} steps={steps} largeWidth
    onStepChange={s=>setStep(s)} validate={steps.every(s=>s.validate)} onFinish={submit}
    contentHeight={400} onClose={onClose} loading={loading} successMsg={false}
  ><div className='f g1 f-column'>
    <c.Frame flex>
      <c.Span label={lang.origem} value={
        <c.Table isSub noHeader data={oc.TURNOS} columns={[['','NM_PES'], ['','DT_CRG'], ['','DS_TRN'],]} />
      } className="f1" />

      <c.Span label={lang.destino} value={
        <c.Table isSub noHeader data={oc.DESTINOS} columns={[['','NM_PES'], ['','NM_LOC'], ['','ID_UF'],]} />
      } />

      <c.Span label={lang.produto} value={
        <c.Table isSub noHeader data={oc.ITENS} columns={[['','QT_EXP'], ['','ID_UNDMEXP'], ['','NM_PRO']]} />
      } className="f1" />
      <c.Span label={'Peso total'} value={
        oc.ITENS?.map(i=>parseFloat(i.QT_CAP)).reduce((a,b)=>a+b) + ' Kg'
      } />
    </c.Frame>

    {/* ABA CAVALO */}
    {step===0 && <CavaloModalPartial tracao={tracao} setTracao={setTracao} tiposVeiculo={tiposVeiculo} motorista={motorista} setMotorista={setMotorista} reboques={reboques} setReboques={setReboques} />}

    {/* ABA CARRETAS */}
    {step===1 && <CarretaModalPartial reboques={reboques} setReboques={setReboques} />}

    {/* ABA MOTORISTA */}
    {step===2 && <MototistaModalPartial motorista={motorista} setMotorista={setMotorista} tiposDocumento={tiposDocumento} />}

    {/* ABA DETALHES */}
    {step===3&&<div className="f f-column g1">
      <div className='f g1 f-between'>
        <c.Span value={tracao.id_vcl??'-----'} label={lang.cavalo} />
        <c.Span value={tiposVeiculo.find(t=>t.value===tracao.TP_VCL)?.label??'-----'} label={lang.tipo} />
        <c.Span value={tracao.QT_EIXO??'-----'} label={lang.eixos} />
        <c.Span value={tracao.qt_capac??'-----'} label={lang.capacidade_carga} />
        <c.Span value={tracao.nr_renavam??'-----'} label={lang.renavam} />
        <c.Span value={tracao.DS_MARCA??'-----'} label={lang.marca} />
      </div>

      <div className='f g1 f-between'>
        <c.Span value={tracao.DS_MODELO??'-----'} label={lang.modelo} />
        <c.Span value={reboques.map(r=>r.id_vclrbq?r.id_vclrbq+' ('+r.nr_renavamrbq+')':null).filter(r=>r!==null).join('; ')||'-----'} label={lang.carreta} className='f1' />
      </div>

      <div className='f g1 f-between'>
        <c.Span value={motorista.nm_motorista??'-----'} label={lang.motorista} />
        <c.Span value={f.formatCpf(motorista.doc_motorista)||'-----'} label={tiposDocumento.find(t=>t.value===motorista.tp_dco)?.label} />
        <c.Span value={motorista.nr_cnh??'-----'} label={lang.habilitacao} />
        <c.Span value={motorista.dt_vldcnh??'-----'} label={lang.validade} />
        <c.Span value={f.formatTel(motorista.nr_telcel)||'-----'} label={lang.celular} />
      </div>

      <c.Checkbox label={lang.declaro_que} checked={declaro} onChange={e=>setDeclaro(e.target.checked)} info={lang.marque_opcao} />
    </div>}
  </div></c.Modal>)
}


// ---------------------- MODAL AGENDAR CHEIO -----------------------------
function AgendarCheioModal({cota, onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador},
        icons = App.icons

  const [tracao, setTracao] = useState({}),
        [motorista, setMotorista] = useState({tp_dco: '1'}),
        [reboques, setReboques] = useState([{},{},{}]),
        [documentoCarga, setDocumentoCarga] = useState([]),
        [documentoCNH, setDocumentoCNH] = useState([]),
        [documentoRENAVAM, setDocumentoRENAVAM] = useState([]),
        [declaro, setDeclaro] = useState(false),
        [tiposVeiculo, setTiposVeiculo] = useState([]),
        [virtualCota, setVirtualCota] = useState({}),
        [cotas, setCotas] = useState([]),
        [tiposDocumento, setTiposDocumento] = useState([]),
        [loading, setLoading] = useState(false),
        [step, setStep] = useState(0),
        [disponibilidadesAppa, setDisponibilidadesAppa] = useState([])

  const isAppa = false && cota.TP_OBJ?.toUpperCase() === 'PRT_COTAINT' && !!cota.ID_OBJ

  const isCNM = !!cota.ID_CNM

  const steps = [
    isCNM ? {label: 'CNM', validate: !!virtualCota.ID_UOCC} : null,
    {label: 'Cavalo', 
      validate: tracao.id_vcl?.length>=5 && tracao.TP_VCL && !!parseInt(tracao.QT_EIXO)
                && tracao.qt_capac && tracao.nr_renavam && tracao.DS_MARCA && tracao.DS_MODELO
                && tracao.ID_UF && tracao.DT_ANOFAB && tracao.DT_ANOMOD 
                && f.validatePlaca(tracao.pais, tracao.id_vcl) && tracao.status
    },
    {label: 'Carreta', 
      validate: !reboques[0].id_vclrbq || (
        [0,1,2].every(r=>!reboques[r].id_vclrbq||(
          !!reboques[r].nr_renavamrbq&&!!reboques[r].qt_capacrbq&&!!reboques[r].ds_marcarbq&&!!reboques[r].id_ufrbq&&reboques[r].status
        )))
    },
    {label: 'Motorista', 
      validate: !!motorista.tp_dco                
                //&&!!(motorista.doc_motorista)
                && ( motorista.tp_dco !== '1' || !!f.validateCPF(motorista.doc_motorista) )
                && !!motorista.nm_motorista
                //&& !!motorista.nr_cnh
                //&& ( motorista.tp_dco !== '1' || !!f.validateCNH(motorista.nr_cnh) )
                && !!motorista.dt_emscnh
                && !!f.validateVencCNH(motorista.dt_vldcnh)
                && !!motorista.ds_ctgcnh
                && !!motorista.nr_telcel
    },
    {label: 'Documentos', 
      validate: 
      (App.diretivas.ValidarOrdemCarga === 'T' && cota.TP_OPRPRT === 'R')
      || (App.diretivas.ValidarOrdemCarga === 'F')
      || (App.diretivas.ValidarOrdemCarga === 'AD' && cota.TP_OPRPRT === 'E')
      || (App.diretivas.ValidarOrdemCarga === 'LD' && cota.TP_OPRPRT === 'R')
      || (App.diretivas.ValidarOrdemCarga === 'ACLD' && cota.TP_OPRPRT === 'R')
      || !!documentoCarga[0]?.file},
    isAppa ? {label: 'Appa',
      validate: true,
    } : null,
    {label: 'Confirmação', validate: declaro},
  ].filter(i=>!!i)

  async function get(){
    App.api('veiculos::getTipos').then(r=>{
      App.api('cotas::getCotasCNM', {ID_CN: cota.ID_CN, ID_CNM: cota.ID_CNM}).then(c=>{
        setTiposVeiculo( r.results.lst_tipo.map(t=>({value: t.ID, label: t.TEXT})) )
        setTiposDocumento(r.results.lst_dco.map(t=>({value: t.ID, label: t.TEXT})) )
        setCotas(c.results)
      })
    })

    if( isAppa ){
      const cotaint = (await App.api('IntegracaoAppa::getCotaIntById',{id: cota.ID_OBJ})).results

      setDisponibilidadesAppa( 
        (await App.api('IntegracaoAppa::disponibilidades', {
          int_id: cota.ID_OBJ
        })).results.find(disponibilidade => disponibilidade.data === App.moment(cotaint.DT_PRV).format('DD/MM/YYYY') )
      )
    }
  }

  useEffect(() => {
    console.log(App.diretivas.VolumeMinimoAgendar)
  },[virtualCota])

  async function submit(){
    setLoading(true)

    const docCarga64   = !!documentoCarga?.[0]?.file ? await f.fileToBase64(documentoCarga?.[0]?.file??null) : '',
          docCNH64     = !!documentoCNH?.[0]?.file ? ( 
                            documentoCNH?.[0]?.file.type === "application/pdf" 
                            ? await f.fileToBase64(documentoCNH?.[0]?.file??null) 
                            : (await f.resizeImg(documentoCNH?.[0].file, 1200, 900)).split(',').slice(1).join(',')
                          ) : '',
          docRENAVAM64 = !!documentoRENAVAM?.[0]?.file ? (
                            documentoRENAVAM?.[0]?.file.type === "application/pdf" 
                            ? await f.fileToBase64(documentoRENAVAM?.[0]?.file??null)
                            : (await f.resizeImg(documentoRENAVAM?.[0].file, 1200, 900)).split(',').slice(1).join(',')
                          ) : ''
    
    const save = await App.api('portarias::save', {
      documentoCarga: {data: docCarga64, name: documentoCarga?.[0]?.name},
      documentoCNH: {data: docCNH64, name: documentoCNH?.[0]?.name},
      documentoRENAVAM: {data: docRENAVAM64, name: documentoRENAVAM?.[0]?.name},
      id_cota: isCNM ? virtualCota.ID_UOCC : cota.ID_COTA,
      id_cnm: virtualCota.ID_CNM??null,
      id_vcl: tracao.id_vcl,
      qt_eixo: tracao.qt_eixo,
      qt_capac: tracao.qt_capac,
      tp_vcl: tracao.tp_vcl,
      qt_comp: tracao.qt_comp,
      nr_cpfmtr: motorista.doc_motorista,
      nr_cpfcnpjtrp: sessionStorage.getItem('nr_cnpj'),
      lst_tracao: tracao,
      lst_reboque1: reboques[0], lst_reboque2: reboques[1], lst_reboque3: reboques[2],
      lst_motorista: motorista,
    })
    
    if( save.status ) { //&& cota.TP_OPRPRT === 'E'
     // sendAppApiNovoAgendamento é uma async mas está sendo chamada sem await pois será em background
     f.sendAppApiNovoAgendamento({App, cota, motorista, tracao, 
       prtIdUocc: save.results.id_uocc,
       cnpjTrp: sessionStorage.getItem('nr_cnpj'),
     })
    }

    if( save.status ){
      App.toast.success( lang.agendamento_sucesso.replace('%t', save.results.id_uocc).replace('%v', save.results.id_vcl) )
    }

    setLoading(false)
    return save.status
  }

  useEffect(()=>{ 
    get()
  }, [])

  return(
  <c.Modal title={lang.agendar} steps={steps} onStepChange={s=>setStep(s)} onClose={onClose} loading={loading}
    onFinish={submit} largeWidth contentHeight={400} validate={steps.every(s=>s.validate)} successMsg={false}
  ><div className='f g1 f-column'>
    <c.Frame flex>
      <div className='f g1 f-between w100'>
        <c.Span value={cota.NM_PESORI} label={lang.origem} />
        <c.Span value={cota.NM_PESDST} label={lang.destino} />
        <c.Span value={cota.NM_PRO} label={lang.produto} />
        <c.Span value={cota.NM_TOMADOR} label={'Tomador'} />
      </div>
      <div className='f g1 f-between w100'>
        <c.Span value={virtualCota?.DT_COTA ?? cota.DT_COTA} label={lang.data} />
        <c.Span value={virtualCota?.DS_TRN ?? cota.DS_TRN} label={lang.turno} />
        <c.Span value={f.formatCurrency(virtualCota?.VL_FRETE, 2) ?? f.formatCurrency(cota.VL_FRETE, 2)} label={lang.frete} />
        <c.Span value={f.formatCurrency(virtualCota?.VL_FRETEMOTORISTA, 2) ?? f.formatCurrency(cota.VL_FRETEMOTORISTA, 2)} label={lang.frete_motorista} />
        <c.Span value={f.formatCurrency(virtualCota?.VL_PEDAGIO, 2) ?? f.formatCurrency(cota.VL_PEDAGIO, 2)} label={lang.pedagio} />
      </div>
    </c.Frame>


    {/* ABA COTAS CNM */}

    {isCNM && step === 0 && <AgendarCNMModal virtualCota={virtualCota} setVirtualCota={setVirtualCota} cotas={cotas} />}

    {/* ABA CAVALO */}
    {step===(0 + isCNM) && <CavaloModalPartial tracao={tracao} setTracao={setTracao} tiposVeiculo={tiposVeiculo} />}

    {/* ABA CARRETAS */}
    {step===(1 + isCNM) && <CarretaModalPartial reboques={reboques} setReboques={setReboques} />}

    {/* ABA MOTORISTA */}
    {step===(2 + isCNM) && <MototistaModalPartial motorista={motorista} setMotorista={setMotorista} tiposDocumento={tiposDocumento} />}

    {/* ABA DOCUMENTO */}
    {step===(3 + isCNM) && 
      <div className="f f-column g1">
        <c.Span label={"Ordem de carregamento"} className='w100'
          value={
            <c.Upload label="Arquivo aceito: PDF. JPG e PNG. Máximo de 1 arquivo." maxFiles={1} className="w100"
              accept={{"application/pdf":['.pdf'],"image/png":['.png'],"image/jpeg":['.jpg']}} 
              files={documentoCarga} onChange={f => setDocumentoCarga(f??[])} 
            />
          }
        />

        <c.Span label={"CNH"} className='w100'
          value={
            <c.Upload label="Arquivo aceito: PDF. JPG e PNG. Máximo de 1 arquivo." maxFiles={1} className="w100"
              accept={{"application/pdf":['.pdf'],"image/png":['.png'],"image/jpeg":['.jpg']}} 
              files={documentoCNH} onChange={f => setDocumentoCNH(f??[])} 
            />
          }
        />

        <c.Span label={"RENAVAM"} className='w100'
          value={
            <c.Upload label="Arquivo aceito: PDF. JPG e PNG. Máximo de 1 arquivo." maxFiles={1} className="w100"
              accept={{"application/pdf":['.pdf'],"image/png":['.png'],"image/jpeg":['.jpg']}} 
              files={documentoRENAVAM} onChange={f => setDocumentoRENAVAM(f??[])} 
            />
          }
        />
      </div>
    }

    {/* ABA APPA */}
    {isAppa && step===4 && <div className="f f-wrap g1">
      {disponibilidadesAppa.horarios.map(horario => 
        <button>{horario.hora}</button>
      )}
    </div>}

    {/* ABA DETALHES */}
    {step === (4 + isCNM + isAppa) && <div className="f f-column g1">
      <div className='f g1 f-between'>
        <c.Span value={tracao.id_vcl??'-----'} label={lang.cavalo} />
        <c.Span value={tiposVeiculo.find(t=>t.value===tracao.TP_VCL)?.label??'-----'} label={lang.tipo} />
        <c.Span value={tracao.QT_EIXO??'-----'} label={lang.eixos} />
        <c.Span value={tracao.qt_capac??'-----'} label={lang.capacidade_carga} />
        <c.Span value={tracao.qt_bruto??'-----'} label={lang.peso_bruto_total} />
        <c.Span value={tracao.nr_renavam??'-----'} label={lang.renavam} />
        <c.Span value={tracao.DS_MARCA??'-----'} label={lang.marca} />
      </div>

      <div className='f g1 f-between'>
        <c.Span value={tracao.DS_MODELO??'-----'} label={lang.modelo} />
        <c.Span value={reboques.map(r=>r.id_vclrbq?r.id_vclrbq+' ('+r.nr_renavamrbq+')':null).filter(r=>r!==null).join('; ')||'-----'} label={lang.carreta} className='f1' />
      </div>

      <div className='f g1 f-between'>
        <c.Span value={motorista.nm_motorista??'-----'} label={lang.motorista} />
        <c.Span value={f.formatCpf(motorista.doc_motorista)||'-----'} label={tiposDocumento.find(t=>t.value===motorista.tp_dco)?.label} />
        <c.Span value={motorista.nr_cnh??'-----'} label={lang.habilitacao} />
        <c.Span value={motorista.dt_vldcnh??'-----'} label={lang.validade} />
        <c.Span value={f.formatTel(motorista.nr_telcel)||'-----'} label={lang.celular} />
      </div>

      <div className='f g1 f-between'>
        <c.Span label={lang.endereco_origem} value={[cota.NM_LGDORI, cota.NR_LGDORI, cota.NM_BROORI, cota.NM_LOCORI, cota.ID_UFORI, cota.NM_PAISORI].join(' ')||'-----'} />
        <c.Span label={lang.endereco_destino} value={[cota.NM_LGDDST, cota.NR_LGDDST, cota.NM_BRODST, cota.NM_LOCDST, cota.ID_UFDST, cota.NM_PAISDST].join(' ')||'-----'} />
      </div>

      <c.Checkbox label={lang.declaro_que} checked={declaro} onChange={e=>setDeclaro(e.target.checked)} info={lang.marque_opcao} />
    </div>}
  </div></c.Modal>
  )
}