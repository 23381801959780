import React, { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../App'
import * as c from '../c'
import * as f from '../f'
import * as p from '../p'

export default function Monitor({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.monitor},
        icons = App.icons

  const [monitores, setMonitores] = useState([]),
        [monitor, setMonitor] = useState({}),
        [selectedMonitor, setSelectedMonitor] = useState(0),
        [loading, setLoading] = useState(false),
        [localFisicoModal, setLocalFisicoModal] = useState(null),
        [motorista, setMotorista] = useState(null),
        [printTicketModal, setPrintTicketModal] = useState(null),
        [openTicketFracionado, setOpenTicketFracionado] = useState(null),
        [detalheModal, setDetalheModal] = useState(null),
        [viewFracionadaDetails, setViewFracionadaDetails] = useState(null),
        [dataFilter, setDataFilter] = useState(true),
        [openDfe, setOpenDfe] = useState(null),
        [selectedTab, setSelectedTab] = useState(parseInt(sessionStorage.getItem('monitorTab')??0)),
        [busca, setBusca] = useState(''),
        [showFilters, setShowFilters] = useState(false)

  const columns = {
    fila: [
      [lang.fila, 'ID_FILA'],
      [lang.nome, 'NM_FILA'],
      [lang.prioridade, 'NR_PRIORIDADE'],
      [lang.produto, 'NM_PRO'], 
      [lang.parceiro, 'NM_PES'], 
      [lang.veiculo, 'ID_VCL'],
      [lang.entrada, 'DT_EFILA'], 
      [lang.tempo, 'QT_TEMPO'],
      [lang.agendado, 'QT_AGENDADO', {}, '', "QT_AGD"],
      [lang.recepcionado, 'QT_PATIO', {}, '', "QT_PTO"], 
      [lang.operacao, 'QT_OPERACAO', {}, '', "QT_OPR"],
    ],
    agendado: [
      [lang.fila, 'ID_FILA'],
      [lang.nome, 'NM_FILA'],
      [lang.cod_itinerario, 'ID_OBJ'],
      [lang.operacao, 'DS_TPOPRPRT', {}, 'f center-h'],
      [lang.data, 'DT_PRV'],
      [lang.turno, 'DS_TRN'],
      [lang.local, '_DS_LFIS', {}, 'nowrap f g1 center-v'],
      [lang.veiculo, 'ID_VCL'],
      [lang.contrato, 'CN'],
      [lang.ordem,'ID_OC'],
      // [lang.ticket, 'ID_UOCC'],
      [lang.ticket, '_TICKET',{},'','ID_UOCC'],
      [lang.nfe, 'LST_NFE'],
      [lang.nr_nfe,'NR_NFE'],
      [lang.origem, 'DS_ORG'],
      [lang.destino, 'DS_DST'],
      [lang.produto, 'DS_PRD'],
      [lang.situacao, 'DS_STS'],
      [lang.tempo, 'TEMPO_TRS'],
      [lang.whatsapp, 'NR_TLFMTR'],
      [lang.acao, 'ACTION'],
      [lang.imprimir, 'IMPRIMIR', {}, 'f center-h'],
      // [lang.danfe, 'DANFE', {}, 'f center-h'],
      // [lang.dacte, 'DACTE', {}, 'f center-h'],
      // [lang.detalhe, 'DETALHE', {}, 'f center-h'],
      [lang.cpf_motorista, 'NR_CPFMTR'],
      [lang.nome_motorista, 'NM_MTR'],
      [lang.transportadora, 'NM_TRP'],
      // [lang.ordem, 'LST_ORDEM', {}, 'f center'],
      [<><icons.MdCloudDownload/>{lang.downloads}</>, 'downloads', {}, 'f g1'],
    ],
    recepcionado: [
      [lang.fila, 'ID_FILA'],
      [lang.nome, 'NM_FILA'],
      [lang.cod_itinerario, 'ID_OBJ'],
      [lang.p_f, 'NR_ORDEMFILA'],
      [lang.p_g, 'NR_ORDEM'],
      [lang.local, '_DS_LFIS', {}, 'nowrap f g1 center-v'],
      [lang.veiculo, 'ID_VCL'],
      [lang.contrato, 'ID_CN'],
      [lang.ordem,'ID_OC'],
      [lang.ticket, 'ID_UOCC'],
      [lang.nfe, 'LST_NFE'],
      [lang.nr_nfe,'NR_NFE'],
      [lang.data, 'DT_PRV'],
      [lang.turno, 'DS_TRN'],
      [lang.operacao, 'TPOPRPRT', {}, 'f center-h', 'DS_TPOPRPRT'],
      [lang.origem, 'DS_ORG'],
      [lang.destino, 'DS_DST'],
      [lang.produto, 'DS_PRD'],
      [lang.data_chegada, 'DT_CHG'],
      [lang.entrada_fila, 'DT_EFILA'],
      [lang.tempo, 'TEMPO_FILA'],
      [lang.acao, 'ACTION', {}, 'f center-h'],
      [lang.imprimir, 'IMPRIMIR', {}, 'f center-h'],
      [lang.whatsapp, 'WHATSAPP', {}, 'f center-h'],
      [lang.checar, 'CHK', {}, 'f center-h'],
      [lang.detalhe, 'DETALHE', {}, 'f center-h'],
      [lang.cpf_motorista, 'NR_CPFMTR'],
      [lang.nome_motorista, 'NM_MTR'],
      [lang.transportadora, 'NM_TRP'],
      [lang.cliente, 'NM_CLT'],
      [lang.tipo_veiculo, 'DS_TPVCL'],
      [lang.classificacao, 'IN_CLS', {}, 'f center-h'],
      [<><icons.MdCloudDownload/>{lang.downloads}</>, 'downloads', {}, 'f g1'],
    ],
    operacao: [
      [lang.fila, 'ID_FILA'],
      [lang.cod_itinerario, 'ID_OBJ'],
      [lang.nome, 'NM_FILA'],
      [lang.veiculo, 'ID_VCL'],
      [lang.contrato, 'ID_CN'],
      [lang.ordem,'ID_OC'],
      [lang.ticket, 'ID_UOCC'],
      [lang.nfe, 'LST_NFE'],
      [lang.nr_nfe,'NR_NFE'],
      [lang.data, 'DT_PRV'],
      [lang.operacao, 'TPOPRPRT', {}, 'f center-h'],
      [lang.origem, 'DS_ORG'],
      [lang.destino, 'DS_DST'],
      [lang.produto, 'DS_PRD'],
      [lang.local, 'ID_LFIS'],
      [lang.entrada, 'DT_OPR1'],
      [lang.tempo, 'TEMPO_OPR'],
      [lang.situacao, 'DS_STS'],
      [lang.cpf_motorista, 'NR_CPFMTR'],
      [lang.nome_motorista, 'NM_MTR'],
      [lang.transportadora, 'NM_TRP'],
      [lang.acao, 'ACTION'],
      [lang.imprimir, 'IMPRIMIR', {}, 'f center-h'],
      // [lang.danfe, 'DANFE', {}, 'f center-h'],
      // [lang.dacte, 'DACTE', {}, 'f center-h'],
      [lang.whatsapp, 'WHATSAPP', {}, 'f center-h'],
      [lang.checar, 'IN_CHK', {}, 'f center-h'],
      [lang.detalhe, 'DETALHE', {}, 'f center-h'],
      [<><icons.MdCloudDownload/>{lang.downloads}</>, 'downloads', {}, 'f g1'],
    ],
    finalizado: [
      [lang.fila, 'ID_FILA'],
      [lang.cod_itinerario, 'ID_OBJ'],
      [lang.nome, 'NM_FILA'],
      [lang.veiculo, 'ID_VCL'],
      [lang.contrato, 'ID_CN'],
      [lang.ordem,'ID_OC'],
      [lang.ticket, 'ID_UOCC'],
      [lang.data, 'DT_PRV'],
      [lang.nfe, 'LST_NFE'],
      [lang.nr_nfe,'NR_NFE'],
      [lang.operacao, 'TPOPRPRT', {}, 'f center-h'],
      [lang.origem, 'DS_ORG'],
      [lang.destino, 'DS_DST'],
      [lang.produto, 'DS_PRD'],
      [lang.local, 'ID_LFIS'],
      [lang.entrada, 'DT_OPR1'],
      [lang.saida, 'DT_OPR2'],
      [lang.tempo, 'TEMPO_OPR'],
      [lang.cpf_motorista, 'NR_CPFMTR'],
      [lang.nome_motorista, 'NM_MTR'],
      [lang.transportadora, 'NM_TRP'],
      [lang.imprimir, 'IMPRIMIR', {}, 'f center-h'],
      // [lang.danfe, 'DANFE', {}, 'f center-h'],
      // [lang.dacte, 'DACTE', {}, 'f center-h'],
      [lang.detalhe, 'DETALHE', {}, 'f center-h'],
      [<><icons.MdCloudDownload/>{lang.downloads}</>, 'downloads', {}, 'f g1'],
    ]
  }

  function get(){
    setMonitores([])
    setLoading(true)

    return App.api('monitor::consulta').then(_monitores => {
      
      let _setMonitores = _monitores.results.map(r => {
        r.filas = r.filas.map(f => ({...f,
          ID_VCL: r.recepcionado?.find(r => r.ID_FILA === f.ID_FILA && r.NR_ORDEMFILA === 1)?.ID_VCL||'',
          DT_EFILA: r.recepcionado?.find(r => r.ID_FILA === f.ID_FILA && r.NR_ORDEMFILA === 1)?.DT_EFILA||'',
          QT_TEMPO: r.recepcionado?.find(r => r.ID_FILA === f.ID_FILA && r.NR_ORDEMFILA === 1)?.QT_TEMPO||'',
          QT_AGENDADO: r.agendado?.filter(a => a.ID_FILA === f.ID_FILA).length,
          QT_PATIO: r.recepcionado?.filter(a => a.ID_FILA === f.ID_FILA).length,
          QT_OPERACAO: r.operacao?.filter(a => a.ID_FILA === f.ID_FILA).length,
        }))

        r.agendado = r.agendado.map(a=>({...a,
          downloads: <c.PortariaDownloadButtons portaria={a} />,
          DS_ORG: Array.isArray(a.DS_ORG) ? a.DS_ORG.map(d=><div>{d.NM_PES}</div>) : a.DS_ORG,
          DS_DST: Array.isArray(a.DS_DST) ? a.DS_DST.map(d=><div>{d.NM_PES}</div>) : a.DS_DST,
          _DS_LFIS: <><c.IconButton onClick={()=>setLocalFisicoModal(a.ID_UOCC)}><icons.BsPencilFill /></c.IconButton>{a.DS_LFIS}</>,
          DS_TPOPRPRT: a.DS_TPOPRPRT==='EXPEDICAO'?<icons.CargaIcon title={lang.carga} size={24} />:<icons.DescargaIcon title={lang.descarga} size={24} />,
          NR_TLFMTR: <span onClick={() => setMotorista({cpf: a.NR_CPFMTR, tel: a.NR_TLFMTR})} className='f nowrap center-v'>
                      <c.IconButton><icons.BsPencilFill /></c.IconButton> {f.formatTel(a.NR_TLFMTR)}
                    </span>,
          NR_CPFMTR: f.formatCpf(a.NR_CPFMTR),
          LST_ORDEM: !a.LST_ORDEM?<icons.BsQuestionCircleFill size={16} />
            :<a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('ordem|' + a.ID_UOCC)} target='_blank' rel="noopener noreferrer">
              <icons.BsCheck2Circle size={16} />
            </a>,
          ACTION: <button className="success" disabled={a.CD_STS === '4' && a.IN_AUTO === 'F'} onClick={()=>alterarSituacao(a, 1)}>{lang.recepcionar}</button>,
          IMPRIMIR: <c.IconButton title={lang.imprimir_ticket}
                      onClick={() => !!a.ID_COTA ? setPrintTicketModal(a.ID_UOCC) : setOpenTicketFracionado(a.ID_UOCC)}>
                      <icons.FaPrint />
                    </c.IconButton>,
          DACTE: !!a.LST_CTE &&
            <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('dacte|' + a.ID_UOCC)} title={lang.dacte} target='_blank' rel="noopener noreferrer">
              <c.IconButton><icons.FaPrint /></c.IconButton>
            </a>,
          DANFE: !!a.LST_NFE &&
            <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('danfe|' + a.ID_UOCC)} title={lang.danfe} target='_blank' rel="noopener noreferrer">
              <c.IconButton><icons.FaPrint /></c.IconButton>
            </a>,
          DETALHE: <c.IconButton onClick={()=>!!a.ID_COTA ? setDetalheModal(a.ID_UOCC) : setViewFracionadaDetails(a.ID_UOCC)}><icons.BsEye size={16} /></c.IconButton>,
          CN: a.ID_CN??'-----',
          ID_OC: a.ID_OC??'-----',
          _TICKET: <button onClick={()=>!!a.ID_COTA ? setDetalheModal(a.ID_UOCC) : setViewFracionadaDetails(a.ID_UOCC)}>{a.ID_UOCC}</button>,
          LST_NFE: a.LST_NFE??'-----'
        }))

        r.recepcionado = r.recepcionado.map((a,i)=>({...a,
          downloads: <c.PortariaDownloadButtons portaria={a} />,
          _DS_LFIS: <><c.IconButton onClick={()=>setLocalFisicoModal(a.ID_UOCC)}><icons.BsPencilFill /></c.IconButton>{a.DS_LFIS}</>,
                    DS_ORG: Array.isArray(a.DS_ORG) ? a.DS_ORG.map(d=><div>{d.NM_PES}</div>) : a.DS_ORG,
          DS_DST: Array.isArray(a.DS_DST) ? a.DS_DST.map(d=><div>{d.NM_PES}</div>) : a.DS_DST,
          TPOPRPRT: a.DS_TPOPRPRT==='EXPEDICAO'?<icons.CargaIcon title={lang.carga} size={24} />:<icons.DescargaIcon title={lang.descarga} size={24} />,
          TEMPO_FILA: <span className={a.TEMPO_FILA_COLOR}>{a.TEMPO_FILA}</span>,
          IMPRIMIR: <c.IconButton title={lang.imprimir_ticket}
                      onClick={() => !!a.ID_COTA ? setPrintTicketModal(a.ID_UOCC) : setOpenTicketFracionado(a.ID_UOCC)}>
                      <icons.FaPrint />
                    </c.IconButton>,
          DACTE: !!a.LST_CTE &&
            <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('dacte|' + a.ID_UOCC)} title={lang.dacte} target='_blank' rel="noopener noreferrer">
              <c.IconButton><icons.FaPrint /></c.IconButton>
            </a>,
          DANFE: !!a.LST_NFE &&
            <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('danfe|' + a.ID_UOCC)} title={lang.danfe} target='_blank' rel="noopener noreferrer">
              <c.IconButton><icons.FaPrint /></c.IconButton>
            </a>,
          WHATSAPP: <c.IconButton onClick={()=>enviarMensagem(a, 1)} title={'WhatsApp'}><icons.MdOutlineWhatsapp /></c.IconButton>,
          DETALHE: <c.IconButton onClick={()=>!!a.ID_COTA ? setDetalheModal(a.ID_UOCC) : setViewFracionadaDetails(a.ID_UOCC)}><icons.BsEye size={16} /></c.IconButton>,
          NR_CPFMTR: f.formatCpf(a.NR_CPFMTR),
          IN_CLS: a.IN_CLS==='T' ? <icons.BsCheck2Circle size={16} /> : <icons.MdCancel size={16} />,
          LST_ORDEM: !a.LST_ORDEM?<icons.BsQuestionCircleFill size={16} />
            :<a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('ordem|' + a.ID_UOCC)} target='_blank' rel="noopener noreferrer">
              <icons.BsCheck2Circle size={16} />
            </a>,
          ACTION: <button className="success" disabled={a.IN_CHK==='F'} onClick={() => alterarSituacao(a, 3)}>{lang.liberar_operacao}</button>,
          CHK: a.IN_CHK === 'T' 
                ? <icons.BsCheck2Circle size={16} />
                : <c.IconButton title={lang.checar_veiculo} 
                    onClick={()=>App.confirm(lang.deseja_checar.replace('%d', a.ID_VCL).replace('%e', lang.entrar_operacao),
                      () => App.api('monitor::checarPortaria',{id_uocc: a.ID_UOCC}).then(r => {
                        if(r.status){ get() }
                        return r.status
                      })
                    )}><icons.MdCancel /></c.IconButton>,
          LST_NFE: a.LST_NFE??'-----',
          ID_OC: a.ID_OC??'-----'
        }))

        r.operacao = r.operacao.map(a=>({...a,
          downloads: <c.PortariaDownloadButtons portaria={a} />,
          TPOPRPRT: a.DS_TPOPRPRT==='EXPEDICAO'?<icons.CargaIcon title={lang.carga} size={24} />:<icons.DescargaIcon title={lang.descarga} size={24} />,
                    DS_ORG: Array.isArray(a.DS_ORG) ? a.DS_ORG.map(d=><div>{d.NM_PES}</div>) : a.DS_ORG,
          DS_DST: Array.isArray(a.DS_DST) ? a.DS_DST.map(d=><div>{d.NM_PES}</div>) : a.DS_DST,
          NR_CPFMTR: f.formatCpf(a.NR_CPFMTR),
          ACTION: 
            <button className="success" disabled={a.IN_CHK==='F'}
              onClick={()=>
                (a.DS_TPOPRPRT==='EXPEDICAO' && App.diretivas.ObrigatorioNFeVelog === '1')
                ? ( !a.LST_NFE ? setOpenDfe(a) : alterarSituacao(a, -1) )
                : alterarSituacao(a, -1)
              } >{lang.liberar_saida}
            </button>,
          IMPRIMIR: <c.IconButton title={lang.imprimir_ticket}
                      onClick={() => !!a.ID_COTA ? setPrintTicketModal(a.ID_UOCC) : setOpenTicketFracionado(a.ID_UOCC)}>
                      <icons.FaPrint />
                    </c.IconButton>,
          DACTE: !!a.LST_CTE &&
            <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('dacte|' + a.ID_UOCC)} title={lang.dacte} target='_blank' rel="noopener noreferrer">
              <c.IconButton><icons.FaPrint /></c.IconButton>
            </a>,
          DANFE: !!a.LST_NFE &&
            <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('danfe|' + a.ID_UOCC)} title={lang.danfe} target='_blank' rel="noopener noreferrer">
              <c.IconButton><icons.FaPrint /></c.IconButton>
            </a>,        
          WHATSAPP: <c.IconButton onClick={()=>enviarMensagem(a, 3)}><icons.MdOutlineWhatsapp /></c.IconButton>,
          IN_CHK: a.IN_CHK === 'T' ? <icons.BsCheck2Circle size={16} />
            : <c.IconButton title={lang.checar_veiculo} 
                onClick={()=>App.confirm(lang.deseja_checar.replace('%d', a.ID_VCL),
                  ()=>App.api('monitor::checarPortaria',{id_uocc: a.ID_UOCC}).then(r=>{r.status&&App.toast.success(lang.veiculo_checado);get(); return r})
                )}><icons.MdCancel /></c.IconButton>,
          DETALHE: <c.IconButton onClick={()=>!!a.ID_COTA ? setDetalheModal(a.ID_UOCC) : setViewFracionadaDetails(a.ID_UOCC)}><icons.BsEye size={16} /></c.IconButton>,
          LST_NFE: a.LST_NFE??'-----',
          ID_OC: a.ID_OC??'-----'
        }))

        r.finalizado = r.finalizado.map(a=>({...a,
          downloads: <c.PortariaDownloadButtons portaria={a} />,
          TPOPRPRT: a.DS_TPOPRPRT==='EXPEDICAO'?<icons.CargaIcon title={lang.carga} size={24} />:<icons.DescargaIcon title={lang.descarga} size={24} />,
                    DS_ORG: Array.isArray(a.DS_ORG) ? a.DS_ORG.map(d=><div>{d.NM_PES}</div>) : a.DS_ORG,
          DS_DST: Array.isArray(a.DS_DST) ? a.DS_DST.map(d=><div>{d.NM_PES}</div>) : a.DS_DST,
          NR_CPFMTR: f.formatCpf(a.NR_CPFMTR),
          IMPRIMIR: <c.IconButton title={lang.imprimir_ticket}
                      onClick={() => !!a.ID_COTA ? setPrintTicketModal(a.ID_UOCC) : setOpenTicketFracionado(a.ID_UOCC)}>
                      <icons.FaPrint />
                    </c.IconButton>,
          DACTE: !!a.LST_CTE &&
            <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('dacte|' + a.ID_UOCC)} title={lang.dacte} target='_blank' rel="noopener noreferrer">
              <c.IconButton><icons.FaPrint /></c.IconButton>
            </a>,
          DANFE: !!a.LST_NFE &&
            <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('danfe|' + a.ID_UOCC)} title={lang.danfe} target='_blank' rel="noopener noreferrer">
              <c.IconButton><icons.FaPrint /></c.IconButton>
            </a>,        
          DETALHE: <c.IconButton onClick={()=>!!a.ID_COTA ? setDetalheModal(a.ID_UOCC) : setViewFracionadaDetails(a.ID_UOCC)}><icons.BsEye size={16} /></c.IconButton>,
          LST_NFE: a.LST_NFE??'-----',
          ID_OC: a.ID_OC??'-----'
        }))

        return r
      })

      setMonitores(_setMonitores)
    }).finally(()=>setLoading(false))
  }

  function enviarMensagem(portaria, situacao){
    App.confirm(lang.deseja_reenviar.replace('%d', portaria.ID_VCL),
      ()=>App.api('monitor::reenviarWhatsapp', {id_uocc: portaria.ID_UOCC, tp_env: situacao})
            .then(r=>{r.status&&App.toast.success(lang.mensagem_reenviada); return r})
    )
  }

  function alterarSituacao(portaria, situacao){
    App.confirm(
      (portaria.CD_STS === '4' || portaria.CD_STS === '5' ? lang.deseja_recepcionar 
        : portaria.CD_STS === '1' ? lang.deseja_liberar_operacao 
        : lang.deseja_liberar_saida).replace('%d', portaria.ID_VCL),
      ()=>App.api('monitor::alteraSituacaoPortaria',{idPrt: portaria.ID_UOCC, sts: situacao}).then(r=>{get(); return r})
    )
  }

  useEffect(() => {

    console.log(App.diretivas)

  },[])

  useEffect(()=>{
    setMonitor( monitores[selectedMonitor] )
  }, [monitores, selectedMonitor])

  useEffect(()=>{ get()
    setReloader(()=>get)
  }, [])

  return(<>
    {monitores.length > 1 &&
      <div className="react-tabs f g2">
        <ul className="react-tabs__tab-list">
          {monitores.map((monitor,id) => 
            monitor.agendado.length + monitor.recepcionado.length + monitor.operacao.length + monitor.finalizado.length > 0 ?(<li key={id} onClick={()=>setSelectedMonitor(id)}
              className={["react-tabs__tab f g1 center-v", (id===selectedMonitor?'react-tabs__tab--selected':'')].join(' ')}
            >
              {monitor.uorg.NM_UORG} 
              <span className='destaque info'>
                {monitor.agendado.length + monitor.recepcionado.length + monitor.operacao.length + monitor.finalizado.length}
              </span>
            </li>) : <></>
          )}
        </ul>
      </div>
    }

    <c.Frame title={"Monitor"} loading={loading} 
      actions={{
        filter: {onClick:()=>setShowFilters(!showFilters), value: showFilters}
      }}
      control={<c.Input placeholder={lang.busca} value={busca} onChange={e=>setBusca(e.target.value)} clearable />}
      
    >
      <c.Tabs selectedIndex={selectedTab} onSelect={i=>{setSelectedTab(i);sessionStorage.setItem('monitorTab', i)}}>
        <div className="f f-column g1">
          <c.TabList>
            <c.Tab>{lang.fila}</c.Tab>
            <c.Tab>{lang.agendado} <sup><b>
              {monitor?.agendado?.filter(t=>t.ID_FILA===(monitor.fila??t.ID_FILA))?.length??0}
            </b></sup></c.Tab>
            <c.Tab>{lang.recepcionado} <sup><b>
              {monitor?.recepcionado?.filter(t=>t.ID_FILA===(monitor.fila??t.ID_FILA))?.length??0}
            </b></sup></c.Tab>
            <c.Tab>{lang.em_operacao} <sup><b>
              {monitor?.operacao?.filter(t=>t.ID_FILA===(monitor.fila??t.ID_FILA))?.length??0}
            </b></sup></c.Tab>
            <c.Tab>{lang.finalizado_recente} <sup><b>
              {monitor?.finalizado?.filter(t=>t.ID_FILA===(monitor.fila??t.ID_FILA))?.length??0}
            </b></sup></c.Tab>
          </c.TabList>

          <c.Select value={monitor?.fila} label={lang.fila} clearable
            onChange={v => {
              monitor.fila = v?.value||null
              setMonitores([...monitores])
            }}
            options={monitor?.filas?.map(f=>({value: f.ID_FILA, label: f.ID_FILA + ' - ' + f.NM_FILA}))}
          />
        </div>

        {/* Fila */}
        <c.TabPanel>
          <c.Table columns={columns.fila} data={monitor?.filas} search={busca}
            selectedRow={row => row.ID_FILA === monitor?.fila} showFilters={showFilters}
            rowClick={row => {
              monitor.fila = row.ID_FILA||null
              setMonitores([...monitores])
            }} forceFullHeight={290}
          />
        </c.TabPanel>

        {/* Agendado */}
        <c.TabPanel style={{overflowX: 'auto'}}>
          <c.Table columns={columns.agendado} perPage={10} forceFullHeight={290} search={busca}
            data={monitor?.agendado?.filter(t=>t.ID_FILA===(monitor?.fila??t.ID_FILA))} showFilters={showFilters}
          />
        </c.TabPanel>

        {/* Recepcionado */}
        <c.TabPanel style={{overflowX: 'auto'}}>
          <c.Table columns={columns.recepcionado} perPage={10} forceFullHeight={290} search={busca}
            data={monitor?.recepcionado?.filter(t=>t.ID_FILA===(monitor?.fila??t.ID_FILA))} showFilters={showFilters}
            
          />
        </c.TabPanel>

        {/* Em operação */}
        <c.TabPanel style={{overflowX: 'auto'}}>
          <c.Table columns={columns.operacao} perPage={10} forceFullHeight={290} search={busca}
            data={monitor?.operacao?.filter(t=>t.ID_FILA===(monitor?.fila??t.ID_FILA))} showFilters={showFilters}
          />
        </c.TabPanel>

        {/* Finalizados */}
        <c.TabPanel style={{overflowX: 'auto'}}>
          <c.Table columns={columns.finalizado} perPage={10} forceFullHeight={290} search={busca}
            data={monitor?.finalizado?.filter(t=>t.ID_FILA===(monitor?.fila??t.ID_FILA))} showFilters={showFilters}
          />
        </c.TabPanel>
      </c.Tabs>
    </c.Frame>


    {localFisicoModal && 
      <LocalFisicoModal idPrt={localFisicoModal} onClose={()=>{get();setLocalFisicoModal(null)}} 
        onFinish={()=>get()} />
    }

    {motorista && 
      <TelMotorista cpf={motorista.cpf} telefone={motorista.tel} 
        onClose={()=>setMotorista(null)} onFinish={()=>get()} />
    }

    {printTicketModal &&
      <p.PrintTicketModal idPrt={printTicketModal} onClose={()=>setPrintTicketModal(false)} />
    }

    {!!openTicketFracionado && 
      <p.PrintTicketFracionadoModal prtId={openTicketFracionado} onClose={()=>setOpenTicketFracionado(null)} />
    }


    {detalheModal &&
      <DetalheModal idPrt={detalheModal} onClose={()=>setDetalheModal(null)}/>
    }

    {viewFracionadaDetails && 
      <p.OrdemCarregamentoDetailsModal prtId={viewFracionadaDetails} onClose={()=>setViewFracionadaDetails(null)} />
    }

    {!!openDfe &&
      <p.DfeModal tela="agendado" portaria={openDfe} mod={55} onClose={()=>{setOpenDfe(null)}} 
        onFinish={()=>{
          return App.api('portarias::alteraSituacao', {
            id_uocc: openDfe.ID_UOCC, cd_sts: '-1',
          }).then(r=>{
            if( r.status ) get()
            return r.status
          })
        }} />
    }
  </>)
}


function DetalheModal({idPrt, onClose}){
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.monitor}
  const [loading, setLoading] = useState(false)
  const [p, setP] = useState({}) // Portaria

  useEffect(()=>{ setLoading(true)
    App.api('portarias::consultaPorId',{id: idPrt})
      .then(r=>{ setP(r.results); setLoading(false)})
  }, [])

  return(
    <c.Modal onClose={onClose} loading={loading} cancelText={lang.fechar} onFinish={false}
      title={!!p.ID_UOCC&&lang.agendamento_de_op_tiket?.replace('%d', p.DS_OPRPRT).replace('%e', p.ID_TICKET)}>
      <div className="f f-wrap g2" style={{width: 1000}} >
        <c.Input label={lang.data_turno} value={p.DT_PRV + ' ' + p.DS_TRN} disabled={true} className='f3' />
        <c.Input label={lang.situacao}   value={p.DS_STS}   disabled={true} className='f3' />
        <c.Input label={lang.cliente}    value={p.NM_PRC}   disabled={true} className='f6' />
        <c.Input label={lang.origemgem}     value={p.NM_ORI}   disabled={true} className='f6' />
        <c.Input label={lang.destinotino}    value={p.NM_DST}   disabled={true} className='f6' />
        <c.Input label={lang.produto}    value={p.NM_PRO}   disabled={true} className='f4' />
        <c.Input label={lang.veiculo}    value={p.DS_TPVCL} disabled={true} className='f4' />
        <c.Input label={lang.placa}      value={p.LST_VCL}  disabled={true} className='f4' />
        <c.Input label={lang.transportadora} value={p.NR_CNPJTRP + ' ' + p.NM_TRP} disabled={true} className='f12' />
        <c.Input label={lang.motorista} value={p.NR_CPFMTR + ' ' + p.NM_MTR} disabled={true} className='f6' />
        <c.Input label={lang.cnh_categ_vencto} value={p.NR_CNHMTR + ' ' + p.DS_CTGCNHMTR + ' ' + p.DT_VCTCNHMTR} disabled={true} className='f3' />
        <c.Input label={lang.telefone}   value={p.NR_TLFMTR} disabled={true} className='f3' />
        <c.Input label={lang.data_agendado} value={p.DT_AGD} disabled={true} className='f2' />
        <c.Input label={lang.tempo_agendado} value={p.TEMPO_AGD} disabled={true} className='f2' />
        <c.Input label={lang.data_transito}  value={p.DT_TRS} disabled={true} className='f2' />
        <c.Input label={lang.tempo_transito} value={p.TEMPO_TRS} disabled={true} className='f2' />
        <c.Input label={lang.data_fila}      value={p.DT_EFILA} disabled={true} className='f2' />
        <c.Input label={lang.tempo_fila}     value={p.TEMPO_FILA} disabled={true} className='f2' />
        <c.Input label={lang.data_operacao}  value={p.DT_OPR1} disabled={true} className='f2' />
        <c.Input label={lang.tempo_operacao} value={p.TEMPO_OPR} disabled={true} className='f2' />
        <c.Input label={lang.data_saida}     value={p.DT_OPR2} disabled={true} className='f2' />
        <c.Input label={lang.tempo_fila_operacao} value={p.TEMPO_PATIO} disabled={true} className='f2' />
        <c.Input label={lang.tempo_consumo}  value={p.TEMPO_CONSUMO} disabled={true} className='f2' />
      </div>

      <c.Divider />

      <c.Table isSub={true} columns={[
        [lang.data_envio, 'DT_ENV'],
        [lang.telefone, 'NR_TLF'],
        [lang.mensagem, 'DS_MSG'],
      ]} data={p.LST_MSG} style={{width: 1000}} />

      <div className="p1">{lang.visualizado_em}{f.formatDate()}</div>
    </c.Modal>
  )
}


function LocalFisicoModal({idPrt, onClose}){
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.monitor}
  const [options, setOptions] = useState([])
  const [idLfis, setIdLfis] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    setLoading(true)
    App.api('portarias::listaLocalFisico', {idPrt})
      .then(r=>{setOptions(r.results); setLoading(false)})
  }, [])

  return(
    <c.Modal onClose={onClose} loading={loading}
      onFinish={()=>{
        setLoading(true)
        return App.api('portarias::gravaLocalFisico',{idPrt, idLfis}).then(r=>{
          setLoading(false)
          return r.status
        })
      }}
      validate={!!idLfis} successMsg={lang.alterado_sucesso}
    >
      <c.Select label={lang.local} options={options} value={idLfis} onChange={v=>setIdLfis(v.value)}/>
    </c.Modal>
  )
}


function TelMotorista({cpf, telefone, onClose, onFinish}){
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.monitor}

  const [tel, setTel] = useState( f.formatTel(telefone) )

  return(
    <c.Modal onClose={onClose} title="Alterar telefone"
      onFinish={()=>App.api('parceiros::alteraTelefone',{cpfcnpj: cpf, tel}).then(()=>{onFinish(); return true})}
      validate={tel.replace(/\D/g, '').length===13} successMsg={lang.alterado_sucesso}
    >
      <c.Input label={lang.telefone} value={tel} onChange={e=>setTel( f.formatTel(e.target.value) )} />
    </c.Modal>
  )
}