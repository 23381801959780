import { useContext, useEffect, useState } from 'react'
import { AppContext, log } from '../App'
import * as c from '../c'

export function PortariaDownloadButtons({portaria}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador}

  const [previewDocumentModal, setPreviewDocumentModal] = useState({})

  function TbBtn({status, tipo, p, color='primary'}){
    return (
      <button className={[color,'nowrap'].join(' ')} 
        style={{fontSize: 12, padding: '2px 5px', maxWidth: 'initial', flex: 1}} 
        disabled={!status}
        title={!status ? 'Não anexada' : ''}
        onClick={() => {
          console.log(p)
          if( !!status ) {
            if( tipo === 'nota_de_venda' || tipo === 'mdfe' ){
              window.open(App.baseURL+'download/?k=' + btoa(tipo + '|' + p.ID_UOCC), '_blank')
            }else{
              setPreviewDocumentModal({
                tipo, 
                idPrt: p.ID_UOCC
              })    
            }
          }
        }}
      >
        {lang[tipo]}
      </button>
    )
  }

  return(<>
    <div className='f f-wrap g1' style={{width: 350}}>
      {/* <TbBtn status={!!portaria.nfe_status||!!portaria.LST_NFE} tipo='nfe' color={['primary','success','warning','cancel'][portaria.nfe_status]} p={portaria} /> */}
      <TbBtn status={!!portaria.IN_NFE_XML} tipo='nfe' color={['primary','success','warning','cancel'][portaria.nfe_status]} p={portaria} />
      <TbBtn status={!!portaria.cte_status||!!portaria.LST_CTE} tipo='cte' color={['primary','success','warning','cancel'][portaria.cte_status]} p={portaria} />
      <TbBtn status={!!portaria.ctemm_status||!!portaria.LST_CTEMM} tipo='cte-mm' color={['primary','success','warning','cancel'][portaria.ctemm_status]} p={portaria} />
      <TbBtn status={!!portaria.mdfe_status} tipo='mdfe' color={['primary','success','warning','cancel'][portaria.mdfe_status]} p={portaria} />
      <TbBtn status={!!portaria.LST_ORDEM} tipo='ordem' color={'primary'} p={portaria} />
      <TbBtn status={!!portaria.LST_RF} tipo='rf' color={'primary'} p={portaria} />
      <TbBtn status={!!portaria.LST_TICKET_PESAGEM} tipo='ticket_pesagem' color={'primary'} p={portaria} />
      <TbBtn status={!!portaria.LST_NOTA_DE_VENDA} tipo='nota_de_venda' color={'primary'} p={portaria} />
      <TbBtn status={!!portaria.CNH_DOC_ID} tipo='cnh' color={'primary'} p={portaria} />
      <TbBtn status={!!portaria.RENAVAM_DOC_ID} tipo='renavam' color={'primary'} p={portaria} />
    </div>

    {!!previewDocumentModal.tipo 
      && <PreviewDocumentModal previewDocumentModal={previewDocumentModal} onClose={()=>setPreviewDocumentModal({})} />
    }
  </>)
}


function PreviewDocumentModal({previewDocumentModal, onClose}){
  const App = useContext(AppContext),
        icons = App.icons

  const {tipo, idPrt} = previewDocumentModal

  const [data, setData] = useState({}),
        [nfes, setNfes] = useState([]),
        [originalLink, setOriginalLink] = useState(''),
        [preview, setPreview] = useState(''),
        [nfe_selected, setNfe_selected] = useState('')

  function set_dco(){
    let _originalLink = App.baseURL+'download/?k=' + btoa(tipo + '|' + idPrt + '|' + nfe_selected)

    setOriginalLink( _originalLink )
    setPreview( tipo === 'nfe' || tipo === 'cte' || tipo === 'cte-mm'
                    ? App.baseURL + 'download/?k=' + btoa('da'+tipo + '|' + idPrt + '|' + nfe_selected)
                    : _originalLink )
  }

  useEffect(() => {
    App.api('portarias::getExtras', {
      ID_UOCCs: [idPrt]
    }).then(r => setData(r.results))
  }, [])

  useEffect(() => { 
    if( ['nfe','cte'].includes(tipo) && !!data.dcos ){ 
      let _nfes = data.dcos[idPrt].filter(dco => parseInt(dco.TP_DF) === (tipo==='nfe'?55:tipo==='cte'?57:55))
      setNfes( _nfes )
      setNfe_selected( _nfes[0]?.ID_UOCC )
    }else{
      set_dco()
    }
  }, [data])

  useEffect(() => set_dco(), [nfe_selected])

  return(
    <c.Modal largeWidth
      extraControls={<>
        <a href={preview} download className='button'><icons.MdCloudDownload/> Download</a>
        {(['nfe','cte','cte-mm'].includes(tipo)) &&
          <a href={originalLink} download className='button'><icons.MdCloudDownload/> XML</a>
        }
      </>}
      title={'Document Preview - ' + tipo.toUpperCase()} onClose={onClose}>

      {['nfe','cte','cte-mm'].includes(tipo) && nfes.length > 1 &&
        <div className='f g1 w100'>
          {nfes.map((nfe, nfe_id) => 
            <button key={nfe_id} className={nfe_selected === nfe.ID_UOCC ? 'info' : ''} onClick={() => setNfe_selected(nfe.ID_UOCC)}>{nfe.NR_DCO}</button>)
          }
        </div>
      }{log(nfes)}
      <object data={preview + '&inline=true'} type="application/pdf" width="100%" height={600}>
        <p>Não foi possível exibir o arquivo. Faça o <a href={preview}>Download</a>.</p>
      </object>

    </c.Modal>
  )
}