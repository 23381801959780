import { Fragment, useContext, useState, useEffect, useRef } from 'react'
import { AppContext, log } from '../../App'
import CryptoJS from 'crypto-js'

import * as f from '../../f'
import * as c from '../'
// import { BsFillBucketFill } from 'react-icons/bs'


export default function Table({ columns, data=[], selectedRow=()=>false, rowClick=null, style={}, hasSub=null, search='', 
  showFilters=false, loading=false, withPagination=true, filter={}, perPage=null, disableLine=null, rowEnabledField=null,
  noFooter = null, noHeader = null, subOpenedDefault=null, emptyMsg=null, forceFullHeight=null, isSub=false, 
  selectColumns = true, allowOrder = true
}) {
  const App = useContext(AppContext),
        icons = App.icons,
        lang = App.lang.global,
        columnsRef = useRef(null)

  const [subs, setSubs] = useState(Array(data.length).fill(subOpenedDefault)),
        [filters, setFilters] = useState(filter),
        [dataShow, setDataShow] = useState([]),
        [_perPage, set_PerPage] = useState(App.prefs?.tableNumber??25),
        [page, setPage] = useState(0),
        [totalPages, setTotalPages] = useState(0),
        [orderBy, setOrderBy] = useState({asc: true, field: null}),
        [columnsShow, setColumnsShow] = useState(columns),
        [columnsEditing, setColumnsEditing] = useState(null),
        [columnsData, setColumnsData] = useState([]),
        //[columnsReload, setColumnsReload] = useState(false),
        [filtered, setFiltered] = useState([]),
        [columnsReload, setColumnsReload] = useState([]),
        [loadingTable, setLoadingTable] = useState(!isSub)

  const perPageOptions = [10, 25, 50, 100, 250]

  function getColumns(_columns){
    const tableString = _columns?.map(c => c.field).join('');
    const tableId = CryptoJS.MD5(JSON.stringify(tableString)).toString();

    !noHeader && !isSub && App.api("users::getColunas", {
      ID_TABLE: tableId}).then((r) => { 
        setLoadingTable(false)
        let _ColumnsData = r.results?.length > 0 ? r.results : _columns?.map(c=> ({field: c.field, IN_INSERT: "T" }))
        setColumnsData(_columns.map(c=> ({...c, IN_INSERT: _ColumnsData.length > 0 ? (_ColumnsData.find(cd=>cd.field===c.field) ? "T" : "F") : "T" })) )
        setColumnsReload(_ColumnsData.length > 0 ? _columns.filter(c=>_ColumnsData.find(cd=>cd.field===c.field)) : _columns)
      }) 
  }

  useEffect(() => {
    getColumns(columns.map(c => {
      const dataField = c[4]??c[1] // Campo usado para ordenação com o valor que vem do banco de dados
      // const _typeof = f.isDate(data?.[0]?.[dataField]) ? 'date' : !isNaN(data?.[0]?.[dataField]) ? 'number' : typeof data?.[0]?.[dataField]
      const _typeof = f.checkArrayType(data,dataField)
      return {
        label: c[0], 
        field: c[1], 
        style: c[2], 
        class: c[3], 
        dataField, 
        typeof: _typeof,
        sortable: ['number','string','date'].indexOf(_typeof)>=0 && (c[3]?.sortable??true),
        //values: data?.map(d=>d?.[c[1]]).filter((v,i,arr)=>arr.indexOf(v)===i)
      }
    })      )
  }, [])

  useEffect(()=>{
    const _columns = columns.map(c=>{
      const dataField = c[4]??c[1] // Campo usado para ordenação com o valor que vem do banco de dados
      // const _typeof = f.isDate(data?.[0]?.[dataField]) ? 'date' : !isNaN(data?.[0]?.[dataField]) ? 'number' : typeof data?.[0]?.[dataField]
      const _typeof = f.checkArrayType(data,dataField)
      return {
        label: c[0], 
        field: c[1], 
        style: c[2], 
        class: c[3], 
        dataField, 
        typeof: _typeof,
        sortable: ['number','string','date'].indexOf(_typeof)>=0 && (c[3]?.sortable??true),
        //values: data?.map(d=>d?.[c[1]]).filter((v,i,arr)=>arr.indexOf(v)===i)
      }
    })      

    setColumnsShow(!isSub ? columnsReload : _columns)

    const _filtered = data
      // Faz trim nas strings
      ?.map(d => Object.keys(d).reduce((dcc, k)=>{
        dcc[k] = typeof d[k] === 'string' ? d[k].trim() : d[k]
        return dcc
      }, {}))
      // Filtros específicos
      ?.filter(d => !showFilters
        ||_columns.every(c =>
            !c.sortable
            || d[c.dataField] === null
            || String(d[c.dataField])?.toUpperCase().includes( (filters?.[c.field]??'').toUpperCase() )
          )
        // || Object.keys(d).every(k =>
        //   !_columns.find(c => c.field === k)?.sortable 
        //   || d[k] === null 
        //   || d[k]?.toUpperCase().includes( (filters?.[k]??'').toUpperCase() )
        // )
      )
      // Filtro do campo de busca geral
      ?.filter(d=>search===''||Object.keys(d).some(k=> 
        ['string','number'].indexOf(typeof d[k])>=0 
          && search?.toUpperCase().split(' ').every(si=>String(d[k])?.toUpperCase().includes(si))))
      ?.sort((a, b) => {
        return allowOrder && !!orderBy.field
        ? ( orderBy?.typeof === 'number'
          ? orderBy.asc ? a[orderBy.dataField]-b[orderBy.dataField] : b[orderBy.dataField]-a[orderBy.dataField]
          : orderBy?.typeof === 'string'
            ? orderBy.asc ? a[orderBy.dataField]?.localeCompare(b[orderBy.dataField]) : b[orderBy.dataField]?.localeCompare(a[orderBy.dataField])
            : orderBy?.typeof === 'date'
              ? orderBy.asc ? f.toDateType(a[orderBy.dataField])-f.toDateType(b[orderBy.dataField]) : f.toDateType(b[orderBy.dataField])-f.toDateType(a[orderBy.dataField])
              : 0
        ):0
      })


    setFiltered( _filtered )
    const tp = Math.ceil( (_filtered?.length??0)/_perPage)-1
    setTotalPages( tp < 0 ? 0 : tp )
    setPage( Math.min( page, tp < 0 ? 0 : tp) )
  }, [data, columns, search, showFilters, filters, _perPage, orderBy, columnsReload])


  useEffect(()=>{
    setDataShow( withPagination ? filtered?.slice(page*_perPage, (page+1)*_perPage) : filtered)
  }, [withPagination, page, filtered, _perPage, columnsEditing])


  useEffect(() => {
    function handleClickOutside(event) {
      if (columnsRef.current && !columnsRef.current.contains(event.target)) {
        const tableString = columnsData?.map(c => c.field).join('');
        const tableId = CryptoJS.MD5(JSON.stringify(tableString)).toString();
        setColumnsEditing(false);
        setLoadingTable(true)
        App.api("users::saveColunas", {ID_TABLE: tableId,
          prefs: columnsData.map(c=> ({field: c.IN_INSERT === "T" ? c.field : '' }))}).then(r => {
            getColumns(columnsData)
            //setColumnsReload(!columnsReload)
          })
          
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [columnsRef, columnsEditing])


  return (<>
    {
    loadingTable ? <App.LoadingComponent /> : 
    !!columnsShow?.length ? 
      <div className='table-default f f-column g2 w100 '  >
        <div style={{overflowX: 'auto', ...forceFullHeight&&{height: 'calc(100vh - '+forceFullHeight+'px)'}}}>
          <table cellSpacing={0} style={style}>
            <thead>
              {!noHeader && 
                <tr>
                  {!!hasSub && <th key='forsub' width='36' className='force-fit'></th>}
                  {columnsShow.map((c, ci)=>
                    <th key={ci} className={c.sortable?'sortable':''}
                      onClick={() => allowOrder && c.sortable && setOrderBy({...c, asc: orderBy.field===c.field?!orderBy.asc:orderBy.asc})}
                    >
                      <div style={c.style} className={c.class}>{c.label} {/* Precisa dessa DIV pq o TH fica distorcido com flex */}
                        {allowOrder && orderBy.field === c.field
                          && <span className='table-order-buttons'>
                              <icons.MdArrowDropDown size={24} className={orderBy.asc&&'selected'} />
                              <icons.MdArrowDropUp size={24} className={!orderBy.asc&&'selected'} />
                            </span>
                        }
                      </div>
                    </th>
                  )}
                </tr>
              }
              {!!showFilters && <tr className='table-filters'>
                {!!hasSub && <th key='forsub'></th>}
                {columnsShow.map((col, ci)=>
                  <th key={ci}>
                    {col.sortable &&
                      <c.Input clearable value={filters[col.field]} onChange={e=>
                        setFilters({...filters, [col.field]: e.target.value})
                      } placeholder={col.label} />
                    }
                  </th>
                )}
              </tr>}
            </thead>

            <tbody>
              {loading ? <tr><td colSpan={columnsShow.length + (!!hasSub?1:0)}><App.LoadingComponent /></td></tr> : <>
                {dataShow?.map((r, ri) => <Fragment key={ri}>
                  <tr className={[
                      selectedRow(r)?'selected':'', 
                      rowClick?'clickable':'',
                      disableLine&&r?.[disableLine]?'disabled-line':'',
                      !!rowEnabledField && r?.[rowEnabledField]===false ? 'disabled' : ''
                    ].join(' ')}
                    onClick={()=>rowClick&&rowClick(r)}
                  >
                    {!!hasSub && 
                      <td key={ri+'forsub'} width='36'>
                        {!(!!rowEnabledField && r?.[rowEnabledField]===false) && r?.[hasSub] &&
                          <c.IconButton onClick={()=>{subs[ri]=!subs[ri]; setSubs([...subs])}}>
                            {!!subs[ri] ? <icons.MdExpandLess size={16} /> : <icons.MdExpandMore size={16} />}
                          </c.IconButton>
                        }
                      </td>}
                    {columnsShow.map((c, ci)=>
                      <td key={ci}>
                        <div style={c.style} className={[c.class].join(' ')}>{r?.[c?.field]}</div>
                      </td>)}
                  </tr>

                  {!!hasSub && subs[ri] && !(!!rowEnabledField && r?.[rowEnabledField]===false) &&
                    <tr className='table-sub' key={ri+'sub'}>
                      <td colSpan={columnsShow.length+1}>{r?.[hasSub]}</td>
                    </tr>}
                </Fragment>)}
                {!dataShow?.length &&
                  <tr>
                    <td colSpan={columnsShow.length + (!!hasSub?1:0)}>
                      <div className='f1 center-h bold p1'>
                        {emptyMsg??App.lang.global.nada_por_aqui}
                      </div>
                    </td>
                  </tr>}
              </>}
            </tbody>
          </table>
        </div>

        { !noHeader && !noFooter &&
          <div className='table-footer'>
            { !isSub &&
              <div className={'dropdown-menu'} >
                <c.IconButton disabled={!selectColumns} onClick= {(e) => {if(selectColumns){(setColumnsEditing(!columnsEditing))}}}><icons.MdEditNote /></c.IconButton>
                {!!columnsEditing && (<ul ref={columnsRef} style={{bottom: 40}}>
                  {columnsData.map((col, ci)=>
                    !!col.label && !("ACTIONS").includes(col.field.toUpperCase()) && 
                    <li key={ci} style={{cursor: 'auto'}}>
                      <c.Checkbox checked={columnsData[ci].IN_INSERT === "T"}
                        label={col?.label}
                        className='w100'
                        onChange={(e) => {
                          const newColumnsData = [...columnsData]
                          newColumnsData[ci].IN_INSERT = e.target.checked ? "T" : "F"
                          setColumnsData(newColumnsData)
                        }}
                      />
                    </li>
                  )}
                </ul>)}
              </div>
            }

            {!!withPagination && (filtered.length > perPageOptions[0]) && !!totalPages ?
              <div className='pagination f center g2'>
                <c.IconButton size={32} icon={icons.MdFirstPage} onClick={()=>setPage(0)} disabled={page===0} />
                <c.IconButton size={32} icon={icons.MdNavigateBefore} onClick={()=>setPage(page-1)} disabled={page===0} />

                {lang.pagina}
                <select onChange={e=>{setPage(Number(e.target.value))}} value={page}>
                  {Array.from({length: totalPages+1}).map((v,i)=><option key={i} value={i}>{i+1}</option>)}
                </select> {lang.de + ' ' + (totalPages+1)}

                <c.IconButton size={32} icon={icons.MdNavigateNext} onClick={()=>setPage(page+1)} disabled={page===totalPages} />
                <c.IconButton size={32} icon={icons.MdLastPage} onClick={()=>setPage( totalPages )} disabled={page===totalPages} />
              </div>
            : <div></div>}

            {!!withPagination && (filtered.length > perPageOptions[0]) && filtered.length > perPageOptions[0] &&
              <div className='per-page f g2 center-v'>{lang.itens_por_pagina}
                <select value={_perPage} onChange={e=>{set_PerPage(Number(e.target.value), App.setPrefs({ tableNumber: e.target.value}));setPage(0)}}>
                  {perPageOptions.map((p,i)=><option key={i} value={p}>{p}</option>)}
                </select>
              </div>
            }
          </div>
        }
      </div> 
      : <App.LoadingComponent />
      // <div className='f1 center-h italic bold p1'>{App.lang.global.nada_por_aqui}</div>
    }
  </>);
}

