import { useState, useEffect, useContext, useRef } from "react"
import { AppContext } from '../App'
import * as c from '../c'
import * as p from './'
import * as f from '../f'

import { Map, GeoJSON, TileLayer, Marker, Popup } from "react-leaflet"
import L from "leaflet"

export default function Contratos({setReloader,modelo}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.contratos},
        icons = App.icons,
        natopContratoComercial = App.diretivas.NatopVelogContratoComercial,
        natopContratoMovimento = App.diretivas.NatopVelogContratoMovimento

  const [data, setData] = useState([]),
        [editing, setEditing] = useState(null),
        [editCotas, setEditCotas] = useState(null),
        [detalhe, setDetalhe] = useState(null),
        [incluir, setIncluir] = useState(null),
        [toggleCardsList, setToggleCardsList] = useState(true),
        [loading, setLoading] = useState([]),
        [busca, setBusca] = useState(''),
        [showFilters, setShowFilters] = useState(false),
        [ufFilter, setUfFilter] = useState(null),
        [filtroEncerradas, setFiltroEncerradas] = useState('Todos'),
        [tipo,setTipo] = useState(false),
        [contratoComercial, setContratoComercial] = useState(false)

  const opcoes = [
    {value: 'todos'     ,label:'Todos'},
    {value: 'andamento' ,label:'Em andamento'},
    {value: 'encerrados',label:'Encerrados'}
  ];

  function get(){
    setLoading(true)
    App.api('contratos::consultaTudo',{filtroEncerradas, id_natop: natopContratoComercial,id_natop_movimento: natopContratoMovimento,tp_contrato: modelo}).then(r=>{ // Consertar busca de diretivas
      r = r.results.map(contrato=>({...contrato,
        ORI_DES: <>{contrato.DS_ORIGEM}<br/>{contrato.DS_DESTINO}</>,
        SLC: f.formatNumber(contrato.QT_SLC, 0),
        SALDO: f.formatNumber(contrato.QT_SALDO, 0),
        CAD: f.formatNumber(contrato.QT_CAD, 0),
        actions: <>
                  {parseInt(contrato.CD_STS) !== -1 
                    && <DropdownEditMenu contrato={contrato} setEditing={setEditing} setEditCotas={setEditCotas} control={<icons.BsPencilFill/>} />
                  }
                    <c.IconButton onClick={()=>setDetalhe(contrato)} ><icons.BsEye/></c.IconButton>
                </>,
        VOL_CADENCIA: f.formatNumber( parseFloat(contrato.QT_ORG_HOJE??0) + parseFloat(contrato.QT_VOLUMECOTA_HOJE??0), 0 ),
        calc_ontem:   f.formatNumber( parseFloat(contrato.QT_ORG_ONTEM??0) + parseFloat(contrato.QT_VOLUMECOTA_ONTEM??0), 2 ),
        calc_hoje:    f.formatNumber( parseFloat(contrato.QT_ORG_HOJE??0) + parseFloat(contrato.QT_VOLUMECOTA_HOJE??0), 2 ),
        calc_amanha:  f.formatNumber( parseFloat(contrato.QT_ORG_AMANHA??0) + parseFloat(contrato.QT_VOLUMECOTA_AMANHA??0), 2 )
      }))
      setData(r)
      setLoading(false)
      return true;
    })
  }

  useEffect(() =>{
    if(contratoComercial){
      setIncluir(true)
    }
  },[contratoComercial])

  useEffect(()=>{
    const tipo_contrato = modelo;
    if(tipo_contrato == 'CNC'){
      setTipo(true)
    }else{
      setTipo(false)
    }
    get()
    setReloader(()=>get)
  }, [modelo])

  useEffect(() => get(), [filtroEncerradas])

  return(<>
    <div className="react-tabs f g2">
      {!loading &&
        <ul className="react-tabs__tab-list">
          <li onClick={()=>setUfFilter(null)} className={["react-tabs__tab", (!ufFilter?'react-tabs__tab--selected':'')].join(' ')}>
            Todas as UFs <sup>{data.length}</sup>
          </li>
          {Object.values(data
            .reduce((acc, d)=>({...acc,
              [d.UF_UCN]: { uf: d.UF_UCN,
                qnt: (acc[d.UF_UCN]?.qnt??0) + 1
              }
            }),{}))
            .map((d, di) =>
              <li key={di} onClick={()=>setUfFilter(d.uf)} className={["react-tabs__tab", (ufFilter===d.uf?'react-tabs__tab--selected':'')].join(' ')}>
                {d.uf} <sup>{d.qnt}</sup>
              </li>
            )
          }
        </ul>
      }
    </div>

    <c.Frame title={lang.comunicacao_negocio} loading={loading} flex
      actions={{
        add: ()=>setIncluir(true),
        filter: toggleCardsList ? {onClick:()=>setShowFilters(!showFilters), value: showFilters} : null,
        toggleCardsList: {onClick:()=>setToggleCardsList(!toggleCardsList), value: toggleCardsList}
      }}
      control={<div className="f g1">
        <c.Input placeholder={lang.busca} value={busca} onChange={e=>setBusca(e.target.value)} clearable className={'f5'}/>
        <c.Select value={filtroEncerradas} onChange={v=>setFiltroEncerradas(v.value)} options={opcoes} label={'Status Contratos'} searchable className='f1'/>
      </div>}
      
    >
      {/* <div className="f g1">
        {'Todos'}
        <c.Switch checked={filtroEncerradas} onChange={e => setFiltroEncerradas(e.target.checked)} />
        {'Concluídas'}
      </div> */}

      {toggleCardsList ? 
        <c.Table loading={loading} search={busca} showFilters={showFilters}
          columns={[
            [lang.ori_des, 'ORI_DES'],
            [lang.produto, 'NM_PRO'],
            [lang.volume, 'SLC'],
            [lang.saldo, 'SALDO'],
            [lang.cadencia_dia, 'CAD'],
            [lang.valor_frete, 'VL_FRETE'],
            [lang.valor_pedagio, 'VL_PEDAGIO'],
            [lang.ontem, 'QT_ONTEM'],
            [lang.hoje, 'QT_HOJE'],
            [lang.amanha, 'QT_AMANHA'],
            [lang.situacao,'situacao'],
            [lang.acao, 'actions', {}, 'center f g1'],
          ]} 
          data={data.filter(d=>!ufFilter||d.UF_UCN===ufFilter).map(item => ({...item,
            situacao: item.CD_STS === '-1' ? lang.encerrado : (item.CD_STS === '-2'? lang.pausado : lang.em_andamento)
          }))} 
        />

        : 
        
        data.filter(d=>!ufFilter||d.UF_UCN===ufFilter).length
          ? (()=>{
            let df = data?.filter(d=>!ufFilter||d.UF_UCN===ufFilter).filter(d=>busca===''||Object.keys(d).some(k=> 
              ['string','number'].indexOf(typeof d[k])>=0 
                && busca?.toUpperCase().split(' ').every(si=>String(d[k])?.toUpperCase().includes(si))))??[]
            return df.length ? df?.map((d,i)=><ContratoCard key={i} setEditing={setEditing} setDetalhe={setDetalhe} get={get} contrato={d} tipo={tipo} setIncluir={setIncluir} setContratoComercial={setContratoComercial}/>) : <b>{lang.nothing_here}</b>
          })()
          : <b>{lang.nothing_here}</b>
      }
    </c.Frame>

    {incluir&&<ContratoIncluir onFinish={()=>{get();setContratoComercial(false)}} onClose={()=>{setIncluir(null);setContratoComercial(false)}} tipo={tipo} natop={natopContratoComercial} contratoComercial={contratoComercial}/>}
    {detalhe&&<ContratoDetalhe detalhe={detalhe} onClose={()=>setDetalhe(null)} />}
    {editing&&<ContratoEditar contrato={editing.contrato} op={editing.op} onFinish={()=>get()} onClose={()=>setEditing(null)} tipo={tipo}/>}
  </>)
}


// ------------------------------ ContratoCotasModal ------------------------------
function ContratoCotasModal({contrato, onFinish, onClose}){
  const App = useContext(AppContext),
        icons = App.icons
  
  const [cotas, setCotas] = useState([]),
        [cotaEdting, setCotaEdting] = useState({}),
        [cotaSaving, setCotaSaving] = useState(null),
        [loading, setLoading] = useState(null),
        [turnos, setTurnos] = useState([])

  async function save(cota){
    setCotaSaving(cota.ID_UOCC)

    const r = await App.api('contratos::cotaSave', {
      ID_CN: contrato.ID_CN,
      cota: cotaEdting
    })

    if( r.status ) App.toast.success('Salvo com sucesso!')

    setCotas( (await App.api('contratos::cotas', {ID_CN: contrato.ID_CN})).results ?? [] )

    setCotaEdting({})
    setCotaSaving(null)
  }

  async function insertClick(){
    await getTurnos( App.moment().format('YYYY-MM-DD') )

    setCotas([{ID_UOCC: 0}, ...cotas])

    setCotaEdting({
      "ID_UOCC": 0,
      "DT_COTA": App.moment().format('YYYY-MM-DD'),
      "DIA_SEMANA": 0,
      "ID_TRN": 0,
      "DS_TRN": "",
      "QT_COTA": 0,
      "QT_CNS": 37,
      "QT_USO": 0
    })
  }

  async function getTurnos(date){
    setTurnos( (await App.api('contratos::getTurnos', {date})).results )
  }

  function dataTurnoRepetido(){
    return cotaEdting.ID_UOCC === 0
      && cotas.filter(c => c.DT_COTA === cotaEdting.DT_COTA && c.ID_TRN === cotaEdting.ID_TRN).length > 0
  }

  useEffect(() => {
    init()

    async function init(){
      setLoading(true)

      let _cotas = (await App.api('contratos::cotas', {ID_CN: contrato.ID_CN})).results ?? []

      if( cotaEdting.ID_UOCC !== undefined ){
        _cotas = [{ID_UOCC: 0}, ..._cotas]
      }

      setCotas( _cotas )

      setLoading(null)
    }
  }, [])

  //useEffect(() => console.log( cotaEdting ), [cotaEdting])

  return(
    <c.Modal title={'Cotas do contrato ' + contrato.ID_CN}
      onClose={onClose}
      loading={loading}
      cancelText={'Sair'}
      extraControls={
        cotaEdting.ID_UOCC === undefined && <button onClick={insertClick}><icons.MdAdd />Incluir</button>
      }
    >
      <c.Table isSub allowOrder={cotaEdting.ID_UOCC !== 0} withPagination={false}
        columns={[
          ['Data', '_DT_COTA'],
          ['Semana', '_DIA_SEMANA', {}, 'DIA_SEMANA'],
          ['Turno', '_DS_TRN'],
          ['Volume', '_VOLUME'],
          ['Conversão', '_QT_CNS'],
          ['Cota', '_QT_COTA'],
          ['Utilizado', '_QT_USO'],
          ['Edição', '_ACTION'],
        ]} 
        data={
          cotas.map(cota => ({...cota,
            _DT_COTA: cotaEdting.ID_UOCC === cota.ID_UOCC && cotaEdting.ID_UOCC === 0
                  ? <c.Input value={cotaEdting.DT_COTA} fit type="date" loading={cotaSaving!==null} 
                      min={App.moment().format('YYYY-MM-DD')}
                      error={!cotaEdting.DT_COTA || dataTurnoRepetido()} style={{width: 110}}
                      onChange={e => {
                        getTurnos( App.moment(e.target.value).format('YYYY-MM-DD') )
                        cotaEdting.DT_COTA = e.target.value
                        setCotaEdting({...cotaEdting})
                      }} 
                    />
                : cotaSaving === cota.ID_UOCC ? <c.Skeleton /> : App.moment(cota.DT_COTA).format('DD/MM/YYYY'),
            _DIA_SEMANA: cotaSaving === cota.ID_UOCC ? <c.Skeleton />
                  : f.diaDaSemana( App.moment( cotaEdting.DT_COTA ?? cota.DT_COTA).day() + 1 ),
            _DS_TRN: cotaEdting.ID_UOCC === cota.ID_UOCC && cotaEdting.ID_UOCC === 0
                  ? <c.Select value={cotaEdting.ID_TRN} options={turnos} fit loading={cotaSaving!==null}
                      error={!cotaEdting.ID_TRN || dataTurnoRepetido()}
                      onChange={e => {
                        cotaEdting.ID_TRN = e.value
                        setCotaEdting({...cotaEdting})
                      }}
                    />
                  : cotaSaving === cota.ID_UOCC ? <c.Skeleton /> : cota.DS_TRN,
            _VOLUME: cotaEdting.ID_UOCC !== cota.ID_UOCC
                ? parseInt(cota.QT_COTA ?? 0) * parseInt(cota.QT_CNS ?? 0)
                : <c.Input value={ cotaEdting._VOLUME ?? parseInt(cotaEdting.QT_COTA) * parseInt(cotaEdting.QT_CNS) } fit
                    loading={cotaSaving!==null} style={{width: 50}} 
                    onChange={e => {
                      cotaEdting._VOLUME = Math.max( parseInt( +e.target.value ), 0 )
                      setCotaEdting({...cotaEdting})
                    }}
                    onBlur={e => {
                      cotaEdting.QT_COTA = parseInt( parseInt( +e.target.value ) / (cotaEdting.QT_CNS ? cotaEdting.QT_CNS : 1) )
                      delete cotaEdting._VOLUME
                      setCotaEdting({...cotaEdting})
                    }}
                  />,
            _QT_CNS: cotaEdting.ID_UOCC !== cota.ID_UOCC
                ? parseInt(cota.QT_CNS ?? 0)
                : <c.Input value={cotaEdting.QT_CNS ?? 0} fit loading={cotaSaving!==null} style={{width: 50}} 
                    onChange={e => {
                      cotaEdting.QT_CNS = Math.max( parseInt( +e.target.value ), 0 )
                      setCotaEdting({...cotaEdting})
                    }}
                  />,
            _QT_COTA: cotaEdting.ID_UOCC !== cota.ID_UOCC
                ? parseInt(cota.QT_COTA ?? 0)
                : <c.Input value={cotaEdting.QT_COTA ?? 0} fit loading={cotaSaving!==null} style={{width: 50}} 
                    type="number"
                    onChange={e => {
                      cotaEdting.QT_COTA = Math.max( parseInt( +e.target.value ), cotaEdting.QT_USO, 0 )
                      setCotaEdting({...cotaEdting})
                    }}
                  />,
            _QT_USO: cotaSaving === cota.ID_UOCC ? <c.Skeleton /> : cota.QT_USO,
            _ACTION: 
                <div className="f center-v g1" style={{minHeight: 32}}>
                  {cotaEdting.ID_UOCC !== cota.ID_UOCC
                    ? cotaEdting.ID_UOCC === undefined &&
                      <c.IconButton icon={icons.MdEdit} size={32} onClick={() => setCotaEdting(cota)} title="Editar" />
                    : cotaSaving === cota.ID_UOCC ? <c.Skeleton /> 
                      : <>
                        {!!cotaEdting.DT_COTA && !!cotaEdting.ID_TRN && !dataTurnoRepetido() &&
                          <c.IconButton title="Salvar" icon={icons.MdSave} size={32} 
                            onClick={() => save(cota)} 
                          />
                        }
                        <c.IconButton title="Cancelar" icon={icons.MdCancel} size={32} 
                          onClick={() => App.confirm('Cancelar edição/adição?', () => {
                            if( cota.ID_UOCC === 0 ) cotas.shift()
                            return !setCotaEdting({})
                          })}
                        />
                        {dataTurnoRepetido() && <span style={{color: 'var(--danger)', fontSize: 12}}>Já existe registro de cotas <br/> para esta data e turno</span>}
                      </>
                  }
                </div>
          }))
        } 
      />
    </c.Modal>
  )
}


// ------------------------------ DropdownEditMenu ------------------------------
function DropdownEditMenu({contrato, control, setEditing,tipo}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.contratos},
        icons = App.icons
  return(
    <c.DropdownMenu title={lang.editar}
      itens={
        [
          {title: lang.pausar,   action: ()=>setEditing({contrato, op: 'pausar'}), disabled: contrato.CD_STS!=='1'},
        ].concat(
          contrato.ID_NATOP === '1'
          ? [
              {title: lang.tarifa,   action: ()=>setEditing({contrato, op: 'tarifa'}), disabled: contrato.CD_STS!=='1'},
              {title: lang.lote,     action: ()=>setEditing({contrato, op: 'lote'}), disabled: contrato.CD_STS!=='1'},
              {title: lang.transp,   action: ()=>setEditing({contrato, op: 'transportadora'}), disabled: contrato.CD_STS!=='1'},
              {title: lang.encerrar, action: ()=>setEditing({contrato, op: 'encerrar'})},
              {title: lang.obs,      action: ()=>setEditing({contrato, op: 'obs'}), disabled: contrato.CD_STS!=='1'},
              {title: lang.origem_destino,action: ()=>setEditing({contrato, op: 'origem_destino'}), disabled: contrato.CD_STS!=='1'},
              {title: lang.vincular_fluxo, action: ()=> setEditing({contrato, op: 'vincular_fluxo'}), disabled: contrato.CD_STS!=='1'},
              parseInt(contrato.CD_STS) === -2 && (
                {title: lang.reiniciar, action: ()=>setEditing({contrato, op: 'reiniciar'})}
              )
            ]
          : 
            (contrato.ID_NATOP == App.diretivas.NatopVelogContratoComercial 
            ?[
                {title: lang.lote,     action: ()=>setEditing({contrato, op: 'lote'}), disabled: contrato.CD_STS!=='1'},
                {title: lang.encerrar, action: ()=>setEditing({contrato, op: 'encerrar'})},
                {title: lang.obs,      action: ()=>setEditing({contrato, op: 'obs'}), disabled: contrato.CD_STS!=='1'},
                {title: 'Turno',     action: ()=>setEditing({contrato, op: 'turno'}), disabled: contrato.CD_STS!=='1'},
                parseInt(contrato.CD_STS) === -2 && (
                  {title: lang.reiniciar, action: ()=>setEditing({contrato, op: 'reiniciar'})}
                )
                // Nova opção de editar turno
            ]
            :
              (contrato.ID_NATOP == App.diretivas.NatopVelogContratoMovimento ?
                [
                  {title: lang.tarifa,   action: ()=>setEditing({contrato, op: 'tarifa'}), disabled: contrato.CD_STS!=='1'},
                  {title: lang.transp,   action: ()=>setEditing({contrato, op: 'transportadora'}), disabled: contrato.CD_STS!=='1'},
                  {title: lang.encerrar, action: ()=>setEditing({contrato, op: 'encerrar'})},
                  {title: lang.obs,      action: ()=>setEditing({contrato, op: 'obs'}), disabled: contrato.CD_STS!=='1'},
                  {title: lang.origem_destino,action: ()=>setEditing({contrato, op: 'origem_destino'}), disabled: contrato.CD_STS!=='1'}
                ]
                :
                  []
              )
            
            )
        
        )
      }
    >
      {control}
    </c.DropdownMenu>
  )
}


// ------------------------------ CARD DO CONTRATO ------------------------------
function ContratoCard({contrato, setEditing, setDetalhe, get,tipo,setIncluir,setContratoComercial}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.contratos},
        icons = App.icons,
        natopContratoMovimento = App.diretivas.NatopVelogContratoMovimento,
        natopContratoComercial = App.diretivas.NatopVelogContratoComercial
  const [_contrato,set_Contrato] = useState(contrato)

  useEffect(()=>{
    set_Contrato(
      {...contrato,CNM: contrato.CNM?.map(cnm =>({...cnm,action:
        <>
        {parseInt(contrato.CD_STS) !== -1 && <DropdownEditMenu setEditing={setEditing} contrato={cnm} control={<icons.BsPencilFill/>} />}
        </>
      }))}
    )
  },[])
  const DS_OBS = [...JSON.parse(contrato.DS_OBS ? contrato.DS_OBS : '[]'),
    !!contrato.VL_FRETENEW 
      ? {id_color: 'danger', ds_obs: lang.card_nova_tarifa + contrato.DT_FRETENEW + ': R$ ' + contrato.VL_FRETENEW} : null,
    !!contrato.VL_FRETEMOTORISTANEW 
      ? {id_color: 'danger', ds_obs: lang.card_valor_maximo_motorista + contrato.DT_FRETEMOTORISTANEW + ': R$ ' + contrato.VL_FRETEMOTORISTANEW} : null,
    contrato.CD_STS === "-2" 
      ? {id_color: 'warning', ds_obs: lang.card_pausar + contrato.DT_MAXCOTA} : null,
    contrato.show_recalc && contrato.ID_NATOP === '1'
      ? {id_color: 'danger', ds_obs: lang.card_data_insuficiente} : null,
  ].filter(o=>!!o&&!!o.ds_obs)

  return(
    <div className={tipo ?"contrato-multidestinos-card":"contrato-card"}>
      <div className={tipo ?"cnc-card":""}>
        {App.moment().format('DD/MM/YYYY') === contrato.DT_CDT
          ? <div className="fita-novo">Novo</div> : null
        }

        <div className="header">
          <div className="base">
            <div className="f g2 center-v">
              <c.Avatar size={48} />
              <h2 className="b">{contrato.NR_DCO}</h2>
            </div>
            <div><span>{contrato.DS_ORIGEM}</span></div>
            {!(!!tipo)&&<div><span>{contrato.DS_DESTINO}</span></div>}
            <div><span>{contrato.NM_PRO}</span></div>
            <div><span>{contrato.CLIENTE}</span></div>
          </div>

          <div className="actions">
            {contrato.ID_NATOP == natopContratoComercial && tipo &&
              <button className="warning" onClick={()=>{setContratoComercial(contrato)}} >{'Contrato Movimento'}</button>
            }

            {parseInt(contrato.CD_STS) === 1 && // Contrato normal
              <DropdownEditMenu setEditing={setEditing} contrato={contrato} control={<button>{lang.editar}</button>} style={{marginLeft: -50}} />}

            {parseInt(contrato.CD_STS) === -2 && // Contrato pausado
              <button className="warning" onClick={()=>setEditing({contrato, op: 'reiniciar'})} >{lang.reiniciar}</button>}

            {contrato.show_recalc === true && contrato.ID_NATOP === '1' && // Recalcular
              <button className="warning" onClick={()=>App.api("contratos::recalcular", {idCn: contrato.ID_CN}).then(()=>get())} >
                {lang.recalcular}</button>}

            {parseInt(contrato.CD_STS) === -1 && // Contrato encerrado
              <span className="encerrado">{lang.encerrado}</span>}

            <button onClick={() => setDetalhe(contrato)}>{lang.visualizar}</button>
          </div>
        </div>

        <c.Divider />

        <div className="infos">
          {!(!!tipo)&&<c.Span value={f.formatNumber(contrato.VL_FRETE??0,2)} sub={'(∅ '+f.formatNumber(contrato.AVG_FRETE??0,2)+')'} label={lang.frete} />}
          {!(!!tipo)&&<c.Span value={f.formatNumber(contrato.VL_FRETEMOTORISTA??0,2)} sub={'(∅ '+f.formatNumber(contrato.AVG_FRETEMOTORISTA??0,2)+')'} label={lang.frete_motorista} />}
          {!(!!tipo)&&<c.Span value={f.formatNumber(contrato.VL_PEDAGIO,2)} sub={'(∅ '+f.formatNumber(contrato.AVG_PEDAGIO??0,2)+')'} label={lang.pedagio} />}
          <c.Span value={contrato.SLC} label={lang.volume_ton} />
          <c.Span value={contrato.SALDO} label={lang.saldo_ton} />
          <c.Span value={contrato.VOL_CADENCIA + '/' + f.formatNumber(contrato.QT_CAD)} 
            label={lang.volume_cadencia} />
          <c.Span value={contrato.ID_CN} label={lang.contrato} />
          <c.Span value={contrato.DT_INICIO} label={lang.inicio} />
          <c.Span value={contrato.DT_MAXCOTA} label={lang.termino_previsto} />
          <c.Span value={contrato.calc_ontem}  sub={<>({contrato.QT_ONTEM}  <icons.FaTruck size={16} />)</>} label={lang.ontem} />
          <c.Span value={contrato.calc_hoje}   sub={<>({contrato.QT_HOJE}   <icons.FaTruck size={16} />)</>} label={lang.hoje}  />
          <c.Span value={contrato.calc_amanha} sub={<>({contrato.QT_AMANHA} <icons.FaTruck size={16} />)</>} label={lang.amanha}/>
        </div>

        {!!DS_OBS.length && <>
          <c.Divider />
          <div className="observations">
            {DS_OBS.map((obs, i)=>obs.ds_obs!==""&&<div key={"obs"+i} className={['item', obs.id_color].join(' ')}>{obs.ds_obs}</div>)}
          </div>
        </>}
      </div>
      {tipo && (
          <div className="cnm-card">
          <c.Table isSub
          columns={[
            ['#', 'ID_CN'],
            ['Cliente', 'NM_PESFSCJRD'],
            ['Destino', 'DS_DESTINO'],
            ['Frete', 'VL_FRETE'],
            ['Volume', 'QT_SLC'],
            ['Saldo', 'QT_SALDO'],
            [lang.acao, 'action', {}, 'center f g1']
          ]} 
          data={_contrato.CNM} 
        />
          </div>)
      }
    </div>
  )
}


// ------------------------------ EDIÇÃO DO CONTRATO ------------------------------
function ContratoEditar({contrato, op, onClose, onFinish,tipo}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.contratos},
        icons = App.icons

  const [pauses_lst, setPauses_lst] = useState([]),
        [transportadoras, setTransportadoras] = useState([]),
        [validate, setValidate] = useState(false),
        [loading, setLoading] = useState(false),
        [data, setData] = useState({ op, idCn: contrato.ID_CN  }),
        [listas, setListas] = useState({}),
        [loadingMap, setLoadingMap] = useState(false),
        [keyMAP, setKeyMAP] = useState(Math.random()),
        [pedagios, setPedagios] = useState([]),
        [rotaOrigemDestino, setRotaOrigemDestino] = useState([]),
        [posOrigemDestino, setPosOrigemDestino] = useState({inicio: [],fim: []}),
        [position, setPosition] = useState([-23.415881526446956, -51.93903347350793]),
        [rota, setRota] = useState({lat_lng_orig: [],lat_lng_dest: []}),
        [turnos, setTurnos] = useState([])

    const iconePedagio = new L.divIcon({className: "",
      iconSize: [32, 32], iconAnchor: [4, 10],
      labelAnchor: [-4, 0], popupAnchor: [0, -5],
      html: `<span style="color:#d04933;" class="fa fa-circle"></span>`,
    })
 
  function deletePause(id, ini, fim){
    window.confirm(lang.confirm_pause_del.replace('%ini',ini).replace('%fim', (fim || lang.indeterminado))) &&
      App.api('contratos::setPauseStatus', {idCn: contrato.ID_CN, id, sts: -3})
        .then(r=>setPauses_lst(r.results))
  }

  function submit(){
    setLoading(true)
    return App.api('contratos::editar', {...data,
      VL_FRETE: parseFloat(data.VL_FRETE),
      VL_PEDAGIO: parseFloat(data.VL_PEDAGIO),
      VL_FRETEMOTORISTA: parseFloat(data.VL_FRETEMOTORISTA),
      DS_OBS: data.DS_OBS.filter(o=>!!o.ds_obs),
      tipo: tipo ? 'CNC' : 'CN'
    }).then(r=>{
      if(r.status) onFinish()
      setLoading(false)
      return r.status
    })
  }

  useEffect(() => {
    setLoading(true)
    get()
    setLoading(false)

    async function get(){
      const _contrato = (await App.api('contratos::consultaPorId',{idCn: contrato.ID_CN})).results
      setData({...data, ..._contrato,
        qt_saldo:          _contrato.qt_volume,
        qt_saldoatual:     _contrato.qt_volume,
        qt_volume:         _contrato.qt_volumetotal,
        VL_FRETE:          _contrato.vl_frete,
        VL_FRETEMOTORISTA: _contrato.vl_fretemotorista,
        VL_PEDAGIO:        _contrato.vl_pedagio,
        qt_cota:           Math.ceil(_contrato.QT_CADENCIA / _contrato.QT_CONVERSAO),
        transportadoras:   _contrato.lst_transp?.map(t=>t.NR_CNPJTRP),
        lst_transp:        _contrato.lst_transp?.map(t=>({label: t.NM_TRP, value: t.NR_CNPJTRP})),
        DS_OBS:            JSON.parse( _contrato.DS_OBS??'[]' ),
        turnos:            _contrato.lst_turnos?.map(t=>t.ID_UOCC),
        lst_turnos:        _contrato.lst_turnos?.map(t=>({label: t.DS_TRN, value: t.ID_UOCC}))
      })
      if( op === 'pausar' ) {
        App.api('contratos::getPauses', {idCn: contrato.ID_CN}).then(r => setPauses_lst(r.results))
      }
      else if( op === 'transportadora' ) {
        App.api('transportadoras::get')
          .then(r=>{
            setTransportadoras(r.results.map((v,i)=>({value: v.NR_CNPJTRP, label: v.NM_TRP, key: i})))
          })
      }
  
      else if(op === 'turno'){
        console.log(_contrato)
        App.api('turno::get')
          .then(r=>{
            setTurnos(r.results.map((v,i) => ({value: v.ID_UOCC, label: v.DS_TRN, key: i})))
          })
      }

    } 
    
  }, [])


  useEffect(()=>{
    setValidate(
      op==='pausar'
        ? !!data.DT_INICIO
      : op==='tarifa'
        ? (!!data.VL_FRETE && !!data.VL_FRETEMOTORISTA && data.dtVldFrete) || (!!data.VL_PEDAGIO && data.dtVldPedagio)
      : op==='lote'
        ? !!data.QT_VOLUME && !!data.QT_CADENCIA && !!data.QT_CONVERSAO && !!data.QT_COTA && data.QT_CADENCIA|0 >= data.QT_CONVERSAO|0
      : op==='transportadora'
        ? !!data.transportadoras?.length
      : op==='encerrar'
        ? !!data.dt_termino
      : op==='reiniciar'
        ? !!data.DT_INICIO
      : op==='obs' 
        ? true
      : op==='origem_destino'
        ? true
      : op==='vincular_fluxo'
        ? true
      : op==='turno'
        ? true
      : false
    )
  }, [data])

  useEffect(() => {
    if(!!data.NR_CNPJEXP && !!data.NR_CNPJEXP && op==='origem_destino'){
      Promise.all([
        App.api('fob::getEmbarcadores', {adicionais: true, s: data.NR_CNPJEXP}),
        App.api('fob::getEmbarcadores', {adicionais: true, s: data.NR_CNPJRCB})
      ]).then((r) =>{
        const exp = r[0];
        const rcb = r[1];
        setListas({...listas, origens:exp.results ,destinos:  rcb.results})
        // setData({...data, NR_CNPJRCrcbata.NR_CNPJEXP,NR_CNPJEXP: data.NR_CNPJEXP})
      })
      
    }
  }, [data.NR_CNPJEXP,data.NR_CNPJEXP])

  // Tarifas
  const [lstTarifas, setLstTarifas] = useState([])
  useEffect(() => getTarifas(), [])
  function getTarifas(){
    App.api('contratos::getLstTarifas', {idCn: contrato.ID_CN}).then(r=>{
      let _tarifas = {}
      let x = ['frete','motorista','pedagio'].map(tipo => {
        _tarifas = {..._tarifas,
          [tipo]: r.results?.[tipo].map((item,i)=>({...item,
            //DT_FIMVLD: item.DT_FIMVLD??'Indeterminado',
            value: parseFloat(item.VL_UBRT).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}),
            action: parseInt(item.CD_STS)===1 && i===0 &&
              <button onClick={()=>deleteTarifa(item.ID_TPCO)} style={{color: 'white', background: 'indianred'}}><icons.MdDelete style={{fontSize: 16}} /></button>,
            disable: item.CD_STS==='-3',
          }))
        }
        return true
      })
      setLstTarifas(_tarifas)
    })
  }

  function deleteTarifa(tipo){
    App.confirm('Confirma a exclusão da tarifa?',
      ()=>App.api('contratos::delTarifa', {idCn: contrato.ID_CN, tipo}).then(r=>{if(r.status) {getTarifas()} return r.status})
    )
  }
  
  //Pedagio
  useEffect(()=>{
    if(op==='origem_destino'){
      atualizaPedagios();
    }
  }, [listas])

  async function buscarLongitudeLatitude(objeto){
    const endereco = `${objeto?.adicional?.nm_loc}, ${objeto?.adicional?.nm_uf}, ${objeto?.adicional?.nm_pais}`
    const r = await fetch(`https://nominatim.openstreetmap.org/search?q=${endereco}&format=geojson`)
    const dados = await r.json()
    return dados?.features[0].geometry.coordinates
  }

  function atualizarCustos(lat_lng_orig, lat_lng_dest){
    fetch('https://velog.vertti.com.br/api/roteiro', {method: 'POST', headers: {'content-type': 'application/json, text/plain, */*'}, 
      body: JSON.stringify({sn_lat_lng: true, sn_rota_alternativa: false, sn_pedagio: true, sn_balanca: true,
        tag: "TRUCK", sn_calcu_volta: false, qtd_eixos: 6, veiculo_km_litro: 0, valor_medio_combustivel: 0,
        rotas: [{ lat: lat_lng_orig[1], lng: lat_lng_orig[0] },{ lat: lat_lng_dest[1], lng: lat_lng_dest[0] }],
      }) 
    }).then(r=>r.json()).then(r=>{
      setPedagios(r[0].track.rotas[0].pedagios)
      setRotaOrigemDestino(r[0].track.rotas[0].lng_lat)
      setPosOrigemDestino({
        inicio: [
          r[0].track.rotas[0].lng_lat[0][1],
          r[0].track.rotas[0].lng_lat[0][0],
        ],
        fim: [
          r[0].track.rotas[0].lng_lat[ r[0].track.rotas[0].lng_lat.length - 1 ][1],
          r[0].track.rotas[0].lng_lat[ r[0].track.rotas[0].lng_lat.length - 1 ][0],
        ],
      })

      setData({...data,
        VL_PEDAGIO: (r[0].track.rotas[0].vl_total_pedagio_original / 6)?.toFixed(2)??0,
        QT_KM: r[0].track.rotas[0].distancia,
        HR_DURACAO: r[0].track.rotas[0].duracao
      })
    })
  }

  async function atualizaPedagios(){
    if( !!data.NR_CNPJEXP && !!data.NR_CNPJRCB ){
      // console.log("Chamei att pedagio")
      // console.log(listas)
      setLoadingMap(true)
      const origem = !!data.NR_CNPJEXP ? listas.origens?.find(o=>parseInt(o.VALUE)===parseInt(data.NR_CNPJEXP)) : null
      const destino = !!data.NR_CNPJRCB ? listas.destinos?.find(o=>parseInt(o.VALUE)===parseInt(data.NR_CNPJRCB)) : null
      if( origem  !== null && destino !== null ){
        // console.log(origem)
        // console.log(destino)
        const posOrigem = await buscarLongitudeLatitude(origem)
        const posDestino = await buscarLongitudeLatitude(destino)

        setRota({...rota, lat_lng_orig: posOrigem, lat_lng_dest: posDestino})
        setPosition([posOrigem[1], posOrigem[0]])
        atualizarCustos(posOrigem, posDestino)
      }
      setLoadingMap(false)
    }
  }

  return(
    <c.Modal largeWidth={op==='origem_destino'} title={lang.editar_contrato.replace('%d', contrato.ID_CN) + ' - ' + lang[op]}
      validate={validate} loading={loading} onClose={onClose} onFinish={submit}
      extraControls={!(op==='obs')?null:
        <button title={lang.adicionar} onClick={()=>{data.DS_OBS=data.DS_OBS.filter(o=>!!o.ds_obs);data.DS_OBS.push({ds_obs:''});setData({...data})}}><icons.MdAdd />{lang.adicionar}</button>
      }
    >
      <div className="f f-column g1 w100 contrato-editar">
        {op==='pausar'&&<> {/* PAUSAR */}
          <c.Input type='date' label={lang.dtInicio}  value={data.DT_INICIO??''}  onChange={e=>setData({...data, DT_INICIO:  e.target.value})} />
          <c.Input type='date' label={lang.dtTermino} value={data.DT_TERMINO??''} onChange={e=>setData({...data, DT_TERMINO: e.target.value})} />

          <c.Divider text={lang.pausas_cadastradas} />
          {pauses_lst.length ? 
            <ul className="contrato-pausas">{pauses_lst?.map((p,i)=><li key={i} className={p.CD_STS==='-3'?'paused':''}>
              {p.DT_INI} - {p.DT_FIM??lang.indeterminado}
              {p.CD_STS!=='-3'&&<button onClick={()=>deletePause(p.ID_UOCC, p.DT_INI, p.DT_FIM)} title={lang.del_pausa}><icons.BsFillTrashFill /></button>}
            </li>)}</ul>
          : lang.sem_pausas}
        </>}

        {/* TARIFA */}
        {op==='tarifa'&&<div className="f g2">
          <div style={{width: 450, minHeight: 255, overflowY: 'auto'}} className="f f-column g2">
            {['frete','motorista','pedagio'].map(tt=>
              <c.Frame key={tt} title={lang.historico + ' - Tarifas ' + tt}>
                <c.Table disableLine={'disable'}
                  columns={[
                    [lang.dtInicio, '_DT_INIVLD'],
                    [lang.dtTermino, '_DT_FIMVLD'],
                    [lang.tarifa, 'value'],
                    ['', 'action',{},'force-fit'],
                  ]} data={
                    (lstTarifas[tt]??[]).map(t=>({...t,
                      _DT_INIVLD: App.moment(t.DT_INIVLD).format('DD/MM/YYYY'),
                      _DT_FIMVLD: !!t.DT_FIMVLD ? App.moment(t.DT_FIMVLD).format('DD/MM/YYYY') : lang.indeterminado,
                    }))
                  } 
                />
              </c.Frame>
            )}
          </div>

          <div className="f f-column g1" style={{width: 300}}>
            <c.Input label={lang.valor_frete}     value={f.formatNumber(data.VL_FRETE, 2)} onChange={e=>setData({...data, VL_FRETE: f.toFloat(e.target.value, 2)})} />
            <c.Input label={lang.valor_frete_mot} value={f.formatNumber(data.VL_FRETEMOTORISTA, 2)} onChange={e=>setData({...data, VL_FRETEMOTORISTA: f.toFloat(e.target.value, 2)})} />
            <c.Input label={lang.data_inicio}     value={data.dtVldFrete}        onChange={e=>setData({...data, dtVldFrete: e.target.value})} type="date" />
            <c.Divider />
            <c.Input label={lang.pedagio}         value={f.formatNumber(data.VL_PEDAGIO, 2)} onChange={e=>setData({...data, VL_PEDAGIO: f.toFloat(e.target.value, 2)})} />
            <c.Input label={lang.data_inicio}     value={data.dtVldPedagio}      onChange={e=>setData({...data, dtVldPedagio: e.target.value})} type="date" />
          </div>
        </div>}

        {op === 'lote' && <> {/* LOTE */}
          <c.Input disabled label={lang.volume_total} type="number" value={data.qt_volumetotal} />
          <c.Input disabled label={lang.consumida} type="number" value={data.qt_consumido} />
          <c.Input disabled label={lang.saldo} value={f.formatNumber(data.qt_saldoatual, 2)} />
          <c.Input error={!data.QT_VOLUME} label={lang.novo_volume} type="number" value={data.QT_VOLUME} onChange={e=>setData({...data, QT_VOLUME: e.target.value})} />
          <c.Input disabled label={lang.novo_saldo} value={f.formatNumber(data.qt_saldo, 2)} />
          <c.Input error={!data.QT_CADENCIA || parseInt(data.QT_CADENCIA) < parseInt(data.QT_CONVERSAO)}  label={lang.volume_cadencia} type="number" value={data.QT_CADENCIA} 
            onChange={e=>setData({...data, QT_CADENCIA: e.target.value, QT_COTA: Math.ceil(e.target.value / data.QT_CONVERSAO)})} />
          <c.Input error={!data.QT_CONVERSAO} label={lang.volume_conversao} type="number" value={data.QT_CONVERSAO} 
            onChange={e=>setData({...data, QT_CONVERSAO: e.target.value, QT_COTA: Math.ceil(data.QT_CADENCIA / e.target.value)})} />
          <c.Input error={!data.QT_COTA} label={lang.cota} type="number" value={Math.ceil(data.QT_CADENCIA / data.QT_CONVERSAO)??0} 
            onChange={e=>setData({...data, QT_COTA: e.target.value, QT_CADENCIA: data.QT_CONVERSAO * e.target.value})} />
        </>}

        {op==='transportadora'&&<> {/* TRANSPORTADORA */}
          <div style={{minHeight: 400}}>
            <c.Select multi label={lang.transportadora} options={transportadoras} value={data.transportadoras} style={{width: '70vw'}}
              onChange={e=>setData({...data, transportadoras: e.map(t=>t.value)})} defaultValue={data.lst_transp} clearable searchable />
              <button onClick={()=>setData({...data, transportadoras: transportadoras.map(t=>t.value)})}>{lang.selecionar_tudo}</button>
          </div>
        </>}

        {op==='turno'&&<> {/* TURNO */}
          <div style={{minHeight: 400}}>
            <c.Select multi label={'Turno'} options={turnos} value={data.turnos} style={{width: '70vw'}}
              onChange={e=>setData({...data, turnos: e.map(t=>t.value)})} defaultValue={data.lst_turnos} clearable searchable />
              <button onClick={()=>setData({...data, turnos: turnos.map(t=>t.value)})}>{lang.selecionar_tudo}</button>
          </div>
        </>}

        {op==='reiniciar'&&<> {/* REINICIAR */}
          <c.Input error={!data.DT_INICIO} label={lang.dtInicio} type="date" value={data.DT_INICIO} onChange={e=>setData({...data, DT_INICIO: e.target.value})} />
        </>}

        {op==='encerrar'&&<> {/* ENCERRAR */}
          <c.Input error={!data.dt_termino} label={lang.dtTermino} type="date" value={data.dt_termino} onChange={e=>setData({...data, dt_termino: e.target.value})} />
        </>}

        {/* OBS */}
        {op==='obs'&&
          <div className="f g1 f-column" style={{width: '70vw'}}>
            {/* <button title={lang.adicionar} onClick={()=>{data.DS_OBS=data.DS_OBS.filter(o=>!!o.ds_obs);data.DS_OBS.push({ds_obs:''});setData({...data})}}><icons.MdAdd /></button> */}
            {data.DS_OBS?.map((o, i) =>
              <div key={i} className="f g1 center-v">
                <c.Checkbox checked={o.in_ticket} 
                  label={'No ticket'}
                  onChange={e => {o.in_ticket = e.target.checked; setData({...data})}}
                />

                <c.Select label={lang.tipo} className="f1" options={[
                    {value: 'success', label: lang.success, color: 'var(--success)'},
                    {value: 'danger',  label: lang.danger,  color: 'var(--danger)'},
                    {value: 'warning', label: lang.warning, color: 'var(--warning)'},
                    {value: 'info',    label: lang.info,    color: 'var(--info)'},
                  ]} styles={{option:(s, {data})=>({...s, color: 'var(--white)',background: data.color})}} 
                  onChange={e=>{data.DS_OBS[i].id_color=e.value;setData({...data, DS_OBS: data.DS_OBS})}}
                  value={o.id_color}
                />

                <c.Input value={o.ds_obs} label={lang.observacao} className="f4"
                  onChange={e=>{data.DS_OBS[i].ds_obs=e.target.value;setData({...data, DS_OBS: data.DS_OBS})}} 
                  inputStyle={{borderLeft: '5px solid var(--'+(o.id_color??'color')+')'}}
                />

                <button onClick={()=>{data.DS_OBS.splice(i,1);setData({...data, DS_OBS: data.DS_OBS})}}><icons.BsFillTrashFill /></button>
              </div>
            )}
          </div>
        }

        {op==='origem_destino' &&
            <div className="f g2 w100" >
              <div className="f g1 f-column f1">
                <c.Select label={lang.origem}  options={listas.origens}  value={data.NR_CNPJEXP} onChange={e=>setData({...data, NR_CNPJEXP: e.value})} searchable 
                  onInputChange={(v, {action}) => {
                    if( v.length >= 3 && action === 'input-change') App.api('fob::getEmbarcadores',{adicionais: true, s: v}).then(r => setListas({...listas, origens: r.results}))
                    return v
                  }}
                />
                <c.Select label={lang.destino} options={listas.destinos} value={data.NR_CNPJRCB} onChange={e=>setData({...data, NR_CNPJRCB: e.value})} searchable 
                  onInputChange={(v, {action}) => {
                    if( v.length >= 3 && action === 'input-change') App.api('fob::getEmbarcadores',{adicionais: true, s: v}).then(r => setListas({...listas, destinos: r.results}))
                    return v
                  }}
                />

                <c.Input disabled value={f.formatCurrency(data.VL_PEDAGIO??0)} label={lang.pedagio} />
                <c.Input disabled value={data.QT_KM} label={lang.distancia} />
                <c.Input disabled value={data.HR_DURACAO} label={lang.duracao} />
              </div>
              <div className="f2 radius overflow-hidden box-shadow">
                {loadingMap?<App.LoadingComponent />:
                  <Map key={keyMAP} center={position} zoom={8} style={{ position: "relative", width: "100%", height: 375 }}>
                    {posOrigemDestino.inicio.length > 0 && posOrigemDestino.fim.length > 0 && <>
                      <Marker position={posOrigemDestino.inicio}><Popup>Inicio da Rota</Popup></Marker>
                      <Marker position={posOrigemDestino.fim}><Popup>Final da Rota</Popup></Marker>
                      <GeoJSON attribution='&copy; <a href="https://velog.com.br">VELOG</a>'
                        data={{type: "RotaCollection",
                          features: [{type: "Feature", geometry: {type: "LineString", coordinates: rotaOrigemDestino}}],
                        }}
                      />
                    </>}
                    {pedagios.map((value, index) => (
                      <Marker icon={iconePedagio} key={`pedagio-${index}`} position={[value.id_lat, value.id_long]} >
                        <Popup>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                            <div style={{ backgroundImage: `url("${value.ds_logo}")`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain", width: "100px", minHeight: "70px", maxHeight: "100px" }} />
                            <span>{value.ds_praca}</span> <span><strong>{f.formatCurrency(value.vl_tarifa / 6)}</strong></span>
                          </div>
                        </Popup>
                      </Marker>
                    ))}
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>' />
                  </Map>
                }
              </div>
            </div>
        }

        {op === 'vincular_fluxo' && 
          <c.Input error={!data.VL_FLUXO} label={lang.descricao_fluxo} value={data.VL_FLUXO} onChange={e=>setData({...data, VL_FLUXO: e.target.value})} />
}
      </div>
    </c.Modal>
  )
}


// ------------------------------ DETALHES DO CONTRATO ------------------------------
function ContratoDetalhe({detalhe, onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.contratos},
        icons = App.icons

  const today = App.moment().format('yyyy-MM-DD')

  const [dtini, setDtini] = useState(today),
        [dtfim, setDtfim] = useState(today),
        [dataDetalhe, setDataDetalhe] = useState({}),
        [veiculoBusca, setVeiculoBusca] = useState(''),
        [loading, setLoading] = useState(false)

  function getDataDetalhe(){
    setLoading(true)
    App.api('gerencial::consulta',{ini: dtini, fim: dtfim, id_cn: detalhe.ID_CN})
    .then(r => {
      setDataDetalhe(r.results)
    }).finally(() => setLoading(false))
  }
  
  useEffect(() => getDataDetalhe(),[])
  useEffect(() => 
    App.moment(dtini,'YYYY-MM-DD',true).isValid() 
    && App.moment(dtini,'YYYY-MM-DD',true).format('YYYY') >= 1900
    && App.moment(dtfim,'YYYY-MM-DD',true).isValid() 
    && App.moment(dtfim,'YYYY-MM-DD',true).format('YYYY') >= 1900
    && getDataDetalhe()
  ,[dtini, dtfim])

  return(
    <c.Modal title={lang.detalhe_contrato_titulo+' '+detalhe.ID_CN+' :: '+detalhe.DS_ORIGEM+' > '+detalhe.DS_DESTINO+' :: '+detalhe.NM_PRO}
      onClose={onClose} loading={loading}
      control={<div className="f g1">
        <c.Input type="date" label={lang.data_inicial} value={dtini} onChange={e => setDtini(e.target.value)} />
        <c.Input type="date" label={lang.data_final} value={dtfim} onChange={e => setDtfim(e.target.value)} />
      </div>}
    >
      <div className="f g2 f-column">
        <div className="f f-wrap g2"><p.GerencialOperacaoCards data={dataDetalhe} /></div>

        {!!dataDetalhe?.grafico&&
          <c.Frame title={lang.variacao_frete}>
            <c.chart.ResponsiveContainer width="100%" minWidth={500} height={350}>
              <c.chart.ComposedChart data={(()=>{
                let FRETE_media_aux = parseInt( dataDetalhe?.grafico.reduce((acc, cur) => acc + parseFloat(cur.FRETE), 0) / dataDetalhe?.grafico.length * 100) / 100
                let FRETE_media = Number.isNaN(FRETE_media_aux) ? '-' : FRETE_media_aux
                let MOTORISTA_media_aux = parseInt( dataDetalhe?.grafico.reduce((acc, cur) => acc + parseFloat(cur.MOTORISTA), 0) / dataDetalhe?.grafico.length * 100) / 100
                let MOTORISTA_media = Number.isNaN(MOTORISTA_media_aux) ? '-' :  MOTORISTA_media_aux
                return dataDetalhe?.grafico.map(g => ({...g,
                  FRETE_media, MOTORISTA_media
                }))
              })()} >
                <c.chart.CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <c.chart.Tooltip />
                <c.chart.YAxis domain={[0, parseInt( Math.max(...dataDetalhe.grafico?.map(g=>parseFloat(g.FRETE) > parseFloat(g.MOTORISTA) ? parseFloat(g.FRETE) : parseFloat(g.MOTORISTA) )) * 1.2 ) ]} /> 
                <c.chart.XAxis dataKey="DT" />
                <c.chart.Line type="natural" dataKey="FRETE" stroke="#00dac2" strokeWidth={2} name="Frete"/>
                <c.chart.Line type="natural" dataKey="MOTORISTA" stroke="#f0a82d" strokeWidth={2} name="Motorista"/>
                <c.chart.Line type="natural" dataKey="FRETE_media" stroke="#00dac2" strokeWidth={1} strokeDasharray={5} name="Media Frete"/>
                <c.chart.Line type="natural" dataKey="MOTORISTA_media" stroke="#f0a82d" strokeWidth={1} strokeDasharray={5} name="Media Motorista"/>
              </c.chart.ComposedChart>
            </c.chart.ResponsiveContainer>
          </c.Frame>
        }

        <c.Frame title={lang.veiculos}
          control={<c.Input placeholder={lang.busca} value={veiculoBusca} onChange={e=>setVeiculoBusca(e.target.value)}/>}
        >
          <c.Table search={veiculoBusca} withPagination={dataDetalhe.lst_veiculo?.length>10}
            columns={[
              [lang.unidade, 'ID_UORG'],
              [lang.data_prevista, '_DT_PRV', {}, '', 'DT_PRV'],
              [lang.turno, 'DS_TRN'],
              [lang.operacao, 'DS_TPOPRPRT'],
              [lang.cliente, 'NM_CLT'],
              [lang.origem_destino, 'DS_ORGDST'],
              [lang.produto, 'DS_PRD'],
              [lang.contrato, 'ID_CN'],
              [lang.transportadora, 'NM_TRP'],
              [lang.veiculo, 'ID_VCL'],
              [lang.ticket, 'ID_UOCC'],
            ]} 
            data={
              dataDetalhe.lst_veiculo?.map(veiculo => ({...veiculo,
                _DT_PRV: App.moment( veiculo.DT_PRV ).format(lang.formatoData)
              }))
            } 
          />
        </c.Frame>
      </div>
    </c.Modal>
  )
}


// ------------------------------ INCLUIR CONTRATO ------------------------------
function ContratoIncluir({onFinish, onClose,tipo,natop,contratoComercial}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.contratos},
        icons = App.icons

  const [step, setStep] = useState(0),
        [listas, setListas] = useState({}),
        [loading, setLoading] = useState(false),
        [loadingMap, setLoadingMap] = useState(false),
        [pedagios, setPedagios] = useState([]),
        [rotaOrigemDestino, setRotaOrigemDestino] = useState([]),
        [posOrigemDestino, setPosOrigemDestino] = useState({inicio: [],fim: []}),
        [rota, setRota] = useState({lat_lng_orig: [],lat_lng_dest: []}),
        [position, setPosition] = useState([-23.415881526446956, -51.93903347350793]),
        [keyMAP, setKeyMAP] = useState(Math.random()),
        [data, setData] = useState({
          tp_mvt: contratoComercial.TP_MVT ?? '',
          id_pro:contratoComercial.ID_PRO ?? '',
          id_ucn: contratoComercial.ID_UCN ?? '',
          dt_cn: contratoComercial.DT_CN ?? '',
          nr_cn: contratoComercial.NR_CN ?? '', 
          nr_cnpjrmt:contratoComercial.NR_CNPJRMT ?? '',
          tp_oprprt: "R", tp_frt: "C",
          qt_conversao: contratoComercial.CONVERSAO ?? 37, lst_transp: [], lst_turnos:[],
          obs: [],
        }),
        [tipoContrato,setTipoContrato] = useState('CN'),
        [idNatop,setIdNatop] = useState(false)
      
  const steps = tipo ? ( !!contratoComercial ? // CNM
  [
    {label: lang.contrato, 
      validate: (!!data.nr_cnpjprc&& !!data.nr_cnpjdst)},
    {label: lang.rota, validate: (!!data.nr_cnpjexp&&!!data.nr_cnpjrcb)},
    {label: lang.transportadora, validate: (!!data.lst_transp?.length)},
    {label: lang.lote, 
      validate: !!data.qt_volume&&!!data.vl_frete},
    {label: lang.obs, validate: true},
    {label: lang.resumo, validate: true},
  ] :
  [ // CNC
    {label: lang.contrato, 
      validate: !!data.nr_cnpjund&&!!data.tp_oprprt&&!!data.tp_frt&&!!data.nr_cnpjprc&&!!data.nr_cnpjrmt && ( tipo ? true : !!data.nr_cnpjdst)},
    {label: 'Turno', validate: (tipo ? (!!data.lst_turnos?.length) : true)},
    {label: lang.lote, 
      validate: !!data.id_pro&&!!data.dt_inicio&&!!data.qt_volume&&!!data.qt_cadencia&&!!data.qt_conversao&&!!data.qt_cota&&data.qt_cadencia>=data.qt_conversao},
    {label: lang.obs, validate: true},
    {label: lang.resumo, validate: true},
  ]
  ) :
  [ // Contrato lote, normal
    {label: lang.contrato, 
      validate: !!data.nr_cnpjund&&!!data.tp_oprprt&&!!data.tp_frt&&!!data.nr_cnpjprc&&!!data.nr_cnpjrmt && ( tipo ? true : !!data.nr_cnpjdst)},
    {label: lang.rota, validate: (tipo ? true : (!!data.nr_cnpjexp&&!!data.nr_cnpjrcb))},
    {label: lang.transportadora, validate: (tipo ? true : (!!data.lst_transp?.length))},
    {label: lang.lote, 
      validate: !!data.id_pro&&!!data.dt_inicio&&!!data.qt_volume&&!!data.qt_cadencia&&!!data.qt_conversao&&!!data.qt_cota&&!!data.vl_frete && data.qt_cadencia>=data.qt_conversao},
    {label: lang.obs, validate: true},
    {label: lang.resumo, validate: true},
  ];

  const iconePedagio = new L.divIcon({className: "",
    iconSize: [32, 32], iconAnchor: [4, 10],
    labelAnchor: [-4, 0], popupAnchor: [0, -5],
    html: `<span style="color:#d04933;" class="fa fa-circle"></span>`,
  })

  function atualizarCustos(lat_lng_orig, lat_lng_dest){
    fetch('https://velog.vertti.com.br/api/roteiro', {method: 'POST', headers: {'content-type': 'application/json, text/plain, */*'}, 
      body: JSON.stringify({sn_lat_lng: true, sn_rota_alternativa: false, sn_pedagio: true, sn_balanca: true,
        tag: "TRUCK", sn_calcu_volta: false, qtd_eixos: 6, veiculo_km_litro: 0, valor_medio_combustivel: 0,
        rotas: [{ lat: lat_lng_orig[1], lng: lat_lng_orig[0] },{ lat: lat_lng_dest[1], lng: lat_lng_dest[0] }],
      }) 
    }).then(r=>r.json()).then(r=>{
      setPedagios(r[0].track.rotas[0].pedagios)
      setRotaOrigemDestino(r[0].track.rotas[0].lng_lat)
      setPosOrigemDestino({
        inicio: [
          r[0].track.rotas[0].lng_lat[0][1],
          r[0].track.rotas[0].lng_lat[0][0],
        ],
        fim: [
          r[0].track.rotas[0].lng_lat[ r[0].track.rotas[0].lng_lat.length - 1 ][1],
          r[0].track.rotas[0].lng_lat[ r[0].track.rotas[0].lng_lat.length - 1 ][0],
        ],
      })

      setData({...data,
        vl_pedagio: (r[0].track.rotas[0].vl_total_pedagio_original / 6)?.toFixed(2)??0,
        qt_km: r[0].track.rotas[0].distancia,
        hr_duracao: r[0].track.rotas[0].duracao
      })
    })
  }

  async function atualizaPedagios(){
    if( !!data.nr_cnpjexp && !!data.nr_cnpjrcb ){
      setLoadingMap(true)
      const origem = !!data.nr_cnpjexp ? listas.origens?.find(o=>parseInt(o.VALUE)===parseInt(data.nr_cnpjexp)) : null
      const destino = !!data.nr_cnpjrcb ? listas.destinos?.find(o=>parseInt(o.VALUE)===parseInt(data.nr_cnpjrcb)) : null

      if( origem !== null && destino !== null ){
        const posOrigem = await buscarLongitudeLatitude(origem)
        const posDestino = await buscarLongitudeLatitude(destino)

        setRota({...rota, lat_lng_orig: posOrigem, lat_lng_dest: posDestino})
        setPosition([posOrigem[1], posOrigem[0]])
        atualizarCustos(posOrigem, posDestino)
      }
      setLoadingMap(false)
    }
  }

  async function buscarLongitudeLatitude(objeto){
    const endereco = `${objeto?.adicional?.nm_loc}, ${objeto?.adicional?.nm_uf}, ${objeto?.adicional?.nm_pais}`
    const r = await fetch(`https://nominatim.openstreetmap.org/search?q=${endereco}&format=geojson`)
    const dados = await r.json()
    return dados.features[0].geometry.coordinates
  }

  function submit(){
    setLoading(true)
    return App.api('contratos::gravar',{...data, obs: data.obs.filter(o=>!!o.ds_obs), tp_contrato: tipoContrato, id_natop: idNatop})
      .then(r=>{ setLoading(false)
        if(r.status) onFinish()
        setLoading(false)
        return r.status
      })
  }

  useEffect(()=>atualizaPedagios(), [data.nr_cnpjexp,  data.nr_cnpjrcb, data.vl_pedagio])
  useEffect(()=>rotaOrigemDestino.length > 0 && setKeyMAP(Math.random()), [rotaOrigemDestino])

  // Auto-preencher origem e destino
  useEffect(() => {
    if( !!data.nr_cnpjrmt && !(tipo && contratoComercial)){
      App.api('fob::getEmbarcadores', {adicionais: true, s: data.nr_cnpjrmt}).then(r => {
        setListas({...listas, origens:  r.results,})
        setData({...data, nr_cnpjexp: data.nr_cnpjrmt,})
      })
    }
  }, [data.nr_cnpjrmt])

  useEffect(() => {
    if( !!data.nr_cnpjdst ){
      App.api('fob::getEmbarcadores', {adicionais: true, s: data.nr_cnpjdst}).then(r => {
        setListas({...listas, destinos:  r.results,})
        setData({...data, nr_cnpjrcb: data.nr_cnpjdst})
      })
    }
  }, [data.nr_cnpjdst])


  useEffect(()=>{setLoading(true)
    if(tipo && contratoComercial){ // CNM
      setIdNatop(App.diretivas.NatopVelogContratoMovimento)
      setTipoContrato('CNM')
    }else if(tipo && !contratoComercial){
      setIdNatop(App.diretivas.NatopVelogContratoComercial)
      setTipoContrato('CNC')
    }
    Promise.all([
      // App.api('fob::getUnidades'),
      // App.api('fob::getParceiros'),
      // App.api('fob::getEmbarcadores', {adicionais: true}),
      App.api('fob::getProdutos'),
      App.api('fob::getTurnos'),
      App.api('transportadoras::get'),
      App.api('turno::get'),
      App.api('fob::getEmbarcadores', {adicionais: true, s: data.nr_cnpjrmt})
    ]).then(r => {
      setListas({
        //unidades: r[0].results,
        //parceiros: r[1].results,
        //remetentes: r[2].results,
        //destinatarios: r[2].results,
        //origens: r[2].results,
        //destinos: r[2].results,
        // produtos: r[3].results,
        // turnos: r[4].results,
        // transportadoras: r[5].results.map((v,i)=>({value: v.NR_CNPJTRP, label: v.NM_TRP, key: i}))
        produtos: r[0].results,
        turnos: r[1].results,
        transportadoras: r[2].results.map((v,i) => ({value: v.NR_CNPJTRP, label: v.NM_TRP, key: i})),
        turnos: r[3].results.map((v,i) => ({value: v.ID_UOCC, label: v.DS_TRN, key: i})),
        origens: r[4].results
      })
      setData({...data, nr_cnpjexp: data.nr_cnpjrmt,})
      setLoading(false)
    })
  },[])

  return(
  <c.Modal largeWidth title={lang.inclusao_contrato} steps={steps} onStepChange={s=>setStep(s)} loading={loading} 
    validate={steps.every(s=>s.validate)} contentHeight={400} onFinish={submit} onClose={onClose} 
  >
    {/* Contrato */} 
    {step===0&&<div className="f f-wrap g1 w100">
      {tipoContrato != 'CNM' &&<c.Select label={lang.unidade} options={listas.unidades} value={data.nr_cnpjund} onChange={e=>setData({...data, nr_cnpjund: e.value})} error={!data.nr_cnpjund} searchable className="w50" 
        onInputChange={(v, {action}) => {
          if( v.length >= 3 && action === 'input-change') App.api('fob::getUnidades',{s: v}).then(r => setListas({...listas, unidades: r.results}))
          return v
        }}
      />}
      {tipoContrato != 'CNM' &&<c.Select label={lang.operacao}     options={[{value:'R',label:lang.recepcao},{value:'E',label:lang.expedicao}]} value={data.tp_oprprt} onChange={e=>setData({...data, tp_oprprt: e.value})} className="f1"/>}
      {tipoContrato != 'CNM' &&<c.Select label={lang.frete}        options={[{value:'C',label:lang.cif},{value:'F',label:lang.fob}]} value={data.tp_frt} onChange={e=>setData({...data, tp_frt: e.value})} className="f1" />
}
      <c.Select label={lang.cliente} options={listas.parceiros} value={data.nr_cnpjprc} onChange={e=>setData({...data, nr_cnpjprc: e.value})} error={!data.nr_cnpjprc} searchable className="w100" 
        onInputChange={(v, {action}) => {
          if( v.length >= 3 && action === 'input-change') App.api('fob::getParceiros',{s: v}).then(r => setListas({...listas, parceiros: r.results}))
          return v
        }}
      />
      {tipoContrato != 'CNM' &&<c.Select label={lang.remetente} options={listas.remetentes} value={data.nr_cnpjrmt} onChange={e=>setData({...data, nr_cnpjrmt: e.value})} error={!data.nr_cnpjrmt} searchable className="w100" 
        onInputChange={(v, {action}) => {
          if( v.length >= 3 && action === 'input-change') App.api('fob::getEmbarcadores',{adicionais: true, s: v}).then(r => setListas({...listas, remetentes: r.results}))
          return v
        }}
      />}
      {tipoContrato != 'CNC'&& <c.Select label={lang.destinatario} options={listas.destinatarios} value={data.nr_cnpjdst} onChange={e=>setData({...data, nr_cnpjdst: e.value})} error={!data.nr_cnpjdst} searchable className="w100" 
        onInputChange={(v, {action}) => {
          if( v.length >= 3 && action === 'input-change') App.api('fob::getEmbarcadores',{adicionais: true, s: v}).then(r => setListas({...listas, destinatarios: r.results}))
          return v
        }}
      />}
    </div>}

    {/* Rota */}
    {(tipo? (!!contratoComercial&&step===1) : step===1)&&<>
      <div className="f g2 w100">
        <div className="f g1 f-column f1">
          <c.Select label={lang.origem}  options={listas.origens}  value={data.nr_cnpjexp} onChange={e=>setData({...data, nr_cnpjexp: e.value})} searchable 
            onInputChange={(v, {action}) => {
              if( v.length >= 3 && action === 'input-change') App.api('fob::getEmbarcadores',{adicionais: true, s: v}).then(r => setListas({...listas, origens: r.results}))
              return v
            }}
          />
          <c.Select label={lang.destino} options={listas.destinos} value={data.nr_cnpjrcb} onChange={e=>setData({...data, nr_cnpjrcb: e.value})} searchable 
            onInputChange={(v, {action}) => {
              if( v.length >= 3 && action === 'input-change') App.api('fob::getEmbarcadores',{adicionais: true, s: v}).then(r => setListas({...listas, destinos: r.results}))
              return v
            }}
          />

          <c.Input disabled value={f.formatCurrency(data.vl_pedagio??0)} label={lang.pedagio} />
          <c.Input disabled value={data.qt_km} label={lang.distancia} />
          <c.Input disabled value={data.hr_duracao} label={lang.duracao} />
        </div>
        <div className="f2 radius overflow-hidden box-shadow">
          {loadingMap?<App.LoadingComponent />:
            <Map key={keyMAP} center={position} zoom={8} style={{ position: "relative", width: "100%", height: 375 }}>
              {posOrigemDestino.inicio.length > 0 && posOrigemDestino.fim.length > 0 && <>
                <Marker position={posOrigemDestino.inicio}><Popup>Inicio da Rota</Popup></Marker>
                <Marker position={posOrigemDestino.fim}><Popup>Final da Rota</Popup></Marker>
                <GeoJSON attribution='&copy; <a href="https://velog.com.br">VELOG</a>'
                  data={{type: "RotaCollection",
                    features: [{type: "Feature", geometry: {type: "LineString", coordinates: rotaOrigemDestino}}],
                  }}
                />
              </>}
              {pedagios.map((value, index) => (
                <Marker icon={iconePedagio} key={`pedagio-${index}`} position={[value.id_lat, value.id_long]} >
                  <Popup>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                      <div style={{ backgroundImage: `url("${value.ds_logo}")`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain", width: "100px", minHeight: "70px", maxHeight: "100px" }} />
                      <span>{value.ds_praca}</span> <span><strong>{f.formatCurrency(value.vl_tarifa / 6)}</strong></span>
                    </div>
                  </Popup>
                </Marker>
              ))}
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>' />
            </Map>
          }
        </div>
      </div>
    </>}

    {/* Transportadora */}
    {(tipo? (!!contratoComercial&&step===2) : step===2)&&<>
      <c.Select multi label={lang.transportadora} options={listas.transportadoras} value={data.lst_transp} className="w100"
        onChange={e=>setData({...data, lst_transp: e.map(t=>t.value)})} defaultValue={listas.transportadoras?.filter(t=>!!data.lst_transp.find(v=>v===t.value))} clearable searchable />
        <button onClick={()=>setData({...data, lst_transp: listas.transportadoras.map(t=>t.value)})}>{lang.selecionar_tudo}</button>
    </>}

    {/* Turno */}
    {(tipo? (!(!!contratoComercial)&&step===1) : false)&& (
      <>
        <c.Select multi label={'Turnos'} options={listas.turnos} value={data.lst_turnos} className="w100"
          onChange={e=>setData({...data, lst_turnos: e.map(t=>t.value)})} defaultValue={listas.turnos?.filter(t=>!!data.lst_turnos.find(v=>v===t.value))} clearable searchable />
          <button onClick={()=>setData({...data, lst_turnos: listas.turnos.map(t=>t.value)})}>{lang.selecionar_tudo}</button>
      </>)}
    {/* Lote */}
    {(tipo? (!!contratoComercial ? step===3 : step===2) : step===3)&&<>
      {tipoContrato != 'CNM' &&<div className="w100">
      <c.Select label={lang.produto} options={listas.produtos} value={data.id_pro} error={!data.id_pro} className="w100"
        onChange={e=>setData({...data, id_pro: e?.value})} defaultValue={data.id_pro} clearable searchable />
      </div>}

      <div className="w100 f g1">
        {tipoContrato != 'CNM' &&<c.Input label={lang.inicio}       value={data.dt_inicio}   onChange={e=>setData({...data, dt_inicio: e.target.value})} type="date" error={!data.dt_inicio} className="w1-3" />}
        {tipoContrato != 'CNM' &&<c.Input label={lang.validade}     value={data.dt_validade} onChange={e=>setData({...data, dt_validade: e.target.value})} type="date" className="w1-3" />}
        <c.Input label={lang.volume_total} value={f.formatNumber(data.qt_volume)} onChange={e=>setData({...data, qt_volume: f.toFloat(e.target.value, 0)})} error={!data.qt_volume} className="w1-3" />
        {tipoContrato == 'CNM'&&<c.Input label={lang.volume_conversao} disabled value={f.formatNumber(data.qt_conversao)} onChange={e=>setData({...data, qt_conversao: f.toFloat(e.target.value, 0), qt_cota: Math.ceil(data.qt_cadencia / f.toFloat(e.target.value, 0))})} error={!data.qt_conversao} className="w1-3" />}
      </div>

      {tipoContrato != 'CNM' &&
      <div className="w100 f g1">
        <c.Input label={lang.volume_cadencia}  value={f.formatNumber(data.qt_cadencia)}  onChange={e=>setData({...data, qt_cadencia: f.toFloat(e.target.value, 0), qt_cota: Math.ceil(f.toFloat(e.target.value, 0) / data.qt_conversao)})} error={!data.qt_cadencia || data.qt_cadencia<data.qt_conversao} className="w1-3" />
        <c.Input label={lang.volume_conversao} value={f.formatNumber(data.qt_conversao)} onChange={e=>setData({...data, qt_conversao: f.toFloat(e.target.value, 0), qt_cota: Math.ceil(data.qt_cadencia / f.toFloat(e.target.value, 0))})} error={!data.qt_conversao} className="w1-3" />
        <c.Input label={lang.cota}             value={f.formatNumber(data.qt_cota)}      onChange={e=>setData({...data, qt_cota: f.toFloat(e.target.value, 0), qt_cadencia: data.qt_conversao * f.toFloat(e.target.value, 0)})} error={!data.qt_cota} className="w1-3" />
      </div>
      }
      {tipoContrato != 'CNC' &&
        <div className="w100 f g1">
          <c.Input label={lang.pedagio}          value={f.formatCurrency(data.vl_pedagio)} onChange={e=>setData({...data, vl_pedagio: f.toFloat(e.target.value, 2)})} className="w1-3" />
          <c.Input disabled value={data.qt_km??'---'} label={lang.distancia} className="w1-3" />
          <c.Input disabled value={data.hr_duracao??'---'} label={lang.duracao} className="w1-3" />
        </div>
        &&
        <div className="w100 f g1">
          <c.Input label={lang.valor_frete}     value={f.formatCurrency(data.vl_frete)}          onChange={e=>setData({...data, vl_frete: f.toFloat(e.target.value, 2)})} error={!data.vl_frete} className="w50" />
          <c.Input label={lang.frete_motorista} value={f.formatCurrency(data.vl_fretemotorista)} onChange={e=>setData({...data, vl_fretemotorista: f.toFloat(e.target.value, 2)})} className="w50" />
        </div>
      }
    </>}

    {/* Observação */}
    {(tipo? (!!contratoComercial ? step===4 : step===3) : step===4)&&<>
      <div className="f g1 f-column w100">
        <button title={lang.adicionar} onClick={()=>{data.obs=data.obs.filter(o=>!!o.ds_obs);data.obs.push({ds_obs:''});setData({...data})}}><icons.MdAdd />{lang.adicionar}</button>
        {data.obs?.map((o,i)=>
          <div key={i} className="f g1 center-v">
            <c.Checkbox checked={o.in_ticket} 
              label={'No ticket'}
              onChange={e => {o.in_ticket = e.target.checked; setData({...data})}}
            />

            <c.Select label={lang.tipo} className="" options={[
                {value: 'success', label: lang.success, color: 'var(--success)'},
                {value: 'danger',  label: lang.danger,  color: 'var(--danger)'},
                {value: 'warning', label: lang.warning, color: 'var(--warning)'},
                {value: 'info',    label: lang.info,    color: 'var(--info)'},
              ]} styles={{option:(s, {data})=>({...s, color: 'var(--white)', background: data.color})}} 
              onChange={e=>{data.obs[i].id_color=e.value;setData({...data})}}
              value={o.id_color} style={{flexBasis: 150}}
            />

            <c.Input value={o.ds_obs} label={lang.observacao} className="f4"
              onChange={e=>{data.obs[i].ds_obs=e.target.value;setData({...data})}} 
              inputStyle={{borderLeft: '5px solid var(--'+(o.id_color??'color')+')'}}
            />

            <button onClick={()=>{data.obs.splice(i,1);setData({...data})}}><icons.BsFillTrashFill /></button>
          </div>
        )}
      </div>
    </>}

    {/* Resumo */}
    {(tipo? (!!contratoComercial ? step===5 : step===4) : step===5)&&<div className="f g1">
      <div className="f g1 f3 f-column">
        <div className="f g1 w100">
          <c.Input disabled label={lang.unidade}          value={listas.unidades?.find(a=>a.VALUE===data.nr_cnpjund)?.LABEL} className='f2' />
          <c.Input disabled label={lang.operacao}         value={{R: lang.recepcao, E:lang.expedicao}[data.tp_oprprt]} className='f1' />
          <c.Input disabled label={lang.frete}            value={{C: lang.cif, F: lang.fob}[data.tp_frt]} className='f1' />
        </div>
        
        <div className="f g1 w100">
        <c.Input disabled label={lang.cliente}          value={listas.parceiros?.find(a=>a.VALUE===data.nr_cnpjprc)?.LABEL} className='f1' />
        <c.Input disabled label={'Tomador'}             value={listas.tomadores?.find(a=>a.VALUE===data.nr_cnpjtmd)?.LABEL} className='f1' />
        </div>

        <div className="f g1 w100">
          <c.Input disabled label={lang.remetente}        value={listas.remetentes?.find(a=>a.VALUE===data.nr_cnpjrmt)?.LABEL} className='f1' />
          <c.Input disabled label={lang.destinatario}     value={listas.destinatarios?.find(a=>a.VALUE===data.nr_cnpjdst)?.LABEL} className='f1' />
        </div>

        <div className="f g1 w100">
          <c.Input disabled label={lang.origem}           value={listas.origens?.find(a=>a.VALUE===data.nr_cnpjexp)?.LABEL} className='f1' />
          <c.Input disabled label={lang.destino}          value={listas.destinos?.find(a=>a.VALUE===data.nr_cnpjrcb)?.LABEL} className='f1' />
        </div>

        <div className="f g1 w100">
          <c.Input disabled label={lang.produto}          value={listas.produtos?.find(a=>a.VALUE===data.id_pro)?.LABEL} className='f2' />
          <c.Input disabled label={lang.data_inicio}      value={App.moment(data.dt_inicio).format('DD/MM/yyyy')} className='f1' />
          <c.Input disabled label={lang.data_validade}    value={data.dt_validade?App.moment(data.dt_validade).format('DD/MM/yyyy'):'---'} className='f1' />
        </div>

        <div className="f g1 w100">
          <c.Input disabled label={lang.volume_total}     value={f.formatNumber(data.qt_volume)} className='f1' />
          <c.Input disabled label={lang.volume_cadencia}  value={f.formatNumber(data.qt_cadencia)} className='f1' />
          <c.Input disabled label={lang.volume_conversao} value={f.formatNumber(data.qt_conversao)} className='f1' />
          <c.Input disabled label={lang.cota}             value={f.formatNumber(data.qt_cota)} className='f1' />
        </div>

        <div className="f g1 w100">
          <c.Input disabled label={lang.pedagio}          value={f.formatCurrency(data.vl_pedagio)} className='f2' />
          <c.Input disabled label={lang.distancia}        value={data.qt_km} className='f1' />
          <c.Input disabled label={lang.duracao}          value={data.hr_duracao} className='f1' />
        </div>

        <div className="f g1 w100">
          <c.Input disabled label={lang.frete}            value={f.formatCurrency(data.vl_frete)} className='f1' />
          <c.Input disabled label={lang.frete_motorista}  value={f.formatCurrency(data.vl_fretemotorista)} className='f1' />
        </div>
      </div>

      <div className="f1"><h5>{lang.transportadoras}</h5>
        <ol>
          {data.lst_transp.map((cnpj,i)=>
            <li key={i}>{listas.transportadoras.find(t=>t.value===cnpj).label}</li>
          )}
        </ol>
      </div>
    </div>}
  </c.Modal>)
}