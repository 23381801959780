import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as p from '..'

export default function Produtos({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.produtos},
        icons = App.icons

  const [grupoModal, setGrupoModal] = useState(null),
        [produtoModal, setProdutoModal] = useState(null),
        [showUndMdd, setShowUndMdd] = useState(false),
        [ncmModal, setNcmModal] = useState(false),
        [grupos, setGrupos] = useState([]),
        [grupo, setGrupo] = useState([]),
        [produtos, setProdutos] = useState([]),
        [produtoBusca, setProdutoBusca] = useState(''),
        [loading, setLoading] = useState({}),
        [filtered, setFiltered] = useState(),
        [colunas, setColunas] = useState([]),
        [produtoShowFilters, setProdutoShowFilters] = useState(false)

  const colunas_default = [
    [' # ', 'ID_PRO'],
    [lang.nome, 'NM_PRO'],
    [lang.tipo_do_produto, 'DS_TPPRO'],
    [lang.status, 'DS_STS'],
    [lang.nomered, 'NM_PRORED'],
    [lang.unmddQTD, 'ID_UNDMQTD'],
    [lang.unmddPCO, 'ID_UNDMPCO'],
    [lang.unmddEXP, 'ID_UNDMEXP'],
    [lang.ncm, 'NCM'],
    [lang.acao, 'actions', {display: 'flex', justifyContent: 'center'}]
  ]

  function getGrupos(){ 
    setLoading({...loading, grupos: true})
    App.api('produtos::getGrupos').then(r=>{
      setGrupos(r.results)
      setLoading({...loading, grupos: false})
    })
  }

  function getProdutos(){ 
    setLoading({...loading, produtos: true})
    App.api('produtos::get', {}).then(r=>{
      r=r.results.map(p=>({...p,
        actions: <>
          <c.IconButton onClick={()=>setProdutoModal(p)}>
            <icons.BsFillPencilFill />
          </c.IconButton>
          <c.IconButton onClick={()=> App.confirm(lang.confirm_del.replace('%d', p.NM_PRO),
            ()=>App.api('produtos::delete', {id: p.ID_PRO}).then(r=>{getProdutos();return r.status})
          )}>
            <icons.BsFillTrashFill />
          </c.IconButton>
        </>,
        DS_STS: p.CD_STS === '1' ? 'ATIVO' : p.CD_STS === '2' ? 'BLOQUEADO' : 'INATIVO',
        DS_TPPRO: ['','PRODUTO INDUSTRIALIZADO','MATÉRIA-PRIMA','SERVIÇO','INSUMO','OUTROS'][p.TP_PRO]??'NÃO DEFINIDO',
        NCM: p.DS_NCM + ' - ' + p.ID_NCM,
      }))
      setProdutos(r)
      setLoading({...loading, produtos: false})
    }
  )}

  function get(){
    getGrupos()
    getProdutos()
  }

  useEffect(()=>{ get()
    setReloader(()=>get)
  }, [App.prefs])

  useEffect(() => {
    setFiltered( produtos.filter(p=>!grupo.length||grupo.some(g=>g?.ID_GRPPRO===p?.ID_GRPPRO)) )
    let c = colunas_default
    if( grupo.length!==1 ) c.unshift([lang.grupoproduto, 'NM_GRPPRO'])
    setColunas(c)
  }, [produtos, grupo])

  return (<>
    <c.Frame title={lang.gruposprodutos} autoScroll={false} loading={loading.grupos}
      actions={{
        add: !loading.grupos ? ()=>setGrupoModal({ID_GRPPRO: 0}) : null,
        edit: !loading.grupos && grupo.length===1 ? ()=>setGrupoModal(grupo[0]) : null,
        delete: !loading.grupos && grupo.length===1 
          ? ()=>App.confirm(
              lang.confirm_del_grupo.replace('%d', grupo[0].NM_GRPPRO).replace('%e', produtos.filter(p=>p.ID_GRPPRO===grupo[0].ID_GRPPRO).map(p=>p.NM_PRO).slice(0,5).join(', ')),
              ()=>App.api('produtos::deleteGrupo', {id: grupo[0].ID_GRPPRO}).then(r=>{setGrupo([]);getGrupos();return r.status})
            )
          : null,
      }}
    >
      <div className="f w100 g1">
        <c.Select value={grupo.map(g=>g.ID_GRPPRO)} className="f4" onChange={v=>setGrupo(grupos.filter(g=>v.some(v=>v.value===g.ID_GRPPRO)))} 
          options={grupos?.map(g=>({value: g.ID_GRPPRO, label: g.NM_GRPPRO}))} label={lang.grupo_produtos} multi 
          searchable />

        <button onClick={()=>setShowUndMdd(true)}><icons.FaBalanceScaleLeft />{lang.und_medida}</button>
        <button onClick={()=>setNcmModal(true)}><icons.BsReverseListColumnsReverse />{lang.ncm}</button>
      </div>
    </c.Frame>
  
    <c.Frame title={lang.produtos} autoScroll={false} loading={loading.produtos}
      actions={{
        add: ()=>setProdutoModal({}),
        filter: {onClick:()=>setProdutoShowFilters(!produtoShowFilters), value: produtoShowFilters}
      }}
      control={<c.Input placeholder={lang.busca} value={produtoBusca} onChange={e=>setProdutoBusca(e.target.value)} clearable />}
    >
      <c.Table columns={colunas} data={filtered} search={produtoBusca} showFilters={produtoShowFilters} loading={loading.produtos} />
    </c.Frame>

    {grupoModal!==null && 
      <GrupoModal grupo={grupoModal} onClose={()=>setGrupoModal(null)} onFinish={()=>getGrupos()} />
    }

    {!!produtoModal && 
      <ProdutoModal grupos={grupos} produto={produtoModal} onClose={()=>setProdutoModal(null)} onFinish={()=>getProdutos()} />}

    {showUndMdd && <UnMddModal onClose={()=>setShowUndMdd(false)} />}

    {ncmModal && <NcmModal onClose={()=> setNcmModal(false)}/>}
  </>);
}


function NcmModal({onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.produtos}

  const [ncm, setNcm] = useState([]),
        [loading, setLoading] = useState(null),
        [filter, setFilter] = useState(null)

  useEffect(()=>{
    setLoading(true)
    App.api('produtos::getNCMs').then(r=>{setNcm(r.results);setLoading(false)})
  }, [])
      
  return(
    <c.Modal title={lang.ncm} onClose={onClose} cancelText={lang.sair}>
      {loading ? <App.LoadingComponent /> : 
        <div className="content f f-column" style={{overflowY: 'auto', width: '50vw', maxHeight:'70vh'}}>  
          <c.Input value={filter} onChange={e=>setFilter(e.target.value)} label={lang.busca} />
          <c.Table 
            data={ncm.filter(n=>!filter||n.DS_NCM.toUpperCase().includes(filter.toUpperCase())||n.ID_NCM.replaceAll('.','').includes(filter.replaceAll('.','')))} 
            columns={[[lang.ncm, 'ID_NCM'],[lang.descncm, 'DS_NCM']]} />
        </div>
      }
    </c.Modal>
  )
}


function UnMddModal({onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.produtos},
        icons = App.icons

  const [undmdd, setUndMdd] = useState([]),
        [loading, setLoading] = useState(null),
        [undmddShowFilters, setUndMddShowFilters] = useState(false),
        [undmddBusca, setUndMddBusca] = useState(''),
        [undmddView, setUndMddView] = useState([]),
        [data, setData] = useState({}),
        [incluir, setIncluir] = useState(false)

  useEffect(()=>{
    setLoading(true)
    get()
  },[incluir])



  function get() {
    App.api('produtos::getMedidas').then(
      (r) =>{
        setLoading(true)
        setUndMdd(r.results)
        setUndMddView(r.results)
        setLoading(false)
      })
  }

  function submit() {
    setLoading(true);
    return App.api('produtos::saveMedidas', {...data}).then(r=>{
      get();
      setData({})
      return r.status
    })
  }

  return(
    <c.Modal title={lang.und_medida} onClose={onClose} cancelText={lang.sair}>
    <div className="w100">
            <c.Frame
              autoScroll={false}
              actions={{
                add : ()=> (setData({}), setIncluir(true)),
                filter: {
                  onClick: () => setUndMddShowFilters(!undmddShowFilters),
                  value: undmddShowFilters,
                },
              }}
              control={
                <c.Input
                  placeholder={lang.busca}
                  value={undmddBusca}
                  onChange={(e) => setUndMddBusca(e.target.value)}
                />
              }
            >
        <div className="content f f-column" style={{overflowY: 'auto', width: '70vw', maxHeight:'70vh'}}>
          {incluir && (
            <div className="g1 f f-row"> 
              <c.Input value={data.ID_UNDMDD??''} onChange={e=>setData({...data, ID_UNDMDD: e.target.value})} label={lang.id_und_medida} required style={{width: '11%'}}/>
              <c.Input value={data.NM_UNDMDD??''} onChange={e=>setData({...data, NM_UNDMDD: e.target.value})} label={lang.und_medida} required style={{width: '26.3%'}}/>
              <c.Select value={data?.ID_UNDEQV??''} onChange={v=>setData({...data, ID_UNDEQV: v.value, NR_UNDEQV: '1'})}
                options={undmdd.map(u => ({value: u?.ID_UNDMDD, label: u?.NM_UNDMDD + ' ( ' + (u.ID_UNDMDD??'-') + ' )'}))}
                label={lang.unddEQV} searchable style={{width: '33.7%'}}/>
              <c.Input type="number" value={data.NR_UNDEQV??''} onChange={e=>setData({...data, NR_UNDEQV: e.target.value})} label={lang.equivalencia} style={{width: '22%'}}/>
              <div className="f center">
              <c.IconButton style={{color: 'var(--' + (!!data.ID_UNDMDD && !!data.NM_UNDMDD ? 'success' : 'colorAlpha') + ')'}} disabled={!data.ID_UNDMDD || !data.NM_UNDMDD} onClick={()=> (setIncluir(false), submit(), get())}><icons.BsCheckSquareFill /></c.IconButton>
              <c.IconButton style={{color: 'var(--danger)'}} onClick={()=> (setIncluir(false), setData({}))}><icons.BsXSquareFill /></c.IconButton>
              </div>
            </div>

          )}
          <c.Table columns={[
            [lang.id_und_medida, 'ID_UNDMDD'],
            [lang.und_medida, 'NM_UNDMDD'],
            [lang.unddEQV, 'ID_UNDEQV'],
            [lang.equivalencia, 'NR_UNDEQV'],
            [lang.acao, 'actions', {display: 'flex', justifyContent: 'center'}]
          ]} data={undmddView.map(u => ({...u,
            ID_UNDMDD:  u.ID_UNDMDD,
            NM_UNDMDD:  u.NM_UNDMDD,
            ID_UNDEQV:  u.ID_UNDEQV,
            NR_UNDEQV:  String(u.NR_UNDEQV ? parseFloat(u.NR_UNDEQV) : 0),
            actions: <c.IconButton  onClick={() => (setData({...u, NR_UNDEQV: u.NR_UNDEQV ? parseFloat(u.NR_UNDEQV) : ''}), setIncluir(true))}>
              <icons.BsFillPencilFill />
            </c.IconButton>        
          }))} 
          showFilters={undmddShowFilters}
          search={undmddBusca}/>
        </div>
        
    </c.Frame>
    </div>
    </c.Modal>
  )
}

function GrupoModal({grupo, onClose, onFinish}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.produtos}

  const [_grupo, setGrupo] = useState(grupo)

  return(
    <c.Modal title={!!_grupo?.ID_GRPPRO ? lang.editar_grupo : lang.criar_grupo} onClose={onClose} 
      onFinish={()=>App.api('produtos::saveGrupo', {..._grupo}).then(()=>{onFinish(); return true})}
      validate={!!_grupo.NM_GRPPRO&&!!_grupo.NM_GRPPRORED&&!!_grupo.CD_STS}
    >
      <div className="g1 f f-column">
        <c.Input onChange={e=>setGrupo({..._grupo, NM_GRPPRO: e.target.value})} value={_grupo?.NM_GRPPRO ?? ''} label={lang.grupoproduto} required />
        <c.Input onChange={e=>setGrupo({..._grupo, NM_GRPPRORED: e.target.value})} value={_grupo?.NM_GRPPRORED ?? ''} label={lang.grupoprodutored} required />
        <c.Select value={_grupo?.CD_STS} onChange={(e)=>{setGrupo({..._grupo, CD_STS : e.value})}}
          options={[{value: '1', label: 'Ativo'},{value: '2', label: 'Bloqueado'},]} label= {lang.statusgrupo} required />
      </div> 
    </c.Modal>
  )
}

function ProdutoModal({produto, grupos, onClose, onFinish}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.produtos}

  const [_produto, setProduto] = useState({...produto, CD_STS: produto.CD_STS??'1'}),
        [loading, setLoading] = useState(null),
        [undMdd, setUndMdd] = useState([])

  useEffect(()=>{
    setLoading(true)
    App.api('produtos::getMedidas').then(r=>{setUndMdd(r.results);setLoading(false)})
  },[])




  return(
    <c.Modal title={!!_produto.ID_PRO ? lang.editar_produto : lang.novo_produto} onClose={onClose} 
      onFinish={()=>App.api('produtos::save', {..._produto}).then(r=>{onFinish();return true})} 
      validate={!!_produto.NM_PRO&&!!_produto.NM_PRORED&&!!_produto.CD_STS&&!!_produto.TP_PRO&&!!_produto.ID_UNDMEXP&&!!_produto.ID_UNDMPCO&&!!_produto.ID_UNDMQTD&&!!_produto.ID_GRPPRO&&!!_produto.ID_NCM&&!!_produto.DS_NCM}
    >
      {loading ? <App.LoadingComponent /> : 
        <div className="g1 f f-wrap" style={{width: '800px'}}>
          <c.Input onChange={e=>setProduto({..._produto, NM_PRO: e.target.value})} value={_produto?.NM_PRO ?? ''} label={lang.nome} required className="w50"/>
          <c.Select value={_produto?.CD_STS ?? '1'} onChange={(e)=>{setProduto({..._produto, CD_STS : e.value})}}
            options={[{value: '1', label: 'Ativo'},{value: '2', label: 'Bloqueado'},]} label= {lang.status} required className="f1"/>
          <c.Input onChange={e=>setProduto({..._produto, NM_PRORED: e.target.value})} value={_produto?.NM_PRORED ?? ''} label={lang.nomered} required className="w50" />
          <c.Select value={_produto?.TP_PRO ?? ''} onChange={(e)=>{setProduto({..._produto, TP_PRO : e.value})}}
            options={[
              {value: '1', label: lang.produto_industrializado},
              {value: '2', label: lang.materia_prima},
              {value: '3', label: lang.servico},
              {value: '4', label: lang.insumo},
              {value: '5', label: lang.outros},
              {value: '',  label: lang.nao_definido}
            ]} label={lang.tipo_do_produto} required className="f1"/>
          <c.Select value={_produto?.ID_UNDMQTD ?? ''} onChange={v=>setProduto({..._produto, ID_UNDMQTD: v.value})} 
            options={undMdd?.map(u => ({value: u?.ID_UNDMDD, label: u?.NM_UNDMDD + ' ( ' + u.ID_UNDMDD + ' )'}))} 
            label= {lang.unmddQTD} searchable required className="f1"/>
          <c.Select value={_produto?.ID_UNDMPCO ?? ''} onChange={v=>setProduto({..._produto, ID_UNDMPCO: v.value})} 
            options={undMdd?.map(u => ({value: u?.ID_UNDMDD, label: u?.NM_UNDMDD + ' ( ' + u.ID_UNDMDD + ' )'}))} 
            label= {lang.unmddPCO} searchable required className="f1"/>
          <c.Select value={_produto?.ID_UNDMEXP ?? ''} onChange={v=>setProduto({..._produto, ID_UNDMEXP: v.value})} 
            options={undMdd?.map(u => ({value: u?.ID_UNDMDD, label: u?.NM_UNDMDD + ' ( ' + u.ID_UNDMDD + ' )'}))} 
            label={lang.unmddEXP} searchable required className="f1"/>
          <c.Select value={_produto?.ID_GRPPRO ?? ''}  onChange={v=>setProduto({..._produto, ID_GRPPRO:  v.value})} 
            options={grupos?.filter(g=>g.CD_STS!=='2')?.map(p => ({value: p?.ID_GRPPRO, label: p?.NM_GRPPRO}))} 
            label= {lang.grupo} required searchable className="w100"/>
          <c.Input onChange={e=>setProduto({..._produto, ID_NCM: e.target.value})} 
            onBlur={e=>App.api('produtos::getNcmByID', {ID_NCM: e.target.value?.replaceAll('.', '')})
              .then(r=>setProduto({..._produto, ID_NCM: r.results.ID_NCM??e.target.value, DS_NCM: r.results.DS_NCM}))} 
              value={_produto?.ID_NCM??''} label={lang.ncmid} className="f1" />
          <c.Input disabled value={_produto?.DS_NCM??'NCM não encontrado!'} className="w2-3" label={lang.descncm}/>
        </div>
      }
    </c.Modal>
  )
}