import { useState, useEffect, useContext, useDeferredValue } from "react";
import { AppContext, log } from "../../App";
import * as c from "../../c";
import * as f from "../../f"

// import { Map, GeoJSON, TileLayer, Marker, Popup } from "react-leaflet"
// import L from "leaflet"


export default function TarifasModal({ onClose, onFinish, itinerario }) {
    const App = useContext(AppContext),
      lang = {
        ...App.lang.global,
        ...App.lang.transportadoras,
        ...App.lang.tarifas
      },
      icons = App.icons;
  
    const [loading, setLoading] = useState({}),
      [busca, setBusca] = useState(""),
      [incluir, setIncluir] = useState(null),
      [editing, setEditing] = useState(null),
      [tarifas, setTarifas] = useState([]),
      [tarifasFilter, setTarifasFilter] = useState(false),
      [tarifasBuca, setTarifasBusca] = useState(""),
      [transportadoras, setTransportadoras] = useState([]),
      [data, setData] = useState({});
  
    function get() {
      setLoading(true)
      App.api('tarifas::get', {ID_ITINERARIO: itinerario.ID_UOCC}).then(r=>{
        setTarifas(r.results);
        // setTransportadoras(r.results.map(t => ({value: t.NR_CNPJTRP, label: t.NM_PESTRP})));
        setLoading(false);
        })

    }

    useEffect(() => {
        console.log(itinerario)
    }, [data])
  
    useEffect(() => {
      get();
    }, []);

    useEffect(() => {
        App.api('tarifas::getTransportadoras').then(r=>{
            setTransportadoras(r.results);
        })
    },[incluir])
  
    function submit() {
      return App.api("tarifas::save", {
        ...data,
        ID_OBJ: itinerario.ID_UOCC,
      }).then((r) => {
        get();
        return r.status;
      });
    }


  
    return (
      <c.Modal  contentHeight={250}  title={lang.tarifa_itinerario + itinerario.ID_UOCC + ' ( #ref ' + itinerario.ID_EXT + ' )'} loading={loading} onClose={onClose}>
        <div className="w100">
          <c.Frame
            autoScroll={false}
            actions={{
              add: () => (setData([]), setIncluir(true)),
              filter: {
                onClick: () => setTarifasFilter(!tarifasFilter),
                value: tarifasFilter,
              },
            }}
            control={
              <c.Input
                placeholder={lang.busca}
                value={tarifasBuca}
                onChange={(e) => setTarifasBusca(e.target.value)}
              />
            }
          >
            {incluir && (
              <div className="g1 f f-row">
                <c.Select
                  className="f1"
                  value={data.NR_CNPJTRP?? 0}
                  onChange={(e) => setData({ ...data, NR_CNPJTRP: e.value })}
                  label={lang.transportadora}
                  options={transportadoras}
                  required
                />
                <c.Select
                  className="f1"
                  value={data.CD_STS?? '1'}
                  onChange={(e) => setData({ ...data, CD_STS: e.value })}
                  label={lang.situacao}
                  options={[{value: '1', label: 'Ativo'}, {value: '-3', label: 'Inativo'}]}
                  required
                />
                <c.Input
                  className="f3"
                  value={data.VL_TRF ?? ""}
                  onChange={(e) =>
                    setData({ ...data, VL_TRF: e.target.value })
                }
                  label={lang.valor}
                  type="number"
                  required
                />
                <c.Input
                  required
                  label={lang.inicio}
                  onChange={(e) => setData({ ...data, DT_INIVLD:App.moment(e.target.value, "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    )})}
                  type="Date"
                  value={App.moment(data.DT_INIVLD, "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  )}
                />
                <div className="f f-row">
                <c.IconButton  style={{fontSize: '20px', color: 'var(--' + ((!!data.NR_CNPJTRP && !!data.CD_STS && !!data.VL_TRF && !!data.DT_INIVLD) ? 'success' : 'colorAlpha') + ')'}} disabled={!data.NR_CNPJTRP && !data.CD_STS && !data.VL_TRF && !data.DT_INIVLD} onClick={()=> (setIncluir(false), submit(), get())}><icons.BsCheckSquareFill /></c.IconButton>
                <c.IconButton  style={{fontSize: '20px', color: 'var(--danger)'}} onClick={()=> (setIncluir(false), setData({}))}><icons.BsXSquareFill /></c.IconButton>
  
                </div>
              </div>
            )}
            <c.Table
              data={tarifas.map((tp) => ({
                ...tp,
                VL_TRF: "R$ " + tp.VL_TRF,
                actions: (
                  <c.IconButton
                    onClick={() => (setData({ ...tp }), setIncluir(true))}
                  >
                    <icons.BsFillPencilFill />
                  </c.IconButton>
                ),
              }))}
              columns={[
                ["#", "ID_UOCC", {}, "f center"],
                [lang.cnpj_transportadora, "NR_CNPJTRP", {}, "f center"],
                [lang.nome_transportadora, "NM_PESTRP", {}, "f center"],
                [lang.valor, "VL_TRF", {}, "f center"],
                [lang.inicio, "DT_INIVLD", {}, "f center"],
                [lang.acao, "actions", {}, "f center"],
              ]}
              search={tarifasBuca}
              showFilters={tarifasFilter}
              style={{ overflowY: "auto", width: "70vw", maxHeight: "70vh" }}
            />
          </c.Frame>
        </div>
      </c.Modal>
    );
  }
  
  
