import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as f from '../../f'
import * as p from '../'

export default function Transportadoras({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportadoras},
        icons = App.icons

  const [loading, setLoading] = useState({}),
        [busca, setBusca] = useState(''),
        [incluir, setIncluir] = useState(null),
        [data, setData] = useState([]),
        [_data, set_Data] = useState([])

  function get(){
    setLoading(true)
    App.api('transportadoras::get').then(r=>{setData(r.results); setLoading(false)})
  }

  useEffect(()=>{
    set_Data(data.map((d,i)=>({...d,
      CNPJ: f.formatCnpj(d.NR_CNPJTRP),
      ENDERECO: d.NM_LGD + ', ' + d.NR_LGD,
      MUNICIPIO_UF: d.NM_LOC + '/' + d.ID_UF,
      CEP: f.formatCEP(d.CD_CEP, true),
      SELECTION: <c.Switch checked={d.IN_TRP==='T'} onChange={e=>{
          App.api('transportadoras::vincular',{nr_cnpjtrp: d.NR_CNPJTRP, in_insert: e.target.checked})
          data[i].IN_TRP=e.target.checked?'T':'F'; setData([...data])
        }} />,
    })))
  },[data])

  useEffect(()=>{get()
    setReloader(()=>get)
  }, [])

  return(<>
  <c.Frame title={lang.transportadoras} loading={loading}
    actions={{
      add: ()=>setIncluir(true)
    }}
    control={<c.Input placeholder={lang.busca} value={busca} onChange={e=>setBusca(e.target.value)} clearable />}
  >
    <c.Table data={_data} columns={[
      ['', 'SELECTION'],
      [lang.cnpj, 'CNPJ'],
      [lang.nome, 'NM_TRP'],
      [lang.endereco, 'ENDERECO'],
      [lang.municipio_uf, 'MUNICIPIO_UF'],
      [lang.cep, 'CEP'],
    ]} search={busca} />
  </c.Frame>

  {incluir&&<p.ParceirosModal tipo={'TRP'} onClose={()=>{get();setIncluir(null)}} />}
  </>)
}