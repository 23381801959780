import { useContext, useState, useRef, useEffect } from 'react'
import { AppContext, log } from '../../App'

import * as c from '../'

export default function Modal({ children, smallWidth=null, middleWidth=null, largeWidth=null, fullWidth=null, contentHeight=null,
  onClose=()=>{}, onCancel=()=>{}, onFinish=null, control=null, extraControls=null, width=null,
  title, steps=null, onStepChange=()=>{}, validate=true, successMsg=null, okText='', cancelText='', loading=null
}){
  const App = useContext(AppContext),
        lang = App.lang.global,
        icons = App.icons

  const modalRef = useRef(null)
  const [closing, setClosing] = useState(false),
        [activeStep, setActiveStep] = useState(0),
        [isDragging, setIsDragging] = useState(false),
        [position, setPosition] = useState({x:null, y:null}),
        [offset, setOffset] = useState({ x: null, y: null })

  function realClose(){
    setClosing(true)
    setTimeout(()=>onClose(), 600)
  }

  function realFinish(){
    const f = onFinish()
    //onFinish()
    const msg = successMsg===null ? App.lang.global.success : successMsg

    //realClose()
    // if(successMsg) App.toast.success( msg ) 

    if( f instanceof Promise ){
      f.then(r=>{
        if( r?.status || r instanceof Promise || r === true ) {
          realClose()
          if(msg) App.toast.success( msg ) 
        }
      })
    }else{
      if( !!f ) {
        realClose()
        if(msg) App.toast.success( msg )
      }
    }
  }

  function lockscreenClick(e){
    // if( modalRef.current && !modalRef.current.contains(e.target) )
    //   App.confirm('Alterações podem ter sido feitas. Deseja sair?', ()=>{realClose();return true})
      //realClose()
  }

  function handleKeyDown(e) {if (e.keyCode === 27) realClose()}



  const handleMouseDown = (e) => {
    setIsDragging(true)
    setOffset({ x: e.clientX - position.x??0, y: e.clientY - position.y??0 })
  }

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({ x: e.clientX - offset.x??0, y: e.clientY - offset.y??0 });
    }
  }

  const handleMouseUp = () => setIsDragging(false)

  useEffect(()=>{
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  useEffect(()=>onStepChange(activeStep),[activeStep])

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging])


  return (
    <div className={['lockscreen',(closing?'closing':'')].join(' ')} 
      onClick={lockscreenClick}
    >
      <div className={['modal',(largeWidth?'large-width':''),(fullWidth?'full-width':''),(middleWidth?'middle-width':'')].join(' ')} ref={modalRef}
        style={{
          width: width, 
          //top: position.y??'', 
          //left: position.x??''
        }} 
      >
        <div className='modal-header' style={{cursor: isDragging?'move':'auto'}}>
          <span className='modal-title f g2 center-v' 
            //onMouseDown={handleMouseDown}
          >{title}</span>
          {!!control&&<div className='frame-title-control'>{control}</div>}
          <c.IconButton onClick={realClose}>{App.icons.MdClose({size: 16})}</c.IconButton>
        </div>

        {!!steps && !loading &&
          <c.Stepper steps={steps} active={activeStep} setActiveStep={setActiveStep} className='modal-stepper'/>
        }

        <div className='modal-content' style={{minHeight: contentHeight?contentHeight:'auto'}}>
          {(loading && <App.LoadingComponent />) || children}
        </div>

        <div className='modal-buttons'>
          <div className='modal-buttons-extra'>
            {!!steps && !loading && <>
              <button onClick={()=>{setActiveStep(activeStep-1)}} disabled={activeStep===0} className='warning'><icons.MdNavigateBefore />{lang.voltar}</button>
              <button onClick={()=>{setActiveStep(activeStep+1)}} disabled={activeStep===steps.length-1} className='warning'>{lang.proximo}<icons.MdNavigateNext /></button>
            </>}
            {extraControls}
          </div>
          <div className='modal-buttons-main'>
            <button onClick={()=>{realClose();onCancel()}} className='danger' disabled={!!loading}>{cancelText||App.lang.global.cancel}</button>
            {!!onFinish && <button onClick={realFinish} className='success' disabled={!validate || !!loading}>{okText||App.lang.global.save}</button>}
          </div>
        </div>
      </div>
    </div>
  )
}