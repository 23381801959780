import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as f from '../../f'
import * as p from '../'

export default function DistribuirLote({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.distribuir_cotas, ...App.lang.menu},
        icons = App.icons,
        permitions = App.components.distribuicao_lote ?? {}

  const [dtSelected, setdtSelected] = useState(),
        [selectedFile, setSelectedFile] = useState(null),
        [loading, setLoading] = useState(false),
        [errors, setErrors] = useState({}),
        [semanas, setSemanas ] = useState([]),
        [semanaSelected, setSemanaSelected] = useState(null),
        [data, setData] = useState([]),
        [search, setSearch] = useState(''),
        [showFilters, setShowFilters] = useState(false)

  async function get(){
    setLoading(true)

    setData( semanaSelected
      ? (await App.api('DistribuirLote::get', {
            semanaSelected
          })).results 
      : [])

    setLoading(false)
  }


  async function getSemanas(){
    setSemanas( (await App.api('DistribuirLote::getSemanas')).results )
  }


  const InputCotaComponent = ({d, d_key}) => {
    return <>
      {!!d[Object.keys(d)[d_key] + '_ID_UOCC']
        ? 
          <div className="f center-v">
            {App.moment(Object.keys(data[0])[d_key]).diff(App.moment(),'days') >= 0
              && !permitions.disableEdition
              ? <>
                  <c.Input type="number" fit value={d[Object.keys(d)[d_key]]} style={{width: 50}} 
                    min={d[Object.keys(d)[d_key] + '_PRT_COUNT']}
                    onChange={e => {
                      d[Object.keys(d)[d_key] + '_oldValue'] = !d[Object.keys(d)[d_key] + '_oldValue']
                        ? d[Object.keys(d)[d_key]] : d[Object.keys(d)[d_key] + '_oldValue']
                      d[Object.keys(d)[d_key]] = parseInt(e.target.value)
                      setData([...data])
                    }}
                    onBlur={e => {
                      const val = Math.max( e.target.value, d[Object.keys(d)[d_key] + '_PRT_COUNT'] )
                      
                      d[Object.keys(d)[d_key]] = val

                      if( d[Object.keys(d)[d_key] + '_oldValue'] 
                        && parseInt(d[Object.keys(d)[d_key] + '_oldValue']) !== val
                      ){
                        delete d[Object.keys(d)[d_key] + '_oldValue']
                        App.api('DistribuirLote::changeCota', {
                          ID_UOCC: d[Object.keys(d)[d_key] + '_ID_UOCC'], 
                          QT_COTA: val
                        }).then(r => {
                          if( r.status ){
                            App.toast.success('Cota alterada com sucesso!')
                          }
                        })
                      }

                      setData([...data])
                    }}
                  />
                  <c.Span fit title={'Agendados'} value={'/ ' + d[Object.keys(d)[d_key] + '_PRT_COUNT']} style={{minWidth: 40}} />
                </>
              : <c.Span fit value={d[Object.keys(d)[d_key]] + ' / ' + d[Object.keys(d)[d_key] + '_PRT_COUNT']} style={{minWidth: 40}} />
            }
          </div>
        : '-----'
      }
    </>
  }


  useEffect(() => { getSemanas()
    setReloader(() => () => {
      setErrors({})
      setSemanaSelected(null)
      getSemanas()
    })
  }, [])


  useEffect(() => {
    if( !!selectedFile ) upload()

    async function upload(){
      setLoading(true)
      const reader = new FileReader()

      reader.onload = async e => {
        const r = (await App.api('DistribuirLote::upload', { file: e.target.result.split(',')[1] }))

        if( r.status ){
          let _errors = r.results.errors ?? {}
          setErrors( _errors )

          if( r.results.saved_counts > 0 ){
            if( _errors.count > 0 ){
              App.toast.warning('Planilha carregada, mas houveram erros!')
            }else{
              App.toast.success('Planilha carregada com sucesso!')
            }
            await getSemanas()
          }else{
            App.toast.warning('Não foi possível importar nenhum item da planilha devido aos erros abaixo!')
          }
        }

        setSelectedFile(null)
        setLoading(false)
      }
      reader.readAsDataURL(selectedFile)

      setSelectedFile(null)
    }
  }, [selectedFile])

  useEffect(() => {

    const intervaloSemana = semanas.map(s => {
      const startOfWeek = App.moment(s, 'YYYY-MM-DD').format('DD/MM/YYYY')
      const endOfWeek = App.moment(s, 'YYYY-MM-DD').add(6, "d").format('DD/MM/YYYY')

      return {startOfWeek, endOfWeek}
    })

    const Existe = intervaloSemana.map(s => {

      const start = App.moment(s.startOfWeek, 'DD/MM/YYYY').toDate(),
            end = App.moment(s.endOfWeek, 'DD/MM/YYYY').toDate()


      if( App.moment(dtSelected).isBetween(start, end, null, '[]') ){
        setSemanaSelected(App.moment(s.startOfWeek, 'DD/MM/YYYY').format('YYYY-MM-DD'))
        return true
        }
      
      return false
    },[])
    
    if( !Existe.includes(true) && semanas.length > 0){
      App.toast.error(lang.semanaNaoEncontrada)
      setdtSelected(App.moment(semanas[semanas.length - 1], 'YYYY-MM-DD').toDate())
    }

  }, [dtSelected])

  const hasNextWeek = () => {
    const intervaloSemana = semanas.map(s => {
      const startOfWeek = App.moment(s, 'YYYY-MM-DD').format('DD/MM/YYYY')
      const endOfWeek = App.moment(s, 'YYYY-MM-DD').add(6, "d").format('DD/MM/YYYY')

      return {startOfWeek, endOfWeek}
    })

    const nextWeek = App.moment(dtSelected, 'DD/MM/YYYY').add(7, 'd').toDate();
    return intervaloSemana.some(s => {
      const start = App.moment(s.startOfWeek, 'DD/MM/YYYY').toDate(),
            end = App.moment(s.endOfWeek, 'DD/MM/YYYY').toDate()
      return App.moment(nextWeek).isBetween(start, end, null, '[]');
    });
  }
  
  const hasPreviousWeek = () => {
    const intervaloSemana = semanas.map(s => {
      const startOfWeek = App.moment(s, 'YYYY-MM-DD').format('DD/MM/YYYY')
      const endOfWeek = App.moment(s, 'YYYY-MM-DD').add(6, "d").format('DD/MM/YYYY')

      return {startOfWeek, endOfWeek}
    })


    const previousWeek = App.moment(dtSelected, 'DD/MM/YYYY').subtract(7, 'd').toDate();
    return intervaloSemana.some(s => {
      const start = App.moment(s.startOfWeek, 'DD/MM/YYYY').toDate(),
            end = App.moment(s.endOfWeek, 'DD/MM/YYYY').toDate()
      return App.moment(previousWeek).isBetween(start, end, null, '[]');
    });
  }

  useEffect(() => {
    //if( !semanaSelected ){
      setSemanaSelected( semanas[0] )

      
    //}
  }, [semanas])


  useEffect(() => {
    if( semanaSelected ) {
      if(!!semanaSelected) {
        setdtSelected(App.moment(semanaSelected, 'YYYY-MM-DD').toDate())
      }
      get()
    }else{
      setData([])
      setLoading(null)
    }
  }, [semanaSelected])

      
  return(<>
    <c.Frame title={lang.distribuirLote} loading={loading}
      actions = {!permitions.disableEdition && {
        upload: () => document.getElementById('fileInput').click(),
        filter: {
          onClick: () => setShowFilters(!showFilters), 
          value: showFilters
        },
      }}
      control={<c.Input placeholder={lang.busca} value={search} onChange={e=>setSearch(e.target.value)} clearable />}
    >
      {
        (
          !!errors.err_cota_trp_ja_cadastrada?.length 
          || !!errors.err_itinerario_not_registered?.length
          || !!errors.err_produto_not_registered?.length
        ) &&
        <div className={'alert warning'}>
          <h2>{lang.processadoComErros}
            <c.IconButton icon={icons.MdClose} size={30} onClick={()=>setErrors({})} />
          </h2>
          <div className="content">
            {!!errors.err_cota_trp_ja_cadastrada?.length &&
              <>
                <h3>Cotas já cadastradas para este transportador neste itinerário.</h3>
                <ul>{errors.err_cota_trp_ja_cadastrada.map(err => <li>{err}</li>)}</ul>
              </>
            }
            {!!errors.err_itinerario_not_registered?.length &&
              <>
                <h3>{lang.ItinerariosNaoRegistrados}</h3>
                <ul>{errors.err_itinerario_not_registered.map(err => <li>{err}</li>)}</ul>
              </>
            }
            {!!errors.err_produto_not_registered?.length &&
              <>
                <h3>Produtos que não foram cadastrados na tabela de integração "de/para":</h3>
                <ul>{errors.err_produto_not_registered.map(err => <li>{err}</li>)}</ul>
              </>
            }
          </div>
        </div>
      }
      <c.Input 
        type="date" 
        label={lang.dataSelecionada}
        value={dtSelected ? dtSelected.toISOString().substring(0, 10) : ''}
        onChange={e => setdtSelected(App.moment(e.target.value).toDate())} 
        className="f1 w100"
        style={{marginRight: 10}} 
      />
      <c.Select label={"Semana"} 
        options={semanas.map(s => ({
          label: 'de ' + App.moment(s).format('DD/MM/YYYY') + ' a ' + (App.moment(s).add(6, 'days')).format('DD/MM/YYYY'), 
          value: s
        }))} 
        value={semanaSelected} onChange={e => (setSemanaSelected(e.value),
          setdtSelected(App.moment(e.value).toDate())
        )
        } 
      />

    <div className="row f-between"  style={{alignItems: 'center'}}>

      <h1>{App.moment(dtSelected).locale(lang.linguagem).format('MMMM').toUpperCase() + " " + lang.de.toUpperCase() + " " + App.moment(dtSelected).locale(lang.linguagem).format('YYYY')}</h1>
      <div>
      <button style={{marginRight: 10}} onClick={() => setdtSelected(App.moment().toDate())}>
        {lang.hoje}
      </button>
      <c.IconButton size={32} icon={icons.MdNavigateBefore} style={{marginRight: 10}} onClick={() => setdtSelected(App.moment(dtSelected, 'DD/MM/YYYY').subtract(7, 'd').toDate())} disabled={!hasPreviousWeek()}/>
      <c.IconButton size={32} icon={icons.MdNavigateNext} style={{marginRight: 10}} onClick={() => setdtSelected(App.moment(dtSelected, 'DD/MM/YYYY').add(7, 'd').toDate())} disabled={!hasNextWeek()}/>
      </div>
      </div>

        <c.Table isSub
        showFilters={showFilters}
        search={search}
          columns={[
            ['Itinerário', 'ID_OBJ'],
            ['Transportadora', '_TRP', {}, '', 'NM_TRP'],
            ['Origem', '_ORIGEM', {}, '', 'NM_ORI'],
            ['Destino', '_DESTINO', {}, '', 'NM_DST'],
            // ['CNPJ Transportadora', '_NR_CNPJTRP'],
            // ['Nome Transportadora', 'NM_PES'],
          ].concat(
            !!data[0]
              ? [3, 6, 9, 12, 15, 18, 21].map(n => 
                  [App.moment(Object.keys(data[0]??{})[n + 4]).format('DD/MM/YYYY'), Object.keys(data[0]??{})[n + 4], {}, '']
                )
              : []
          )}
          data={data.map(d => 
            [3, 6, 9, 12, 15, 18, 21].reduce((acc, cur) => ({...acc,
              [Object.keys(data[0])[cur + 4]]: InputCotaComponent({d: d, d_key: cur + 4}),
            }), {...d,
              NM_TRP: d.NM_TRP ?? '',
              _NR_CNPJTRP: f.formatCnpj(d.NR_CNPJTRP),
              _TRP: 
                  <div className="f g1 f-column">
                    <span>{d.NM_TRP??''}</span>
                    <span className="secondary-information nowrap">{f.formatCnpj(d.NR_CNPJTRP)}</span>
                  </div>,
              _ORIGEM: 
                  <div className="f g1 f-column">
                    <span>{d.NM_ORI}</span>
                    <span className="secondary-information nowrap">{f.formatCnpj(d.NR_CNPJORI)}</span>
                  </div>,
              _DESTINO: 
                  <div className="f g1 f-column">
                    <span>{d.NM_DST}</span>
                    <span className="secondary-information nowrap">{f.formatCnpj(d.NR_CNPJDST)}</span>
                  </div>,
    })
          )}
        />
      
    </c.Frame>

    <input type="file" id="fileInput" accept=".xlsx" style={{display: 'none'}}
      onChange={(e) => {setSelectedFile(e.target.files[0]); e.target.value = ''}} 
    />
  </>)
}