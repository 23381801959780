import React, { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../App'
import * as c from '../c'
import * as f from '../f'

export default function AnexarDcoAuxModal({portaria, onClose, onFinish}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.dfe, ...App.lang.transportador},
        icons = App.icons

  const [loading, setLoading] = useState(false),
        [files, setFiles] = useState([]),
        [tipo, setTipo] = useState(0)

  const tipos = [
    portaria.TP_OPRPRT === 'E' ? lang.anexar_ordem_carga : lang.anexar_ordem_descarga,
    lang.ticket_pesagem,
    lang.nota_de_venda
  ]

  async function submit(){
    setLoading(true)

    let ds_dco = ['XML'].includes( files[0].file.name.split('.').pop().toUpperCase() )
      ? await f.getFileString( files[0].file )
      : await f.fileToBase64( files[0].file )

    return App.api('portarias::anexarDcoAux', {
      ID_UOCC: portaria.ID_UOCC, 
      NM_DCO: files[0].name, 
      tipo: tipo + 1, 
      DS_DCO: ds_dco
    }).then(r => {
      onFinish()
      return r.status
    }).finally(() => setLoading(false))
  }

  return(
    <c.Modal title={'Anexar documento auxiliar - ' + portaria.ID_UOCC}
      onClose={onClose} validate={files.length===1} loading={loading} middleWidth successMsg={lang.vinculada_com_sucesso}
      onFinish={submit}
    >
      <c.Select label={'Tipo do documento'} options={tipos} value={tipo} onChange={e=>setTipo(e.value)} />

      <c.Upload 
        accept={{
          "application/pdf": ['.pdf'],
          "image/png": ['.png'],
          "image/jpeg": ['.jpg'],
          "application/xml": ['.xml'],
        }} 
        label="Arquivo aceito: PDF. JPG, PNG e XML. Máximo de 1 arquivo." maxFiles={1} files={files} onChange={f=>setFiles(f)}
      className='w100' />
    </c.Modal>
  )
}