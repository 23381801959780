import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as f from '../../f'

export default function GerencialQualidade(props) {
  const App = useContext(AppContext),
        lang = {...App.lang.gerencialQualidade, ...App.lang.global},
        icons = App.icons

  //const today = App.moment().format('yyyy-MM-DD')

  const [ lstDados, setLstDados ] = useState([]),
        [dtIni, setDtIni] = useState( App.moment().toDate() ),
        [dtFim, setDtFim] = useState( App.moment().toDate() ),
        [ lstDadosEncerradas, setLstDadosEncerradas ] = useState([]),
        [ filtro, setFiltro ] = useState({ini: dtIni??App.moment().toDate(), fim: dtFim??App.moment().toDate()}),
        [ lstFiltro, setLstFiltro ] = useState({}),
        [ loading, setLoading ] = useState(null)

  async function get(){
    setLoading(true)

    Promise.all([
      App.api('quality::getResultado', {
        periodos: [{
          ini: App.moment(dtIni).format('YYYY-MM-DD'), 
          fim: App.moment(dtFim).format('YYYY-MM-DD')  
        }],
        cnpjund:    filtro?.unidades,
        cnpjgrpcom: filtro?.grupos,
        cnpjclt:    filtro?.clientes,
        cnpjori:    filtro?.origens,
        id_pro:     filtro?.produtos,
      }),
      App.api('quality::getResultado', {
        encerradas: true,
        periodos: [{
          ini: App.moment(dtIni).format('YYYY-MM-DD'), 
          fim: App.moment(dtFim).format('YYYY-MM-DD')  
        }],
        cnpjund:    filtro?.unidades,
        cnpjgrpcom: filtro?.grupos,
        cnpjclt:    filtro?.clientes,
        cnpjori:    filtro?.origens,
        id_pro:     filtro?.produtos,
      })
    ]).then(r => {
      setLstDados(r[0].results)
      setLstDadosEncerradas(r[1].results)

      setLoading(false)
    })
  }

  function getListas(){
    dtIni&&dtFim&&App.api('quality::getListas', { 
      ini: App.moment(dtIni).format('YYYY-MM-DD'), 
      fim: App.moment(dtFim).format('YYYY-MM-DD')
    }).then(r=>{
      setLstFiltro(r.results)
    })
  }

  useEffect(()=>getListas(), [dtIni,dtFim])

  useEffect(() => {
    get()

    const reloadInterval = setInterval( get, 1000 * 60 * 10)

    return () => clearInterval(reloadInterval)
  }, [])

  const style = {minWidth: 400}

  return (<>
    <c.Frame className="filter-frame" autoScroll={false} flex style={{zIndex: 1}}>
      {/* <c.Input type="date" label="Data inical" value={filtro.ini} onChange={(e)=>setFiltro({...filtro, ini: e.target.value})} className="f1" />
      <c.Input type="date" label="Data final"  value={filtro.fim} onChange={(e)=>setFiltro({...filtro, fim: e.target.value})} className="f1" /> */}
      <c.DaterangePicker label='Data' startDate={dtIni} endDate={dtFim} onChange={(ini, fim)=>{setDtIni(ini);setDtFim(fim);setFiltro({...filtro, ini: ini, fim: fim})}} />

      <c.Select value={filtro?.unidades} onChange={v=>setFiltro({...filtro, unidades: v.map(v=>v.value)})} options={lstFiltro?.unidades} label={lang.unidades} multi style={style} searchable className="f4" />
      <c.Select value={filtro?.grupos}   onChange={v=>setFiltro({...filtro, grupos:   v.map(v=>v.value)})} options={lstFiltro?.grupos}   label={lang.grupos}   multi style={style} searchable className="f4" />
      <c.Select value={filtro?.clientes} onChange={v=>setFiltro({...filtro, clientes: v.map(v=>v.value)})} options={lstFiltro?.clientes} label={lang.clientes} multi style={style} searchable className="f4" />
      <c.Select value={filtro?.origens}  onChange={v=>setFiltro({...filtro, origens:  v.map(v=>v.value)})} options={lstFiltro?.origens}  label={lang.origens}  multi style={style} searchable className="f4" />
      <c.Select value={filtro?.produtos} onChange={v=>setFiltro({...filtro, produtos: v.map(v=>v.value)})} options={lstFiltro?.produtos} label={lang.produtos} multi style={style} searchable className="f4" />

      <button onClick={()=>get()} disabled={!!App.loading} className="f1">
        {icons.MdSearch({size: 24})} {App.lang.global.pesquisar}
      </button>
    </c.Frame>

    {loading ? <App.LoadingComponent /> :
      <>
        {lstDados?.map((e,i) => (
          <CardClassificacao key={i} filtro={filtro} lstFiltro={lstFiltro} dados={e} encerradas={false} />
        ))}

        {lstDadosEncerradas?.map((e,i) => (
          <CardClassificacao key={i} filtro={filtro} lstFiltro={lstFiltro} dados={e} encerradas={true} />
        ))}
      </>}
  </>)
}


function CardClassificacao(props) {
  const App = useContext(AppContext),
        lang = {...App.lang.gerencialQualidade, ...App.lang.global},
        icons = App.icons

  const [open, setOpen] = useState(false),
        [data, setData] = useState([]),
        [cardFilter, setCardFilter] = useState(false),
        [loadingAnalitico, setLoadingAnalitico] = useState(false)

  const colunas = [
    [lang.data, 'DT_PRT'],
    [lang.portaria, 'ID_UOCC'],
    [lang.situacao, 'DS_STS'],
    [lang.veiculo, 'ID_VCL'],
    [lang.peso_origem_t, 'QT_ORG'],
    [lang.peso_liquido_t, 'QT_PSG'],
    [lang.retencao_t, 'QT_RTN'],
    [lang.desconto_t, 'QT_AD'],
    [lang.classificacao, 'NM_CLSIRED'],
    [lang.valor, 'VL_CLSI'],
  ]

  useEffect(() => { open && getAnalitico() }, [open]);

  function getAnalitico() {
    setLoadingAnalitico(true)
    App.api("quality::getAnalitico", {
      encerradas: props.encerradas,
      periodos: [{ ini:  App.moment(props.filtro.ini).format('YYYY-MM-DD'), fim: App.moment(props.filtro.fim).format('YYYY-MM-DD') }],
      cnpjund: props.filtro.unidades?.length === 0 && props.lstFiltro.unidades?.length === 1
                ? props.lstFiltro.unidades
                : props.filtro.unidades,
      cnpjgrpcom: props.filtro.grupos,
      cnpjclt:    props.filtro.clientes,
      cnpjori:    props.filtro.origens,
      id_pro:     props.dados.codigo,
    }, true).then(r=>{
      setData( r.results )
      setLoadingAnalitico(false)
    })
  }

  return (<>
    <c.Frame className="CardClassificacao" flex
      title={props.dados.produto + ' - ' + props.dados.operacao + ' - ' + (props.encerradas?'Encerradas':'Em operação')}
    >
      <div className="infos_gerais">
        <div className="qnt">
          {icons.FaTruck({size:25})} {props.encerradas ? props.dados.encerrados : props.dados.emoperacao}
        </div>

        <div className="pesos">
          <span><icons.GiWeight size={25} /> {lang.peso_origem} <b>{f.formatNumber(props.dados.origem, 3)} t</b></span>
          {props.encerradas ? <>
            <span>{lang.peso_liquido}<b>{f.formatNumber(props.dados.liquido, 3)} t</b></span>
            <span>{lang.desconto}<b>{f.formatNumber(props.dados.desconto, 3)} t</b></span>
          </> : null}
        </div>

        <c.IconButton onClick={()=>setOpen(!open)} className="expansion-button">
          {open ? <icons.MdExpandLess size={24} /> : <icons.MdExpandMore size={24} />}
        </c.IconButton>
      </div>

      <div className="cards">
        {props.dados.classificacao.map((e, i) => {
          let n = e.nome.toUpperCase()
          const MIcon = () => 
              n.includes("UMIDADE") ? <icons.BsDropletHalf size={24} /> 
            : (n.includes("INSETO") ? <icons.MdPestControl size={24} /> 
            : (n.includes("IMPUREZA") ? <icons.MdCoronavirus size={24} /> 
            : (n.includes("ESVERDEADOS") ? <icons.MdGrass size={24} /> 
            : (n.includes("AVARIADO") ? <icons.GiEdgeCrack size={24} /> 
            : (n.includes("ESTRANHO") ? <icons.GiFragmentedMeteor size={24} />
            : (n.includes("QUEBRADO") ? <icons.GiBrokenPottery size={24} />
            : (n.includes("VEÍCULOS") ? <icons.FaTruck size={24} />
            : (n.includes("VOLUME") || n.includes("RETENÇÃO") || n.includes("DESCONTO") || n.includes("PESO") ? <icons.GiWeight size={24} />
            : <icons.GiBrokenPottery size={24} />
            ))))))))

          return <div key={i} className={['card', cardFilter===i?'active':null, e.indicador!=='NORMAL'?e.indicador:null].join(' ')}
            onClick={()=>{ setOpen(true); setCardFilter( cardFilter===i ? false : i ) }}>
            <div className="title"><MIcon />{e.nome}</div>
            <div className="content">
              <div className="min"><span>{e.min}</span><span>Min</span></div>
              <div className="med"><span>{e.avg}</span><span>Méd</span></div>
              <div className="max"><span>{e.max}</span><span>Max</span></div>
            </div>
            <div className="footer">Anormalidade <b>{e.anormalidade??0}</b> (<icons.FaTruck /> {e.anormais??0})</div>
          </div>
        })}
      </div>
          
      {open && 
        (loadingAnalitico 
          ? <App.LoadingComponent /> 
          : <c.Table columns={colunas} data={data.filter(v=>cardFilter===false || v.NM_CLSIRED===props.dados.classificacao[cardFilter].nome).map(d=> ({...d,
            VL_CLSI: parseFloat(d.VL_CLSI)}))} />
        )
      }
    </c.Frame>
  </>);
}
