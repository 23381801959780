import { useContext, useState, useEffect } from 'react'
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as p from '../../p'
import * as f from '../../f'

export default function FreteFechamento({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador},
        icons = App.icons

  const [data, setData] = useState([]),
        [openDescargaModal, setOpenDescargaModal] = useState(null),
        [loading, setLoading] = useState(null)

  async function get(){
    setLoading(true)
    setData( (await App.api('portarias::consultaFechar')).results )
    setLoading(false)
  }

  useEffect(() => { get()
    setReloader(()=>get)
  }, [])

  return(<>
    <c.Frame title={"Veículos"} loading={loading}>
      <c.Table hasSub={'_SUB'}
        columns={[
          ["#",'ID_UOCC'],
          ["Data operação",'DT_PRV'],
          ["Op.",'_DS_OPRPRT'],
          ["Veículo",'LST_VCL'],
          ["Contrato",'ID_CN'],
          ["Origem",'NM_ORI'],
          ["Destino",'NM_DST'],
          ["Produto",'NM_PRO'],
          ["Peso origem",'QT_SAI'],
          ["NFe",'LST_NFE'],
          ["CTe",'LST_CTE'],
          ["Ticket descarga",'_IN_DOC'],
        ]}
        data={
          data.map(item => ({...item,
            _DS_OPRPRT: item.DS_OPRPRT==='Carga'?<icons.CargaIcon title={lang.carga} size={24} />:<icons.DescargaIcon title={lang.descarga} size={24} />,
            _IN_DOC: item.TP_OPRPRT !== 'E' || item.IN_NFE !== true || item.IN_CTE !== true
                      ? <icons.MdBlock style={{color: 'var(--danger)'}} />
                      : item.IN_DOC === 'T'
                        ? <icons.BsCheck2Circle size={16} style={{color: 'var(--success)'}} />
                        : <c.IconButton title={'Anexar ticket'} icon={icons.MdAttachFile} size={24} onClick={() => setOpenDescargaModal(item)} />,
            _SUB: !!item.DOCS?.length &&
                    <c.Table isSub hasSub={'_SUB'} // dcos
                      columns={[
                        ['','_VALIDATION_ICON',{},'force-fit'],
                        ['Data','DT_DCO'],
                        ['Tipo','_TP_DF'],
                        ['Número/Série','_NUM_NOTA'],
                        ['Chave','NR_NFE'],
                        ['Emitente','NM_PES'],
                        ['Quantidade','_QT_ORG'],
                        ['Valor','_VL_ORG'],
                      ]} 
                      data={item.DOCS?.map(dco => {
                        const valid = dco.validacoes?.every(v => v.CD_STS === '-1'),
                              block = dco.validacoes?.some(v => v.CD_STS === '-3'), 
                              notif = !valid && !block && dco.validacoes?.some(v => v.CD_STS === '1')
                    
                        return {...dco,
                          _VALIDATION_ICON: 
                            valid ? <icons.BsCheck2Circle size={16} style={{color: 'var(--success)'}} />
                            : block ? <icons.MdCancel size={16} style={{color: 'var(--danger)'}} />
                            : notif ? <icons.MdReportProblem size={16} style={{color: 'var(--warning)'}} /> : '',
                          _TP_DF: dco.TP_DF==='55'?'NFe':'CTe',
                          _NUM_NOTA: dco.NR_DCO+'/'+dco.CD_DCO,
                          _QT_ORG: parseFloat(dco.QT_ORG??0).toLocaleString() + 'Kg',
                          _VL_ORG: 'R$' + parseFloat(dco.VL_ORG??0).toLocaleString(),
                          _SUB: !!dco.validacoes && 
                                  <c.Table isSub
                                    columns={[
                                      ['','_VALIDATION_ICON',{},'force-fit'],
                                      ['Regra','DS_VLD'],
                                      ['No agendamento','VL_PRT'],
                                      ['No XML','VL_XML'],
                                    ]}
                                    data={dco.validacoes.map(v => ({...v,
                                      _VALIDATION_ICON: 
                                        v.CD_STS === '-1' ? <icons.BsCheck2Circle size={16} style={{color: 'var(--success)'}} />
                                        : v.CD_STS === '-3' ? <icons.MdCancel size={16} style={{color: 'var(--danger)'}} />
                                        : v.CD_STS === '1' ? <icons.MdReportProblem size={16} style={{color: 'var(--warning)'}} /> : '',
                                    }))}
                                  />
                        }
                      })}
                    />
          }))
        }
      />
    </c.Frame>

    {!!openDescargaModal && <OpenDescargaModal cota={openDescargaModal} onClose={() => {setOpenDescargaModal(null); get()}} />}
  </>)
}

function OpenDescargaModal({cota, onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador}

  const [loading, setLoading] = useState(null),
        [data, setData] = useState({})

  async function submit(){
    setLoading(true)
    const r = (await App.api('resumoFrete::gravaDescarga', {
      id_uocc: cota.ID_UOCC,
      qt_peso: data.qt_peso,
      dt_peso: data.dt_peso,
      ds_obs: data.ds_obs,
      tp_ext: data.images[0].name.split('.').pop(),
      imagem: (await f.fileToBase64( data.images[0].file )),
      ds_tag: 'ticket_descarga',
    }))
    setLoading(false)
    return r.status
  }

  return(
    <c.Modal title={'Anexar ticket'} onClose={onClose} onFinish={submit} loading={loading} largeWidth
      validate={!!data.qt_peso && !!data.dt_peso && !!data.images?.length}
    >
      <div className='f g1'>
        <c.Span label={lang.veiculo} value={cota.ID_VCL} />
        <c.Span label={lang.peso_origem} value={cota.QT_SAI} />
        <c.Span label={lang.nfe} value={cota.LST_NFE} />
        <c.Span label={lang.cte} value={cota.LST_CTE} />
        <c.Span label={lang.produto} value={cota.NM_PRO} />
        <c.Span label={lang.destino} value={cota.NM_DST} />
      </div>

      <div className='f g1'>
        <div className='w1-2 f g1 f-column w50'>
          <div className='f g1 f-column'>
            <div className='f g1'>
              <c.Input label={"Peso descarga"} type="number" error={!data.qt_peso} required value={data.qt_peso} onChange={e => setData({...data, qt_peso: e.target.value})} className="w1-3" />
              <c.Span label={"Quebra (%)"} value={<>{Number(data.qt_peso??0) - Number(cota.QT_SAI??0)} ({(Number(data.qt_peso??0) - Number(cota.QT_SAI??0)) / Number(cota.QT_SAI??1) * 100})</>} className="w1-3" />
              <c.Input label={"Data/hora descarga"} error={!data.dt_peso} required type="date" value={data.dt_peso} onChange={e => setData({...data, dt_peso: e.target.value})} className="w1-3" />
            </div>
            <c.Input label={lang.observacao} value={data.ds_obs} onChange={e => setData({...data, ds_obs: e.target.value})} />
          </div>

          <c.Upload accept={{"image/png": ['.png'], "image/jpeg": ['.jpg'],
              // "application/pdf": ['.pdf'],               
            }} 
            label="Arquivo aceito: JPG, e PNG. Máximo de 1 arquivo." maxFiles={1} files={data.images??[]} onChange={f => setData({...data, images: f})}
            className='w100' />
        </div>

        <div className='w50'>
          <c.Span label={"Pré-visualização"} className='center-v'
            value={!!data?.images?.[0] 
                    ? <img src={data?.images?.[0]?.preview} alt="Preview" className='w100' style={{maxHeight: 400}} />
                    : <div className='p10'>Selecione um arquivo para visualizar</div>
                  } 
          />
        </div>
      </div>
    </c.Modal>
  )
}