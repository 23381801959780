import React, { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../App'
import { parseString } from 'xml2js'
import * as c from '../c'
import * as f from '../f'

export default function DfeModal({portaria, mod, tela, onClose, onFinish}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.dfe},
        icons = App.icons

  const [loading, setLoading] = useState(false),
        [files, setFiles] = useState([]),
        [xml, setXml] = useState({}),
        [xmls, setXmls] = useState([]),
        [totais, setTotais] = useState({})


  function carregaXml(xml, filename){
    parseString(xml, (err, r) => {
      App.api('dfe::validateXml', {modelo: mod, xml: r, portaria})
      .then(rvalidates => {
        const validacoes = rvalidates.results
        const valid = validacoes.every(v=>v.valid),
              alert = validacoes.some(v=>v.alert),
              notif = validacoes.some(v=>v.notif),
              block = validacoes.some(v=>v.block)
  
        let infDFe = mod === 55 ? r.nfeProc?.NFe[0]?.infNFe[0] 
                    : mod === 57 ? r.cteProc?.CTe[0]?.infCte[0]
                    : mod === 58 ? r.mdfeProc?.MDFe[0]?.infMDFe[0]
                    : r.cteProc?.CTe[0]?.infCte[0]
  
        let qt_nf = 0, vl_nf = 0, nr_dfe = ''
        if( !block ){
          if( mod===55 ){
            qt_nf = infDFe?.det.reduce((sum, v)=>sum + Number(v.prod[0].qCom[0]), 0)
            // let vQtProd = valid ? infDFe?.['det'].length : 0; for( let i=0; i < vQtProd; i++ ) qt_nf += Number(infDFe?.['det'][i]['prod'][0]['qCom'][0])
            vl_nf = infDFe?.total[0]?.ICMSTot[0]?.vNF[0]
            nr_dfe = r.nfeProc?.protNFe[0]?.infProt[0]?.chNFe[0]
          }
          if( mod === 57 || mod === '57m' ){
            qt_nf = infDFe?.infCTeNorm[0]?.infCarga[0]?.infQ[0]?.qCarga[0]
            vl_nf = infDFe?.infCTeNorm?.[0]?.infCarga?.[0]?.vCarga?.[0] ?? 0
            nr_dfe = r.cteProc?.protCTe[0]?.infProt[0]?.chCTe[0]
          }
          if( mod===58 ){
            nr_dfe = r.mdfeProc?.protMDFe[0]?.infProt[0]?.chMDFe[0]
          }
        }

        if( parseInt(infDFe?.ide[0].mod[0]) === (String(mod).substr(0,2)|0) 
          && (mod === '57m' ? (infDFe?.ide[0].modal[0]|0) === 6 : true )
        ){
          setXml({
            nm_file: filename,
            signals: {valid, alert, notif, block},
            signal: getSignal( block, notif, alert, valid ),
            validacoes,
            nr_cnpj: infDFe?.emit[0]?.CNPJ?.[0] ?? infDFe?.emit[0]?.CPF?.[0],
            nm_emit: infDFe?.emit[0].xNome[0] ?? '',
            UF_emit: !block ? infDFe?.emit[0]?.enderEmit[0]?.UF[0] : '',
            nm_prd: mod===55 ? (infDFe?.det[0].prod[0].xProd[0] ?? '') : '',
            vl_nf: !block ? vl_nf : '',
            qt_nf: !block ? qt_nf : '',
            ds_und: !block && mod===55 ? infDFe?.det[0]?.prod[0]?.uCom[0] : '',
            dt_dco: !block ? infDFe?.ide[0]?.dhEmi[0].substr(0, 10) : '',
            nr_dco: !block ? ( mod===55 ? infDFe?.ide[0].nNF[0] : mod===57 || mod==='57m' ? infDFe?.ide[0]?.nCT[0] : infDFe?.ide[0]?.nMDF[0] ) : '',
            nr_serie: !block ? infDFe?.['ide'][0]['serie'][0] : '',
            nr_dfe: nr_dfe,
            nr_nfe: !block && mod===57 ? infDFe?.infCTeNorm?.[0]?.infDoc?.[0]?.infNFe?.map(c=>c?.chave?.[0]) : '',
            ds_xml: !block ? xml : '',
            sub: <c.Table isSub={true} data={validacoes.map(v=>({...v,
              signal: getSignal( v.block, v.notif, v.alert, v.valid ),
              portaria: String(v.portaria).split("\n").map((x,i)=><div key={i}>{x}</div>),
              xml: v.xml.split("\n").map((x,i)=><div key={i} style={{paddingLeft: (x.split(/[^\t]/)[0].length * 10)+'px'}}>{x}</div>)
            }))} columns={[
              ['','signal', {}, 'force-fit'],
              ['Descrição','desc'],
              ['No agendamento','portaria'],
              ['No XML','xml'],
            ]} />
          })
        }else{
          setXmls([])
          setTotais({})
          setFiles([])
          App.toast.error("Tipo de documento inválido! Era para ser modelo " + String(mod).substr(0,2) + (mod === '57m' ? ' Multimodal': '') )
        }
      })
    })
  }

  function getSignal(block, notif, alert, valid){
    return(
      block ? <icons.MdCancel size={16} style={{color: 'var(--danger)'}} /> 
      : notif ? <icons.MdReportProblem size={16} style={{color: 'var(--warning)'}} />
      : alert ? <icons.MdReportProblem size={16} style={{color: 'var(--warning)'}} />
      : valid ? <icons.BsCheck2Circle size={16} style={{color: 'var(--success)'}} /> : ''
    )
  }

  function onChangeFiles(filesData){
    setXmls([])
    setTotais({})
    setFiles( filesData )

    filesData.map(file => { 
      if( file.file.name.toUpperCase().indexOf(".XML") > 0 ){
        f.getFileString( file.file ).then(r =>
          carregaXml( r.substr(r.indexOf("<")), file.name )
        )  
      }

      return true
    })
  }


  function submit() { setLoading(true)
    if( mod === 55 ){
      let lst_doc = xmls
      delete lst_doc.signal
      delete lst_doc.sub
      return App.api('portarias::gravaListaDocumento', {
        id_uocc: portaria.ID_UOCC, tela, lst_doc
      }).then(r => {
        if( r.status ){
          onFinish()
          return r.status
        }
      }).finally(() => setLoading(false))
    }

    if( mod === 57 ){
      let lst_doc = xmls
      delete lst_doc.signal
      delete lst_doc.sub
      return App.api('portarias::gravaCTe', {
        lst_doc,
        tp_dfe: '57',
        id_uocc: portaria.ID_UOCC, tela,
      }).then(r=>{
        setLoading(false)
        onFinish()
        return r.status
      })
    }

    if( mod === '57m' ){
      let lst_doc = xmls
      delete lst_doc.signal
      delete lst_doc.sub
      return App.api('portarias::gravaCTeMM', {
        lst_doc,
        tp_dfe: '57m',
        id_uocc: portaria.ID_UOCC, tela,
      }).then(r=>{
        setLoading(false)
        onFinish()
        return r.status
      })
    }

    if( mod === 58 ){
      return App.api('portarias::gravaMDFe', {...xmls[0],
        tp_dfe: '58',
        id_uocc: portaria.ID_UOCC,
      }).then(r=>{
        setLoading(false)
        onFinish()
        return r.status
      })
    }
  }

  useEffect(() => {
    if( !!xml.nm_file ){
      setXmls([...xmls, xml ])

      if( !xml.signals.block ){
        let qtde = Number(xml.qt_nf??0) * Number(xml.ds_und?.toUpperCase().includes('T') ? 1000 : 1)

        setTotais({
          qt_file: (totais.qt_file??0) + 1,
          qt_prd: (totais.qt_prd??0) + Number(qtde??0),
          vl_prd: (totais.vl_prd??0) + Number(xml.vl_nf??0),
        })
      }
    }
  }, [xml])

  return(
    <c.Modal onClose={onClose} loading={loading} onFinish={submit}
      title={
        mod === 55 ? lang.dados_fiscais + ' (' + (portaria.NM_PRO??portaria.DS_PRD) + ')' 
        : mod === 57 ? 'Conhecimento de Transporte Eletrônico - CT-e'
        : mod === 58 ? "Anexar MDFe"
        : "CT-e Multimodal"
      }
      successMsg={lang.documento_vinculado_sucesso}
      validate={!!xmls.length && xmls.every(_xml => _xml.validacoes.every(val => !val.block))}
    >
      <c.Upload accept={{"application/xml":['.xml']}} maxFiles={999} _maxFiles={mod===55?3:1} 
        files={files} onChange={onChangeFiles}
        label={mod === 55 ? lang.nfe_arquivo_aceito
                : mod === 57 ? lang.cfe_arquivo_aceito
                : mod === 58 ? 'Arquivo aceito: XML de MDFe.'
                : 'Arquivo aceito: XML de CTe Multimodal.'
              }
      />
      <c.Divider />

      <c.Table hasSub='sub' data={xmls} columns={[
        [lang.arquivo, 'nm_file'],
        [lang.valido, 'signal', {}, 'center-h'],
        [lang.cpfcnpj_emitente, 'nr_cnpj'],
        [lang.emitente, 'nm_emit'],
        [lang.produto, 'nm_prd'],
        [lang.valor, 'vl_nf'],
        [lang.quantidade, 'qt_nf'],
        [lang.unidade, 'ds_und'],
        [lang.data, 'dt_dco'],
        [lang.numero, 'nr_dco'],
        [lang.serie, 'nr_serie'],
        [lang.nr_dfe, 'nr_dfe'],
      ]} withPagination={false} isSub={true} />

      <c.Divider />

      <div className="f g1">
        <c.Input className='f1' disabled value={totais.qt_file||0} label={lang.xml_valido} />
        <c.Input className='f1' disabled value={f.formatNumber(totais.qt_prd||0)+'Kg'} label={lang.quantidade_total} />
        <c.Input className='f1' disabled value={'$ '+f.formatNumber(totais.vl_prd||0)} label={lang.valor_total} />
      </div>
    </c.Modal>
  )
}