import { useState, useEffect, useContext } from "react"
import { AppContext } from '../App'
import * as c from '../c'
import * as f from '../f'

export default function DashDescarga(props) {
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.dashCargaDescarga}

  const [data, setData] = useState([]),
        [loading, setLoading] = useState(null)

  function get(){ setData([])
    setLoading(true)
    App.api('dashboard::unidadeDescarga', {}, true)
      .then(r=>{
        setData( (App.user.in_uorg === 'T' || App.user.in_clt === 'T') ? (r.results??[]) : [])
        setLoading(false)
      })
  }

  useEffect(()=>{ get()
    const interval = setInterval(() => get(), 60000)
    return () => clearInterval(interval)
  }, [])

  useEffect(()=>{
    props.setReloader(()=>get)
  }, [])


  return (<>
    <c.Frame title={lang.title_descarga + ' ' + App.moment().format('DD/MM/YYYY')} flex>
      { !loading && !Object.entries(data).length 
      ? lang.nothing_here
      : <>
        {/* QUANTIDADES */}
        <c.Card type='info' icon={App.icons.BsCalendar3} title={lang.total_agendado} value={data.qt_agd} />
        <c.Card type='warning' icon={App.icons.FaRoad} title={lang.transito} value={data.qt_trs} />
        <c.Card type='danger' icon={App.icons.BsClock} title={lang.aguardando_fila} value={data.qt_fila} />
        <c.Card type='info' icon={App.icons.FaBalanceScale} title={lang.em_operacao} value={data.qt_opr} />
        <c.Card type='info' icon={App.icons.FaBalanceScale} title={lang.veiculo_total} value={data.qt_fila_opr} />
        <c.Card type='info' icon={App.icons.MdLogout} title={lang.encerrado} value={data.qt_enc} />

        {/* VOLUMES */}
        <c.Card type='info' icon={App.icons.BsCalendar3} title={lang.volume_agendado} value={data.qt_vtotal} />
        {/* <c.Card type='warning' icon={App.icons.FaRoad} title={lang.volume_transito} value={f.formatNumber(data.qt_vtrs,2)} /> */}
        <c.Card type='warning' icon={App.icons.FaRoad} title={lang.volume_transito} value={data.qt_vtrs} />

        <c.Card type='danger' icon={App.icons.BsClock} title={lang.volume_fila} value={data.qt_vfila} />
        <c.Card type='info' icon={App.icons.FaBalanceScale} title={lang.volume_operacao} value={data.qt_vopr} />
        {/* <c.Card type='info' icon={App.icons.FaBalanceScale} title={lang.volume_total} value={f.formatNumber(data.qt_vfila_vopr,2)} /> */}
        <c.Card type='info' icon={App.icons.FaBalanceScale} title={lang.volume_total} value={data.qt_vfila_vopr} />

        <c.Card type='info' icon={App.icons.MdLogout} title={lang.volume_encerrado} value={data.qt_venc} />

        {/* TEMPOS */}
        <c.Card type='info' icon={App.icons.BsClock} title={lang.tempo_transito} value={data.dt_trs} />
        <c.Card type='danger' icon={App.icons.BsClock} title={lang.tempo_fila} value={data.dt_fila} />
        <c.Card type='info' icon={App.icons.BsClock} title={lang.tempo_operacao} value={data.dt_opr} />
        <c.Card type='info' icon={App.icons.BsClock} title={lang.tempo_total} value={data.dt_total} />
      </>}
    </c.Frame>

    {data.lst_recepcao?.length > 0 && 
      <c.Frame>
        <table className="w100">
          <thead><tr>
            <td>{lang.resumo_descarga_encerrado}</td>
            <td className="right">{lang.quantidade}</td>
            <td className="right">{lang.volume}</td>
          </tr></thead>
          <tbody>
            {data.lst_recepcao.map((r,i)=>
              <tr key={i}>
                <td>{r.NM_PRO}</td>
                <td className="right">{r.QT_VEICULO}</td>
                <td className="right">{f.formatNumber(r.QT_VOLUME)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </c.Frame>
    }
  </>)
}
