import { useContext, useEffect, useState } from 'react'
import { AppContext, log } from './App'
import * as c from './c'
import * as p from './p'

import logoW from './a/velog-hub-w.png'
import logoB from './a/velog-hub-b.png'
import getSidebarItens from './sidebar'


export default function Layout(){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.login, ...App.lang.menu},
        icons = App.icons, user = App.user

  const [showSettings, setShowSettings] = useState(false),
        [showProfile, setShowProfile] = useState(false),
        [showUsersEditionModal, setShowUsersEditionModal] = useState(null),
        [reloader, setReloader] = useState(null),
        [reloading, setReloading] = useState(null),
        [notifications, setNotifications] = useState([]),
        [sidebarSelection, setSidebarSelection] = useState(
          (sessionStorage.getItem('sidebarSelection_'+user.id_usr)??'0,0').split(',')
        ),
        [sidebarItens, setSidebarItens] = useState([])


  const Page = sidebarItens[ sidebarSelection[0] ]?.page 
    || sidebarItens[ sidebarSelection[0] ]?.itens[ sidebarSelection[1] ]?.page

  const params = sidebarItens[ sidebarSelection[0] ]?.params 
  || sidebarItens[ sidebarSelection[0] ]?.itens[ sidebarSelection[1] ]?.params

  const toolbar_avatar_menu_itens = [
    {title: lang.commodity_prices, icon: icons.MdPriceCheck({size: 24}), action:()=>window.open('https://portal.vertti.com.br/commodity-prices/','_blank')},
    {
      title: lang.configurations, 
      icon: icons.MdSettings({size: 24}), 
      action: () => setShowSettings(true),
      hide: App.user.in_uorgadm !== 'T',
    },
    {
      title: 'Gestão de usuários e permissões', 
      icon: icons.FaUsersCog({size: 24}), 
      action: () => setShowUsersEditionModal(true),
      hide: App.user.in_uorgadm !== 'T',
    },
    {title: lang.profile, icon: icons.FaUserCircle({size: 24}), action:()=>setShowProfile(true)},
    {title: lang.logout, icon: icons.MdLogout({size: 24}), action:()=>App.logout()},
  ]

  // Ao carregar
  // useEffect(() => {
  //   async function getDiretivas(){
  //     let settings = await App.api('settings::list')

  //     let diretivas = settings?.results?.diretivas.reduce((acc, cur) => ({...acc,
  //       [cur.NM_DIR]: cur.VL_DIRLC
  //     }),{})

  //     App.setApp({...App, diretivas})
  //   }
  //   getDiretivas()
  // }, [])

  useEffect(() => {
    (async () => {
      const [components, diretivas, notifications, prefs] =
        await Promise.all([
          App.api('users::getComponents'), 
          App.api('users::getDiretivas'),
          App.api('notifications::get', {}, true),
          App.api('users::getPrefs')
        ])
  
      let _App = {...App, 
        //user: JSON.parse(atob(sessionStorage.getItem('token')??'e30=')),
        components: components.results,
        diretivas: diretivas?.results.reduce((acc, cur) => ({...acc,
                      [cur.NM_DIR]: cur.VL_DIRLC
                    }),{}),
        notifications: notifications.results,
        prefs: prefs.results,
      }

      App.setApp( _App)

      setSidebarItens( getSidebarItens(_App) )
    })()
  }, [])

  //useEffect(() => console.log(App.diretivas), [App.diretivas])

  // "Contorno" feito para as notificações para não sobrescrever o App.setPrefs
  function getNotifications(){
    App.api('notifications::get', {}, true).then(n => setNotifications(n.results))
  }
  useEffect(() => App.setApp({...App, notifications}),[notifications])

  // Intervals
  useEffect(() => { 
    const notificationsInterval = setInterval(() => getNotifications(), 60000)

    const checkUserInterval = setInterval(() => {
      App.api('users::check', {}, true).then(user=>!user.results.id_usr&&App.logout())
    }, 60000)

    return () => {
      clearInterval(checkUserInterval)
      clearInterval(notificationsInterval)
    }
  }, [])


  useEffect(() => {
    try{
      sessionStorage.setItem('sidebarSelection_'+user.id_usr, sidebarSelection?.join(',')??'0,0')
    }catch(e){
      sessionStorage.setItem('sidebarSelection_'+user.id_usr, '0,0')
    }
  }, [sidebarSelection])


  useEffect(()=>{
    setReloading(null)
    if( reloading ) reloader()
  },[reloading])


  return(
    <div id='layout' className={App.prefs?.dark?'dark':''}>

      {/* SIDEBAR */}
      <div id='sidebar' className={'g2 f1 ' + (App.prefs?.sidebar_toggle?'reduce':null)}>
        <c.Sidebar itens={sidebarItens} selection={sidebarSelection} setSelection={setSidebarSelection} setReloading={setReloading} />

        <div id='version' className='center f f-column g1'>
          <img src={require('./a/velog-icon.png')} alt='Version' width={24} />
          <span className='version-number'>Version {App.pj.version}</span>
          {App.env!==3 &&
            <span className='version-env destaque danger'>
              {['Dev - Local back','Testing','Homologation','Production','Dev - Remote back'][App.env]}
            </span>
          }
        </div>
      </div>

      {/* MAIN */}
      <div id='main' className={(App.prefs?.sidebar_toggle?'reduce':null)}>
        {!!Page&&<Page setReloader={setReloader} {...params} />}
      </div>


      {/* TOOLBAR */}
      <div id='toolbar' className='f f-between'>
        <c.IconButton 
          onClick={()=>App.setPrefs({sidebar_toggle: !App.prefs?.sidebar_toggle})}
          id='toggleMenu'>
          {App.prefs?.sidebar_toggle 
            ? App.icons.MdMenu({size: '24'})
            : App.icons.MdMenuOpen({size: '24'})
          }
        </c.IconButton>

        <img src={App.prefs?.dark?logoW:logoW} className='logoVelog' alt="logoVelog" height='30px' />

        <div id='toolbar_infos' className='f g2 center-v'>
          <c.DropdownMenu id='toggleLang' title={lang.selecionar_idioma} itens={
            lang.langs.map((l,i)=>({
              title: l, 
              // icon: lang.flags[i], 
              icon: <c.Flag code={lang.flagsB[i]} />,
              action:()=>App.setPrefs({lang: i})
            }))
          }>
            <c.IconButton >
              <c.Flag code={lang.flagB} />
            </c.IconButton>
          </c.DropdownMenu>

          <c.IconButton onClick={()=>App.setPrefs({dark: !App.prefs?.dark})} id='toggleDarkmode'>
            {App.prefs?.dark 
              ? <icons.BsSunFill size={16} title={'Normal mode'} /> 
              : <icons.BsMoonFill size={16} title={'Dark mode'} /> }
          </c.IconButton>

          <c.NotificationMenu />

          <c.DropdownMenu itens={toolbar_avatar_menu_itens}>
            <span id='user_name' className='f g3'><c.Avatar border size='48' />{user.nm_red}</span>
          </c.DropdownMenu>
        </div>
      </div>

      {showSettings && <p.Settings onClose={()=>setShowSettings(false)} />}
      {showProfile && <p.ProfileModal onClose={()=>setShowProfile(false)} />}
      {showUsersEditionModal && <p.UsersEditionModal onClose={()=>setShowUsersEditionModal(null)} />}
    </div>
  )
}