import { useState, useEffect, useContext, useDeferredValue } from "react";
import { AppContext, log } from "../../App";
import * as c from "../../c";
import * as f from "../../f"

import { Map, GeoJSON, TileLayer, Marker, Popup } from "react-leaflet"
import L from "leaflet"
import { set } from "lodash";
import { ZA } from "country-flag-icons/react/3x2";


export default function Rotas({ setReloader }) {
  const App = useContext(AppContext),
    lang = {
      ...App.lang.global,
      ...App.lang.rotas,
    },
    icons = App.icons;

  const [loading, setLoading] = useState({}),
    [busca, setBusca] = useState(""),
    [incluir, setIncluir] = useState(null),
    [editing, setEditing] = useState(null),
    [rotas, setRotas] = useState([]),
    [data, setData] = useState();

    function get() {
        setLoading(true)
        App.api('rotas::get').then(r => {
            setRotas(r.results)
            setLoading(false)
        })

    }


    useEffect(() => {
        get()
        setReloader(() => get)
    }, [])


  return (
    <>
      <c.Frame
      loading={loading}
        title={lang.rota}
        actions={{
          add: () => (setIncluir(true),setData({})),
        }}
        control={
          <>
            <div className="f f-row w100 center">
              <c.Input
                className="w100"
                placeholder={lang.busca}
                value={busca}
                onChange={(e) => setBusca(e.target.value)}
                clearable
              />
            </div>
          </>
        }
      >
        <c.Table
          data={rotas.map((n) => ({...n,
            END_ORG: n.NM_LGDORI + ' ' + n.NM_BROORI +  ' ( ' + f.formatCEP(n.CD_CEPORI) + ' )  / ' + n.NM_CIDORI + ' - ' + n.NM_UFORI,
            END_DST: n.NM_LGDDST + ' ' + n.NM_BRODST +  ' ( ' + f.formatCEP(n.CD_CEPDST) + ' )  / ' + n.NM_CIDDST + ' - ' + n.NM_UFDST,
            QT_KM: (n.QT_KM??'----'),
            HR_DURACAO: !!n.HR_DURACAO ? n.HR_DURACAO.split(":")[0] + "h " + n.HR_DURACAO.split(":")[1] + "m" : '----',
            TP_ROTA: n.TP_ROTA === 'R' ? lang.tp_rodoviario : n.TP_ROTA === 'F' ? lang.tp_ferroviario : '----',
            VL_PDG: "(R$) " + (n.VL_PDG ??'----'),
          actions:<c.IconButton  onClick={() => (setEditing({...n}))}>
          <icons.BsFillPencilFill />
        </c.IconButton> 
          }))}
          columns={[
            ["#", "ID_UOCC", {}, "f center"],
            [lang.id_referencia, "ID_EXT", {}, "f"],
            [lang.parceiroOrg, "NM_PESORI", {}, "f"],
            [lang.enderecoOrg, "END_ORG", {}, "f"],
            [lang.parceiroDes, "NM_PESDST", {}, "f"], 
            [lang.enderecoDes, "END_DST", {}, "f"], 
            [lang.km, "QT_KM", {}, "f"],
            [lang.pedagio, "VL_PDG", {}, "f"],
            [lang.duracao, "HR_DURACAO", {}, "f"],
            [lang.tp_rota, "TP_ROTA", {}, "f"],
            [lang.acao, "actions", {}, "f center"],
          ]}
          search={busca}
        />
      </c.Frame>
      {incluir && <RotasModal onClose={() => setIncluir(null)} onFinish={() => get()}  />}
      {editing && <RotasModal rotaData={editing} onClose={() => setEditing(null)} onFinish={() => get()} />}
    </>
  );
}


function RotasModal({rotaData = null, onFinish, onClose}){
    const App = useContext(AppContext),
          lang = {...App.lang.global, ...App.lang.rotas},
          icons = App.icons
  
    const [listas, setListas] = useState({}),
          [loading, setLoading] = useState(false),
          [loadingMap, setLoadingMap] = useState(false),
          [loadingAddress, setLoadingAddress] = useState(false),
          [pedagios, setPedagios] = useState([]),
          [rotaOrigemDestino, setRotaOrigemDestino] = useState([]),
          [posOrigemDestino, setPosOrigemDestino] = useState({inicio: [],fim: []}),
          [rota, setRota] = useState({lat_lng_orig: [],lat_lng_dest: []}),
          [position, setPosition] = useState([-23.415881526446956, -51.93903347350793]),
          [keyMAP, setKeyMAP] = useState(Math.random()),
          [data, setData] = useState({
            tp_oprprt: "R", tp_frt: "C",
            qt_conversao: 37, lst_transp: [],
            obs: [],
            posicaoOrigem:[],
            posicaoDestino:[],
            id_ext: rotaData?.ID_EXT??'',
            tp_rota: rotaData?.TP_ROTA??'R',
            nr_cnpjexp: rotaData?.CNPJORI??null,
            nr_cnpjrcb: rotaData?.CNPJDST??null,
            id_rota: rotaData?.ID_UOCC??null
            
          })
    
    const iconePedagio = new L.divIcon({className: "",
      iconSize: [32, 32], iconAnchor: [4, 10],
      labelAnchor: [-4, 0], popupAnchor: [0, -5],
      html: `<span style="color:#d04933;" class="fa fa-circle"></span>`,
    })
  
    function atualizarCustos(lat_lng_orig, lat_lng_dest){
      fetch('https://velog.vertti.com.br/api/roteiro', {method: 'POST', headers: {'content-type': 'application/json, text/plain, */*'}, 
        body: JSON.stringify({sn_lat_lng: true, sn_rota_alternativa: false, sn_pedagio: true, sn_balanca: true,
          tag: "TRUCK", sn_calcu_volta: false, qtd_eixos: 6, veiculo_km_litro: 0, valor_medio_combustivel: 0,
          rotas: [{ lat: lat_lng_orig[1], lng: lat_lng_orig[0] },{ lat: lat_lng_dest[1], lng: lat_lng_dest[0] }],
        }) 
      }).then(r=>r.json()).then(r=>{
        setPedagios(r[0].track.rotas[0].pedagios)
        setRotaOrigemDestino(r[0].track.rotas[0].lng_lat)
        setPosOrigemDestino({
          inicio: [
            r[0].track.rotas[0].lng_lat[0][1],
            r[0].track.rotas[0].lng_lat[0][0],
          ],
          fim: [
            r[0].track.rotas[0].lng_lat[ r[0].track.rotas[0].lng_lat.length - 1 ][1],
            r[0].track.rotas[0].lng_lat[ r[0].track.rotas[0].lng_lat.length - 1 ][0],
          ],
        })
  
        setData({...data,
          vl_pedagio:  data?.tp_rota === "R" ? (r[0].track.rotas[0].vl_total_pedagio_original / 6)?.toFixed(2) : 0,
          qt_km: data?.tp_rota === "R" ? r[0].track.rotas[0].distancia : '0',
          hr_duracao: data?.tp_rota === "R" ? r[0].track.rotas[0].duracao : '0 h 00 min'
        })
      })
    }
  
    async function atualizaPedagios(){
      
      if( !!data.nr_cnpjexp && !!data.nr_cnpjrcb ){
        

        setLoadingMap(true)
        
        const origem = !!data.nr_cnpjexp ? listas.origens?.find(o=>parseInt(o.VALUE)===parseInt(data.nr_cnpjexp)) : null
        const destino = !!data.nr_cnpjrcb ? listas.destinos?.find(o=>parseInt(o.VALUE)===parseInt(data.nr_cnpjrcb)) : null

        if( origem !== null && destino !== null && origem !== undefined && destino !== undefined ){
          if( origem?.adicional?.cd_cep === '' || destino?.adicional?.cd_cep === '' ){
              setLoadingAddress(true)
          }
          const posOrigem = await buscarLongitudeLatitude(origem)
          const posDestino = await buscarLongitudeLatitude(destino)
          setLoadingAddress(false)
          
          setRota({...rota, 
            lat_lng_orig: (data.nr_cnpjexp === rotaData?.CNPJORI) ? [parseFloat(rotaData.NR_LGTORI), parseFloat(rotaData.NR_LATORI)] : posOrigem, 
            lat_lng_dest: (data.nr_cnpjrcb === rotaData?.CNPJDST) ? [parseFloat(rotaData.NR_LGTDST), parseFloat(rotaData.NR_LATDST)] : posDestino})
          setPosition([posOrigem[1], posOrigem[0]])
          setLoadingMap(false)
          atualizarCustos(posOrigem, posDestino)

        }
        
        
        
      }
    }
  
    async function buscarLongitudeLatitude(objeto){
      const endereco = `${objeto?.adicional?.nm_loc}, ${objeto?.adicional?.nm_uf}, ${objeto?.adicional?.nm_pais}`
      const r = await fetch(`https://nominatim.openstreetmap.org/search?q=${endereco}&format=geojson`)
      const dados = await r.json()


      return dados.features[0].geometry.coordinates

    }
  
    function submit(){
      setLoading(true)
      return App.api('rotas::save',{...data,
        ID_UOCC: parseInt(data.id_rota),
        ID_PRC_ORI: data.nr_cnpjexp,
        ID_PRC_DST: data.nr_cnpjrcb,
        NR_LGT_ORI: rota.lat_lng_orig[0],
        NR_LGT_DST: rota.lat_lng_dest[0],
        NR_LAT_ORI: rota.lat_lng_orig[1],
        NR_LAT_DST: rota.lat_lng_dest[1],
        CD_CEP_ORI: listas.origens?.find(o=>parseInt(o.VALUE)===parseInt(data.nr_cnpjexp))?.adicional?.cd_cep,
        CD_CEP_DST: listas.destinos?.find(o=>parseInt(o.VALUE)===parseInt(data.nr_cnpjrcb))?.adicional?.cd_cep,
        VL_PDG: (parseFloat(data.vl_pedagio) > 0 && data.tp_rota === "R") ? parseFloat(data.vl_pedagio) : 0,
        QT_KM: parseFloat(data.qt_km.replace(',', '.').replace(' (KM)', '')),
        HR_DURACAO: data.hr_duracao.split(" ")[0] + ":" + data.hr_duracao.split(" ")[2],
        ID_EXT: parseInt(data.id_ext??0),

      })
        .then(r=>{ setLoading(false)
          onFinish()
          return true
        })
    }

    useEffect(() => {
      
      if (!!data.nr_cnpjexp) {
        App.api('rotas::getEmbarcadores', { adicionais: true, s: data.nr_cnpjexp }).then(r => {
          setListas(listas => ({ ...listas, origens: r.results }));
        });
      }
    
      if (!!data.nr_cnpjrcb) {
        App.api('rotas::getEmbarcadores', { adicionais: true, s: data.nr_cnpjrcb }).then(r => {
          setListas(listas => ({ ...listas, destinos: r.results }));
        });
      }


    }, [data.nr_cnpjexp, data.nr_cnpjrcb]);
    

    useEffect(()=>atualizaPedagios(), [data.nr_cnpjexp,  data.nr_cnpjrcb, listas, data.tp_rota])
    useEffect(()=>rotaOrigemDestino.length > 0 && setKeyMAP(Math.random()), [rotaOrigemDestino])
  
  
  
  
    return(
    <c.Modal largeWidth title={!!rotaData ? lang.editar_rota.replace("%d", rotaData?.ID_UOCC) : lang.incluir_rota} onFinish={submit} loading={loading} 
     contentHeight={500} onClose={onClose} validate={
      rota.lat_lng_orig.length > 0 &&
      rota.lat_lng_dest.length > 0 &&
      !!data.tp_rota &&
      !!data?.id_ext
     }
    >
      {/* Rota */}
      {<>
        <div className="f g2 w100">
          <div className="f g1 f-column f1">
            <c.Select required={!!rota} label={lang.origemTitulo} options={listas.origens}  value={data.nr_cnpjexp} onChange={e=>setData({...data, nr_cnpjexp: e.value})} onInputChange={(v, {action}) => {
              if(v.length >= 3 && action === 'input-change') App.api('rotas::getEmbarcadores', {adicionais: true, s: v}).then(r => setListas({...listas, origens: r.results}))
              if(v.length < 3 && action === 'input-change')
               setListas({...listas, origens: []})
              return v
            }} searchable 
            />
            <c.Span label={lang.enderecoOrg} value={!!data.nr_cnpjexp ? ((objeto) => `${objeto?.adicional?.nm_lgd} - ${objeto?.adicional?.nm_bro??''} (${f.formatCEP(objeto?.adicional?.cd_cep)}) - ${objeto?.adicional?.nm_loc}, ${objeto?.adicional?.nm_uf}, ${objeto?.adicional?.nm_pais}`)(listas.origens?.find(o=>parseInt(o.VALUE)===parseInt(data.nr_cnpjexp))) : null}/>
            <div className="f f-row w100">
            <c.Input value={rota?.lat_lng_orig.length > 0 ? rota.lat_lng_orig[0] : ''} label={"LAT"} onChange={(e) => setRota({...rota,
              lat_lng_orig: rota.lat_lng_orig.length > 0 ? [e.target.value, rota.lat_lng_orig[1]] : []
            })} />
            <c.Input style={{marginLeft: '20px'}} value={rota?.lat_lng_orig.length > 0 ? rota.lat_lng_orig[1] : ''} label={"LNG"} onChange={(e) => setRota({...rota,
              lat_lng_orig: rota.lat_lng_orig.length > 0 ? [rota.lat_lng_orig[0], e.target.value] : []
            })} />
            </div>
            <c.Select  required={!!rota} label={lang.destinoTitulo} options={listas.destinos} value={data.nr_cnpjrcb} onChange={e=>setData({...data, nr_cnpjrcb: e.value})} searchable onInputChange={(v, {action}) => {
              if(v.length >= 3 && action === 'input-change') App.api('rotas::getEmbarcadores', {adicionais: true, s: v}).then(r => setListas({...listas, destinos: r.results}))
              if(v.length < 3 && action === 'input-change')
              setListas({...listas, origens: []})
              return v
            }} 
            />
            <c.Span label={lang.enderecoDes} value={!!data.nr_cnpjrcb ? ((objeto) => `${objeto?.adicional?.nm_lgd} - ${objeto?.adicional?.nm_bro} (${f.formatCEP(objeto?.adicional?.cd_cep)}) - ${objeto?.adicional?.nm_loc}, ${objeto?.adicional?.nm_uf}, ${objeto?.adicional?.nm_pais}`)(listas.destinos?.find(o=>parseInt(o.VALUE)===parseInt(data.nr_cnpjrcb))) : null}/>
            <div className="f f-row w100">
            <c.Input value={rota?.lat_lng_dest.length > 0 ? rota.lat_lng_dest[0] : ''} label={"LAT"} onChange={(e) => setRota({...rota,
              lat_lng_dest: rota.lat_lng_dest.length > 0 ? [e.target.value, rota.lat_lng_dest[1]] : []
            })} />
            <c.Input style={{marginLeft: '20px'}} value={rota?.lat_lng_dest.length > 0 ? rota.lat_lng_dest[1] : ''} label={"LNG"} onChange={(e) => setRota({...rota,
              lat_lng_dest: rota.lat_lng_dest.length > 0 ? [ rota.lat_lng_dest[0], e.target.value,] : []
            })} />
            </div>
            <div className="f">
            <c.Input disabled value={f.formatCurrency(data.vl_pedagio??0)} label={lang.pedagio} />
            <c.Input disabled value={data.qt_km} label={lang.distancia} />
            <c.Input disabled value={data.hr_duracao} label={lang.duracao} />
            </div>
            <c.Select  required={!!data.tp_rota} label={lang.tp_rota} options={[{value: 'R', label: lang.tp_rodoviario},
            {value: 'F', label: lang.tp_ferroviario}]} value={data.tp_rota} onChange={e=>setData({...data, tp_rota: e.value})} searchable 
            />
            <c.Input required value={data.id_ext??''} onChange={(e) => setData({...data, id_ext: e.target.value})} label={"Referência"} />
          </div>
          <div className="f2 radius overflow-hidden box-shadow">

            {
            loadingAddress ? <div className="f center " style={{color: 'red'}}><h1>{lang.endereco_nao_cadastrado}</h1></div>  : loadingMap  ?  <App.LoadingComponent /> :
              <Map key={keyMAP} center={position} zoom={8} style={{ position: "relative", width: "100%", height: "500px" }}>
                {posOrigemDestino.inicio.length > 0 && posOrigemDestino.fim.length > 0 && <>
                  <Marker position={posOrigemDestino.inicio}><Popup>Inicio da Rota</Popup></Marker>
                  <Marker position={posOrigemDestino.fim}><Popup>Final da Rota</Popup></Marker>
                  <GeoJSON attribution='&copy; <a href="https://velog.com.br">VELOG</a>'
                    data={{type: "RotaCollection",
                      features: [{type: "Feature", geometry: {type: "LineString", coordinates: rotaOrigemDestino}}],
                    }}
                  />
                </>}
                {pedagios.map((value, index) => (
                  <Marker icon={iconePedagio} key={`pedagio-${index}`} position={[value.id_lat, value.id_long]} >
                    <Popup>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                        <div style={{ backgroundImage: `url("${value.ds_logo}")`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain", width: "100px", minHeight: "70px", maxHeight: "100px" }} />
                        <span>{value.ds_praca}</span> <span><strong>{f.formatCurrency(value.vl_tarifa / 6)}</strong></span>
                      </div>
                    </Popup>
                  </Marker>
                ))}
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>' />
              </Map>
}
            
          </div>
        </div>
      </>}
    </c.Modal>)
  }
  
