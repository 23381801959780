import ReactDOM from "react-dom"
import Application from "./App"
import 'react-toastify/dist/ReactToastify.css'

import env from "./.env.json"

// 0 = Dev local
// 1 = Teste
// 2 = Homologação
// 3 = Produção
// 4 = Dev local com back no velogteste.vertti.com.br

ReactDOM.render(<Application env={env} />,
  document.getElementById("root"),
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', ()=>{
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service worker registrado com sucesso: ', registration.scope)
        navigator.serviceWorker.addEventListener('message', event => { 
          if (event.data === 'reload') {
            window.location.reload();
          }
        });
        Notification.requestPermission()
      })
      .catch(error => {
        console.error('Erro ao registrar o service worker: ', error)
      })
  })

  navigator.serviceWorker.addEventListener('message', event => {
    if (event.data && event.data.type === 'update_available') {
      window.location.reload();
    }
  });
}