import { useContext, useState, useEffect } from 'react'
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as f from '../../f'

export default function UsersPerfisEditionModal({onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.login, ...App.lang.menu},
        icons = App.icons, user = App.user

  const [tabActive, setTabActive] = useState(0),
        [loading, setLoading] = useState(null)

  return(
    <c.Modal title={'Gestão de usuários e permissões'} onClose={onClose} largeWidth contentHeight=''
      cancelText={'Fechar'} loading={loading}
    >
      <div className='f g2 w100'>
        <ul className='menu' style={{gap: 0, marginLeft: -10, minWidth: 150}}>
          {['Perfis e permissões','Usuários'].map((tab, tab_i)=>
            <li key={tab_i} className={tabActive === tab_i ? 'active' : null}>
              <span onClick={()=>setTabActive(tab_i)}>
              <span className='menu-name'>{tab}</span>
            </span></li>
          )}
        </ul>

        {/* <div className='f1'> */}
          {tabActive===0 && <PerfisEditor />}
          {tabActive===1 && <UsersEditor />}
        {/* </div> */}
      </div>
    </c.Modal>
  )
}


function UsersEditor(props){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.login, ...App.lang.menu},
        icons = App.icons, user = App.user

  const [loading, setLoading] = useState(null),
        [users, setUsers] = useState([]),
        [activeUser, setActiveUser] = useState({}),
        [parceiros, setParceiros] = useState([]),
        [search, setSearch] = useState(''),
        [perfisList, setPerfisList] = useState([])

  async function init(){
    setLoading(true)
    setUsers( (await App.api('perfis::users')).results )
    setPerfisList( 
      (await App.api('perfis::list')).results.map(perfil => ({
        ID: perfil.ID_UOCC,
        NAME: perfil.DS_PFL
      }))
    )
    setLoading(false)
  }

  async function userAdd(){
    setUsers([{ID_UOCC: -1}, ...users])
    setActiveUser({ID_UOCC: -1, IN_STATUS: '1', editing: true})
  }


  async function getUser(){
    if( !!activeUser.ID ){
      setActiveUser({loading: true})
      let _activeUser = (await App.api('perfis::users', activeUser)).results[0] ?? {}
      setActiveUser( _activeUser )
      setParceiros(!!_activeUser.ID_FNC
        ? [{ID: _activeUser.ID_FNC??0, NAME: _activeUser.NM_PES??''}]
        : []
      )
    }
  }

  async function userEditingCancel(){
    if( users[0].ID_UOCC === -1 ){
      users.shift()
      setUsers([...users])
      setActiveUser({})
    }else{
      setActiveUser({ID: activeUser.ID_UOCC})
    }
  }

  async function userSave(){
    setActiveUser({...activeUser, loading: true})
    const _users = await App.api('perfis::userSave', activeUser)
    if( _users.status ){
      App.toast.success('Salvo', { autoClose: 500 })
      setUsers( _users.results )
      setActiveUser({
        ID: _users.results.find(u => u.ID_LOGIN.trim() === activeUser.ID_LOGIN.trim())?.ID_UOCC
      })
    }else{
      setActiveUser({...activeUser, loading: false})
    }
  }

  useEffect(() => init(), [])
  useEffect(() => getUser(), [activeUser])

  return(
    <div className='f f-column f1' style={{height: 500}}>
      {loading && <App.LoadingComponent />}

      {!loading && 
        <div className='f g1' style={{height: 500}}>
          <div className='f f-column g1'
            style={{
              maxHeight: '100%',
              padding: 5,
              borderRadius: 5,
              background: 'var(--background)',
              border: '1px dashed var(--color)',
            }}
          >
            {!loading && !activeUser.editing &&
              <div className='f g1 f-between center-v'>
                <button onClick={() => userAdd()}>
                  <icons.MdCreateNewFolder />Novo
                </button>

                <c.Input fit clearable placeholder={'Busca'} value={search} onChange={e => setSearch( e.target.value )} />
              </div>
            }

            <ul className='menu' 
              style={{
                overflowY: activeUser.editing ? 'hidden' : 'auto', 
                display: 'block', 
                minWidth: 270
              }}
            >
              {users
                .filter(u => !search || u.ID_LOGIN.toUpperCase().indexOf(search.toUpperCase()) >= 0)
                .map(user =>
                  <li key={user.ID_UOCC}
                    className={[
                      activeUser.ID_UOCC === user.ID_UOCC 
                        ? 'active' 
                        : activeUser.editing ? 'disabled' : ''
                    ].join(' ')}
                  >
                    <span onClick={() => !activeUser.editing && setActiveUser({ID: user.ID_UOCC})}>
                      <span className='menu-name'>
                        {user.ID_LOGIN ?? '-- NOVO USUÁRIO --'}
                      </span>
                    </span>
                  </li>
                )
              }
            </ul>
          </div>

          {!!activeUser.loading 
            ? <App.LoadingComponent />
            : !!activeUser.ID_UOCC
              ? <div className='f1 f f-column f-between g1'
                  style={{
                    padding: 5,
                    borderRadius: 5,
                    background: 'var(--background)',
                    border: '1px dashed var(--color)',
                  }}        
                >
                  <div className='f f-column g1'>
                    <div style={{fontSize: 18}} className='center-h'>
                      {activeUser.ID_UOCC === -1
                        ? <>Novo usuário</>
                        : <>Editando: <b>{activeUser.ID_LOGIN}</b></>
                      }
                    </div>

                    <c.Input label={'Login'} 
                      value={activeUser.ID_LOGIN??''} 
                      title={'Não são permitidos caracteres acentuados ou especiais'}
                      minLength={5}
                      onChange={e => setActiveUser({...activeUser, 
                        editing: true, 
                        ID_LOGIN: e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
                      })}
                    />
                    <c.Input label={'Nome'} 
                      value={activeUser.NM_USR??''} 
                      minLength={5}
                      onChange={e => setActiveUser({...activeUser, editing: true, NM_USR: e.target.value})}
                    />
                    {activeUser.ID_UOCC === '1'
                      ? <c.Span label={'Status'} 
                          value={activeUser.IN_STATUS === '1' ? 'Ativo' : 'Bloqueado'} 
                        />
                      : <c.Select label={'Status'} 
                          value={activeUser.IN_STATUS}
                          onChange={e => setActiveUser({...activeUser, editing: true, IN_STATUS: e.value})}
                          options={[
                            {value: '-3', label: 'Bloqueado'},
                            {value: '1', label: 'Ativo'},
                          ]}
                        />
                    }
                    <c.Select label={'Parceiro associado'} searchable clearable
                      value={activeUser.ID_FNC} options={parceiros}
                      onInputChange={(s, {action}) => {
                        if( action === 'input-change') 
                          App.api('perfis::funcionarios',{s})
                            .then(r => setParceiros(r.results))
                        return s
                      }}
                      onChange={e => setActiveUser({...activeUser, editing: true, ID_FNC: e?.value})}
                    />
                    <c.Select label={'Perfil associado'} searchable clearable
                      value={activeUser.PFL_ID} options={perfisList}
                      onChange={e => setActiveUser({...activeUser, editing: true, PFL_ID: e?.value})}
                    />
                  </div>

                  {activeUser.editing &&
                    <div className='f g2'>
                      <button className='success'
                        onClick={() => userSave()}
                        disabled={!(
                          activeUser.ID_LOGIN?.length >= 5 &&
                          activeUser.NM_USR?.length >= 5
                        )}
                      ><icons.MdSave />Salvar</button>

                      <button className='danger'
                        onClick={() => userEditingCancel()}
                      ><icons.MdCancel />Cancelar</button>
                    </div>
                  }
                </div>
              : <div className='bold f center center-margin f-column g3'>
                  <icons.FaUserTie size={60} />
                  <h2>Selecione um usuário</h2>
                </div>
          }
        </div>
      }
    </div>
  )
}


function PerfisEditor(props){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.login, ...App.lang.menu},
        icons = App.icons, user = App.user

  const [loading, setLoading] = useState(null),
        [perfis, setPerfis] = useState([]),
        [perfilSaving, setPerfilSaving] = useState({}),
        [perfilNameEditing, setPerfilNameEditing] = useState({}),
        [selected, setSelected] = useState({}),
        [search, setSearch] = useState('')

  const style = {
                  padding: 5,
                  borderRadius: 5,
                  background: 'var(--background)',
                  border: '1px dashed var(--color)',
                  overflowY: 'auto',
                }

  async function init(){
    setLoading(true)
    setPerfis( (await App.api('perfis::full')).results )
    setLoading(false)
  }

  async function perfilAdd(){
    setPerfis([{ID: -1}, ...perfis])
    setPerfilNameEditing({PERFIL_ID: -1})
    setSelected({ID: -1})
  }

  async function perfilEditingCancel(){
    if( perfis[0].ID === -1 ){
      perfis.shift()
      setPerfis([...perfis])
      setSelected({})
    }
    setPerfilNameEditing({})
  }

  async function perfilSave(){
    if( !!perfilSaving.PERFIL_ID ){
      const _perfis = await App.api('perfis::save', perfilSaving)
      if( _perfis.status ) {
        setPerfis( _perfis.results )
        App.toast.success('Salvo', { autoClose: 500 })
      }else{
        perfilEditingCancel()
      }
      setPerfilSaving({})
      setPerfilNameEditing({})
    }
  }

  async function usersByPerfil(){
    if( !selected.users ){
      setSelected({...selected,
        users: [],
        usersLoaging: true,
      })

      setSelected({...selected,
        users: (await App.api('perfis::usersByPerfil', {ID: selected.ID})).results,
        usersLoaging: false,
      })  
    }
  }

  useEffect(() => init(), [])

  useEffect(() => perfilSave(), [perfilSaving])

  useEffect(() => usersByPerfil(), [selected])

  return(
    <div className='f f-column f1' style={{height: 500}}>
      {loading && <App.LoadingComponent />}

      {!loading &&
        <div className='f g1' style={{height: 500}}>
          <div className='f f-column g1' style={style}>
            {!loading && !perfilNameEditing.PERFIL_ID && !perfilSaving.PERFIL_ID &&
              <div className='f g1 f-between center-v'>
                <button onClick={() => perfilAdd()}>
                  <icons.MdCreateNewFolder />Novo
                </button>

                <c.Input fit clearable placeholder={'Busca'} value={search} onChange={e => setSearch( e.target.value )} />
              </div>
            }

            <ul className='menu' 
              style={{
                overflowY: perfilNameEditing.PERFIL_ID ? 'hidden' : 'auto', 
                display: 'block', 
                minWidth: 270
              }}
            >
              {perfis
                .filter(perfil => !search || perfil.NOME.toUpperCase().indexOf(search.toUpperCase()) >= 0)
                .map(perfil =>
                  <li key={perfil.ID}
                    className={[
                      selected.ID === perfil.ID 
                        ? 'active' 
                        : perfilNameEditing.PERFIL_ID ? 'hidden' : ''
                    ].join(' ')}
                  >
                    <span onClick={() => 
                      !perfilNameEditing.PERFIL_ID && selected.ID !== perfil.ID && setSelected({ID: perfil.ID})
                    }>
                      <span className='menu-name f center-v f-between w100'>
                        {perfilNameEditing.PERFIL_ID === perfil.ID 
                          ? perfilSaving.PERFIL_ID === perfil.ID 
                            ? <div className='f1'><c.Skeleton /></div>
                            : <c.Input value={perfilNameEditing.NOME} minLength={5} fit
                                title={'Não são permitidos caracteres acentuados ou especiais'}
                                onChange={e => setPerfilNameEditing({...perfilNameEditing, 
                                  NOME: e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                                })}
                              />
                          : perfil.NOME ?? '-- NOVO PERFIL --'
                        }

                        {perfilNameEditing.PERFIL_ID === perfil.ID
                          ? !perfilSaving.PERFIL_ID && 
                              <>
                                {perfilNameEditing.NOME?.length >= 5 &&
                                  <c.IconButton title={"Salvar"} icon={icons.MdSave} size={28} 
                                    onClick={()=>setPerfilSaving({...perfilNameEditing})}
                                  />
                                }
                                <c.IconButton title={"Cancelar"} icon={icons.MdCancel} size={28} 
                                  onClick={()=>perfilEditingCancel()}
                                />
                              </>
                          : !perfilNameEditing.PERFIL_ID &&
                              <c.IconButton title={"Editar"} icon={icons.MdEdit} size={28} 
                                onClick={()=>setPerfilNameEditing({PERFIL_ID: perfil.ID, NOME: perfil.NOME})}
                              />
                        }
                      </span>
                    </span>
                  </li>
                )
              }
            </ul>
          </div>

          {selected.ID > 0 && !perfilNameEditing.PERFIL_ID
            ? <>
                <div className='f1 f f-column f-between g1' style={style}>
                  <div style={{fontSize: 18}} className='center-h'>
                    Permissões de <b>{perfis.find(perfil => perfil.ID === selected.ID)?.NOME}</b>
                  </div>

                  {perfis
                    .find(perfil => perfil.ID === selected.ID)
                    ?.COMPONENTES?.map(componente => 
                      <details key={componente.ID}>
                        <summary className='f g1 center-v'>
                          <c.Checkbox title={"Ativar/Desativar"} checked={componente.ATIVO} 
                            disabled={!!perfilSaving.PERFIL_ID}
                            label={perfilSaving.COMPONENTE_ID !== componente.ID
                              ? componente.NAME
                              : <div className='f1'><c.Skeleton /></div>
                            }
                            onChange={() => setPerfilSaving({PERFIL_ID: selected.ID, COMPONENTE_ID: componente.ID, ATIVO: !componente.ATIVO})}
                          />
                        </summary>

                        {componente.FUNCOES.map(funcao => 
                          <div className='item f g1' key={funcao.ID}>
                            <c.Checkbox title={"Ativar/Desativar"} checked={funcao.ATIVO} 
                              disabled={!!perfilSaving.PERFIL_ID}
                              label={perfilSaving.FUNCAO_ID !== funcao.ID
                                ? funcao.NOME
                                : <div className='f1'><c.Skeleton /></div>
                              }
                              onChange={() => setPerfilSaving({PERFIL_ID: selected.ID, FUNCAO_ID: funcao.ID, ATIVO: !funcao.ATIVO})}
                            />
                          </div>
                        )}
                      </details>
                    )
                  }
                </div>

                <div className='f1 f f-column g1' style={style}>
                  {selected.usersLoaging ? <App.LoadingComponent />
                   : <>
                      <div style={{fontSize: 18}} className='center-h'>
                        Usuários com perfil <b>{perfis.find(perfil => perfil.ID === selected.ID)?.NOME}</b>
                      </div>
                      {selected.users?.length
                        ? <ul style={{paddingLeft: 25, margin: 0}}>
                            {selected.users?.map(user =>
                              <li key={user.ID} className='p1'><b>{user.LOGIN}</b> ({user.NOME})</li>
                            )}
                          </ul>
                        : <div className='bold p3 w100 center-h'>Ainda não há usuários para este perfil</div>
                      }
                    </>
                  }
                </div>
              </>
            : <div className='bold f center center-margin f-column g3'>
                <icons.FaUsers size={60} />
                <h2>Selecione um perfil</h2>
              </div>
          }
        </div>
      }
    </div>
  )
}